import { Outlet, useNavigate } from "react-router-dom";
import { checkIfLogin } from "./common";
import { reset as resetGlobal } from "../slices/globalSlice";
import { reset as resetWorkPlan } from "../slices/workPlanSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { reset as resetGISDashboard } from "../slices/gisDashboardSlice";

export function ProtectedRoute() {
  const isLogin = useSelector((state) => state.global.isLogin);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === null) { // This means localStorage is cleared
        navigate("/login", { replace: true });
        dispatch(resetGlobal());
        dispatch(resetWorkPlan());
        dispatch(resetGISDashboard());
        toast.success("Logged out successfully", {
          autoClose: 1000,
          hideProgressBar: true,
        });
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [navigate, dispatch]);

  useEffect(() => {
    if (!isLogin) {
      if (!checkIfLogin()) {
        navigate("/login", { replace: true });
        localStorage.clear();
        dispatch(resetGlobal());
        dispatch(resetWorkPlan());
        dispatch(resetGISDashboard());
      }
    }
  }, [navigate, dispatch, isLogin]);

  return checkIfLogin() ? <Outlet /> : null;
}
