import React, { useEffect, useState } from "react";
import {
  ControlPosition,
  Map,
  useMap,
  useMapsLibrary,
} from "@vis.gl/react-google-maps";
import { useDispatch, useSelector } from "react-redux";
import { calculateBounds } from "../../utils/mapZoomCalculation";
import { getCrewMemberColor } from "../../utils/common";
import {
  setIsStreetView,
  setSelectedCrewMembersPathId,
} from "../../slices/globalSlice";
import "./PlannerGoogleMaps.css";
import CrewMemberServiceLayers from "./Layers/CrewMemberServiceLayers";
import InitialServiceLayers from "./Layers/InitialServiceLayers";
import EntryPoints from "./Layers/EntryPoints";
import TransitionDottedLines from "./Layers/TransitionDottedLines";
import ParkingsMarkers from "./Markers/ParkingMarkers";
import CrewMemberWiseDirectionArrows from "./Layers/CrewMemberWiseDirectionArrows";
import TimelineHoveredPolygonLayer from "./Layers/TimelineHoveredPolygonLayer";
import PolygonDetailsPopup from "./PolygonDetailsPopup";
import MapHoveredPolygonLayer from "./Layers/MapHoveredPolygonLayer";
import WayPointsMarker from "./Markers/WayPointsMarker";
import HighlightLastAndPreviousConnectingLines from "./Layers/HighlightLastAndPreviousConnectingLines";
import ShortestLoopLines from "./Layers/ShortestLoopLines";
import useViewportWidth from "../../Hooks/useViewportWidth";
import UploadedWayPointsMarkers from "./Markers/UploadedWayPointsMarkers";

const PlannerGoogleMaps = ({ hoveredPolygon, crewMemberDayWise, mapData }) => {
  const dispatch = useDispatch();
  const lookUpData = useSelector((state) => state.global.lookUpData);
  const selectedPolygon = useSelector((state) => state.global.selectedPolygon);
  const showNumberCircles = useSelector(
    (state) => state.global.showNumberCircles
  );
  const showConnectingLines = useSelector(
    (state) => state.global.showConnectingLines
  );
  const [filteredGeoJson, setFilteredGeoJson] = useState(null);
  const [hoveredGeoJson, setHoveredgeoJson] = useState(null);
  const [mapHoveredGeoJson, setMapHoveredgeoJson] = useState(null);
  const [clickedPolygonData, setClickedPolygonData] = useState(null);
  const [hoveredPolygonId, setHoveredPolygonId] = useState(null);
  const width = useViewportWidth();
  const showArrowMarkers = useSelector(
    (state) => state.global.showArrowMarkers
  );
  const showShortestLoop = useSelector(
    (state) => state.global.showShortestLoop
  );
  const selectedServices = useSelector(
    (state) => state.global.selectedServices
  );
  const showWayPoints = useSelector((state) => state.global.showWayPoints);

  const crewMemberDayWiseFilteredData = useSelector(
    (state) => state.global.crewMemberDayWiseFilteredData
  );
  const showLabels = useSelector((state) => state.global.showLabels);
  const selectedCrewMembersPathId = useSelector(
    (state) => state.global.selectedCrewMembersPathId
  );
  const selectedCrewMembersId = useSelector(
    (state) => state.global.selectedCrewMembersId
  );
  const selectedDay = useSelector((state) => state.global.selectedDay);
  const map = useMap();
  const mapsLibrary = useMapsLibrary("maps");
  const [viewport, setViewport] = useState({
    center: { lat: 40.7, lng: -74 },
    zoom: 12,
    width: 800,
    height: 400,
    bearing: 0,
    streetViewControl: true,
    fullscreenControl: false,
    fullscreenControlOptions: true,
  });

  const serviceNameToIdMap = Object.entries(lookUpData?.services)?.reduce(
    (acc, [id, { serviceName }]) => {
      // Normalize the service names to match the format of the selectedServices keys
      const normalizedServiceName = serviceName
        .replace(/_/g, " ")
        .toLowerCase();
      acc[normalizedServiceName] = id;
      return acc;
    },
    {}
  );

  const selectedServiceIds = Object.keys(selectedServices)
    .filter((service) => selectedServices[service])
    .map((service) => serviceNameToIdMap[service?.toLowerCase()])
    .filter((id) => id !== undefined); // Ensure the service name was found in the map

  useEffect(() => {
    if (selectedCrewMembersId) {
      const crewMemberPolygons = {}; // Object to hold crewMemberId: [polygons]

      Object.keys(lookUpData.crewMemberDayWise).forEach((crewMemberId) => {
        const isSelected = selectedCrewMembersId[crewMemberId];

        if (isSelected) {
          const crewMember = lookUpData.crewMemberDayWise[crewMemberId];
          crewMemberPolygons[crewMemberId] = [];

          crewMember?.day.forEach((day) => {
            if (day?.day === selectedDay) {
              // Check if the day matches the selected day
              crewMemberPolygons[crewMemberId] = day?.polygon;
            } else if (selectedDay == "All") {
              crewMemberPolygons[crewMemberId] = [
                ...crewMemberPolygons[crewMemberId],
                ...day?.polygon,
              ];
            }
          });

          // case: unAssigned Polygons
          if (crewMember?.crewId === 0) {
            crewMember?.day?.forEach((day) => {
              crewMemberPolygons[crewMemberId] = day?.polygon;
            });
          }
        }
      });
      dispatch(setSelectedCrewMembersPathId(crewMemberPolygons));
    }
  }, [selectedCrewMembersId]);

  // Update the viewport when mapData changes
  useEffect(() => {
    if (!mapData || !mapData.features || mapData.features.length === 0) {
      return;
    }

    const bounds = calculateBounds(mapData.features);
    setViewport((prevViewport) => ({
      ...prevViewport,
      center: {
        lat: (bounds[1] + bounds[3]) / 2,
        lng: (bounds[0] + bounds[2]) / 2,
      },

      zoom: 16.5,
    }));
  }, [mapData, showLabels]);

  useEffect(() => {
    const filterGeoJson = () => {
      if (mapData && mapData?.features && selectedCrewMembersPathId) {
        let filteredGeoJsonByCrewMember = [];

        for (const crewMemberId in selectedCrewMembersPathId) {
          const pathIds = selectedCrewMembersPathId[crewMemberId];

          const filteredFeatures = mapData.features.filter(
            (feature) => selectedServices[feature.properties.serviceType]
          );

          const filteredFeaturesForPathId = filteredFeatures.filter((feature) =>
            pathIds.includes(parseInt(feature.properties.pathId))
          );

          const updatedFeatureWithColor = filteredFeaturesForPathId.map(
            (feature) => {
              const updatedProperties = {
                ...feature.properties,
                color: getCrewMemberColor(
                  crewMemberId,
                  lookUpData?.crewMemberDayWise
                ),
              };
              const featureWithColor = {
                ...feature,
                properties: updatedProperties,
              };

              return featureWithColor;
            }
          );

          filteredGeoJsonByCrewMember = [
            ...filteredGeoJsonByCrewMember,
            ...updatedFeatureWithColor,
          ];
        }
        const aggregatedFilteredGeoJson = {
          ...mapData,
          features: filteredGeoJsonByCrewMember,
        };

        setFilteredGeoJson(aggregatedFilteredGeoJson);
      }
    };

    filterGeoJson();
  }, [selectedCrewMembersPathId, mapData, selectedServices, showLabels]);

  useEffect(() => {
    if (mapData && mapData?.features && hoveredPolygon) {
      const filteredFeatures = mapData.features.filter(
        (feature) => feature.properties.pathId == hoveredPolygon.id
      );
      const filteredGeoJsonData = { ...mapData, features: filteredFeatures };
      setHoveredgeoJson(filteredGeoJsonData);
    } else {
      setHoveredgeoJson(null);
    }
  }, [hoveredPolygon, mapData]);

  const getEntryPointForPolygonId = (polygonId) => {
    const polygon = lookUpData?.polygons[polygonId];

    if (polygon) {
      const entryPoint = polygon.entryPoint;
      return entryPoint;
    }

    return null;
  };

  const handleClickedPolygon = (clickedData) => {
    const coordinates = clickedData?.coordinate;
    const properties = clickedData?.object?.properties;
    if (clickedData) {
      const selectedPolygonId = clickedData?.object?.properties?.pathId;

      const selectedPolygonData = lookUpData.polygons[selectedPolygonId];
      if (selectedPolygonData) {
        setClickedPolygonData({
          ...selectedPolygonData,
          coordinates: coordinates,
          properties: properties,
        });
      } else {
        setClickedPolygonData(null);
      }
    } else {
      setClickedPolygonData(null);
    }
  };

  const handleHoveredPolygon = (hoveredData, event) => {
    if (hoveredData) {
      const selectedPolygonId = hoveredData?.object?.properties?.pathId;
      setHoveredPolygonId(selectedPolygonId);

      const filteredFeatures = mapData?.features?.filter(
        (feature) =>
          feature.properties.pathId !== undefined &&
          feature.properties.pathId === selectedPolygonId
      );
      const filteredGeoJsonData = { ...mapData, features: filteredFeatures };
      if (filteredGeoJsonData) {
        setMapHoveredgeoJson(filteredGeoJsonData);
      } else {
        setMapHoveredgeoJson(null);
        setHoveredPolygonId(null);
      }
    } else {
      setMapHoveredgeoJson(null);
      setHoveredPolygonId(null);
    }
  };
  useEffect(() => {
    if (mapsLibrary && map) {
      setViewport((prev) => ({
        ...prev,
        mapTypeControlOptions: {
          style: mapsLibrary?.MapTypeControlStyle?.HORIZONTAL_BAR,
          position: 0,
        },
      }));
      const panorama = map.getStreetView();
      panorama.addListener("visible_changed", () => {
        dispatch(setIsStreetView(panorama.getVisible()));
      });
    }
  }, [mapsLibrary, map]);

  // console.log("map id ==>> ",process.env.REACT_APP_GOOGLE_MAPS_ID)
  return (
    <div style={{ height: "92vh", width: "100%" }}>
      <Map
        {...viewport}
        zoom={viewport?.zoom}
        defaultZoom={viewport?.zoom}
        center={viewport?.center}
        defaultCenter={viewport?.center}
        onCameraChanged={(v) => setViewport(v)}
        gestureHandling={"greedy"}
        draggableCursor="default" // Change this to any cursor style you want when the map is idle
        draggingCursor="move"
        tiltInteractionEnabled={true}
        defaultTilt={0}
        reuseMaps={true}
        mapId={showLabels ? "6f98ed508430fd59" : "19d9cc996698ed9f"}
        defaultBounds={{
          south: 40.5,
          west: -74.2,
          north: 40.9,
          east: -73.8,
        }}
        mapTypeControlOptions={{
          style: mapsLibrary?.MapTypeControlStyle?.HORIZONTAL_BAR,
          position: ControlPosition.TOP_CENTER,
        }}
      >
        <InitialServiceLayers
          hoveredGeoJson={hoveredGeoJson}
          filteredGeoJson={filteredGeoJson}
          handleClickedPolygon={handleClickedPolygon}
        />
        <CrewMemberServiceLayers
          handleHoveredPolygon={handleHoveredPolygon}
          filteredGeoJson={filteredGeoJson}
        />
        <TransitionDottedLines
          hoveredPolygon={hoveredPolygon}
          filteredGeoJson={filteredGeoJson}
          crewMemberDayWise={crewMemberDayWiseFilteredData}
          lookUpData={lookUpData}
          showConnectingLines={showConnectingLines}
          selectedServiceIds={selectedServiceIds}
        />

        {showArrowMarkers && (
          <CrewMemberWiseDirectionArrows
            filteredGeoJson={filteredGeoJson}
            crewMemberDayWise={crewMemberDayWise}
            viewport={viewport}
            lookUpData={lookUpData}
            position={90}
            selectedServiceIds={selectedServiceIds}
            getEntryPointForPolygonId={getEntryPointForPolygonId}
          />
        )}
          <TimelineHoveredPolygonLayer hoveredGeoJson={hoveredGeoJson} />
          <MapHoveredPolygonLayer hoveredGeoJson={mapHoveredGeoJson} />
        {/* to show popup when we hover over the polygon */}
          <PolygonDetailsPopup
            selectedPolygon={selectedPolygon}
            clickedPolygonData={clickedPolygonData}
            setClickedPolygonData={setClickedPolygonData}
            lookUpData={lookUpData}
          />
          <WayPointsMarker
            crewMemberDayWise={crewMemberDayWise}
            selectedServiceIds={selectedServiceIds}
            filteredGeoJson={filteredGeoJson}
          />
          <UploadedWayPointsMarkers
            crewMemberDayWise={crewMemberDayWise}
            selectedServiceIds={selectedServiceIds}
            filteredGeoJson={filteredGeoJson}
          />
        {width > 800 && (
          <HighlightLastAndPreviousConnectingLines
            hoveredPolygon={hoveredPolygon}
            filteredGeoJson={filteredGeoJson}
            crewMemberDayWise={crewMemberDayWiseFilteredData}
            lookUpData={lookUpData}
            showConnectingLines={showConnectingLines}
            selectedServiceIds={selectedServiceIds}
            hoveredPolygonId={hoveredPolygonId}
            hoveredGeoJson={mapHoveredGeoJson}
          />
        )}
        {width > 800 && <ShortestLoopLines />}
        <EntryPoints
          filteredGeoJson={filteredGeoJson}
          crewMemberDayWise={crewMemberDayWiseFilteredData}
          selectedServiceIds={selectedServiceIds}
          showNumberCircles={showNumberCircles}
          getEntryPointForPolygonId={getEntryPointForPolygonId}
        />
        <ParkingsMarkers lookUpData={lookUpData} />
      </Map>
    </div>
  );
};

export default PlannerGoogleMaps;
