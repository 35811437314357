import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    animationState:"stopped",
    animatedLines:[],
    markerPositions: [],
    completedLines:[],
    animationSpeed:1,
};

const animationSlice = createSlice({
  name: "animation",
  initialState,
  reducers: {
    reset: () => initialState,
    setAnimationState: (state, action) => {
      return {
        ...state,
        animationState: action.payload,
      };
    },
    setAnimatedLines: (state, action) => {
      return {
        ...state,
        animatedLines: action.payload,
      };
    },
    setMarkerPositions: (state, action) => {
      return {
        ...state,
        markerPositions: action.payload,
      };
    },
    setCompletedLines: (state, action) => {
      return {
        ...state,
        completedLines: action.payload,
      };
    },
    setAnimationSpeed: (state, action) => {
      return {
        ...state,
        animationSpeed: action.payload,
      };
    }
  },
});

export const {
  reset,
  setAnimationState,
  setAnimatedLines,
  setMarkerPositions,
  setCompletedLines,
  setAnimationSpeed,
} = animationSlice.actions;

export default animationSlice.reducer;
