import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setAnimationData,
  setInitialCrewMembers,
  setLandingPageMap,
  setLookUpData,
  setMapData,
  setResetAnimation,
} from "../../slices/globalSlice";
import { useGetWorkPlansMutation } from "../../services/Properties";
import "./LocationGroupModal.css";
import {
  ShuffleJson,
  assignCrewMemberColors,
  assignCrewMemberColorsToAnimation,
  calculateServicesTotal,
  calculateTotalAssignmentsForCrewMember,
} from "../../utils/common";
import {
  setWorkerPlanFolderData,
  setSelectedServiceType,
  setSelectedLocation,
  setIsLocationSet,
} from "../../slices/workPlanSlice";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import useViewportWidth from "../../Hooks/useViewportWidth";

export const fetchS3Data = async (url) => {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Data could not be fetched!");
    }
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    toast.error("Something went wrong", {
      autoClose: 1000,
      hideProgressBar: true,
    });
  } finally {
    // setIsLoading(false);
  }
};

const LocationGroupModal = ({ isOpen, initial, setModal }) => {
  const width = useViewportWidth();
  const [locationValue, setLocationValue] = useState("");
  const [workPlanSelect, setWorkPlanSelect] = useState("");
  const [isFetchingFiles, setIsFetchingFiles] = useState(false);
  const user = useSelector((state) => state.global.user);
  const selectedLocation = useSelector(
    (state) => state.workPlan.selectedLocation
  );
  const selectedServiceType = useSelector(
    (state) => state.workPlan.selectedServiceType
  );
  const [getWorkPlans, { isLoading }] = useGetWorkPlansMutation();
  const locationGroupData = useSelector(
    (state) => state.workPlan.locationGroupData
  );
  const workerPlanFolderData = useSelector(
    (state) => state.workPlan.workerPlanFolderData
  );
  const dispatch = useDispatch();

  useEffect(() => {
    setLocationValue(selectedLocation);
    setWorkPlanSelect(selectedServiceType);
  }, [selectedLocation, selectedServiceType]);
  const handleSubmit = () => {
    setIsFetchingFiles(true)
    const currentFolder = workerPlanFolderData.filter(
      (item) => item.key == workPlanSelect.key
    );
    const MapScreenGeoJson = currentFolder[0]?.files?.filter(
      (item) => item.file_type == "site_plan_map"
    );
    const LookupFile = currentFolder[0]?.files?.filter(
      (item) => item.file_type == "planner_output_lookup"
    );

    const animationFile = currentFolder[0]?.files?.filter(
      (item) => item.file_type == "planner_animation"
    );

    if (animationFile?.length > 0) {
      fetchS3Data(animationFile[0]?.file_s3_url).then((data) => {
        const animatedData = assignCrewMemberColorsToAnimation(data);
        if (animatedData.hasOwnProperty("0")) {
          delete animatedData["0"];
        }
        dispatch(setAnimationData(animatedData));
      });
    }

    // Assuming MapScreenGeoJson and LookupFile are already defined earlier in your code.
    if (MapScreenGeoJson?.length > 0 && LookupFile?.length > 0) {
      // Use Promise.all to attempt both fetchS3Data operations.
      Promise.all([
        fetchS3Data(LookupFile[0]?.file_s3_url),
        fetchS3Data(MapScreenGeoJson[0]?.file_s3_url),
      ])
        .then(([lookupData, mapData]) => {
          // Process lookup file data
          const fileData = lookupData?.lookUpOutput
            ? lookupData?.lookUpOutput
            : lookupData;
          const final = calculateTotalAssignmentsForCrewMember(fileData);
          const withServices = calculateServicesTotal(final);
          const crewColors = assignCrewMemberColors(withServices);

          // Dispatch actions for lookup data
          dispatch(setLookUpData(crewColors));
          dispatch(setInitialCrewMembers(withServices?.crewMemberDayWise));

          // Process map file data
          ShuffleJson(mapData);
          dispatch(setMapData(mapData));
          dispatch(setLandingPageMap(mapData));

          // Common actions after both fetches are successful
          dispatch(setSelectedLocation(locationValue));
          dispatch(setSelectedServiceType(workPlanSelect));
          dispatch(setIsLocationSet(true));
          dispatch(setResetAnimation(true));

          if (initial) {
            toast.success("Plan Added Successfully", {
              autoClose: 1000,
              hideProgressBar: true,
            });
          } else {
            toast.success("Plan Changed Successfully", {
              autoClose: 1000,
              hideProgressBar: true,
            });
          }

          localStorage.setItem("current location", JSON.stringify(locationValue));
          localStorage.setItem("current folder", JSON.stringify(workPlanSelect));
          setIsFetchingFiles(false)
          setModal(false);
        })
        .catch((error) => {
          setIsFetchingFiles(false)
          // Handle any errors that occurred during fetch
          toast.error("An error occurred with the file format", {
            autoClose: 2000,
            hideProgressBar: true,
          });
        });
    } else {
      toast.error("Files are not available for this plan", {
        autoClose: 1000,
        hideProgressBar: true,
      });
      setIsFetchingFiles(false)
    }
  };

  const handleLocationSelectChange = async (event, newValue) => {
    const value = newValue ? newValue.key : null;
    setLocationValue(newValue);
    setWorkPlanSelect("");

    if (value) {
      const data = {
        organization: user.organization,
        locationGroup: newValue.key,
      };
      try {
        const workDataFolder = await getWorkPlans(data).unwrap();
        dispatch(setWorkerPlanFolderData(workDataFolder.results));
      } catch (error) {
        toast.error(
          error?.originalStatus === 500 ? "Something Went Wrong" : error
        );
      }
    }
  };

  const handleWorkPlanSelectChange = (event, newValue) => {
    setWorkPlanSelect(newValue);
  };
  const handleCancel = () => {
    setModal(false);
  };
  return (
    <Modal
      open={isOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: width < 500 ? "100%": 400,
          padding: width < 500 && "20px",
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ mb: 2 }}
        >
          Select location to view site
        </Typography>
        {locationGroupData ? (
          <FormControl fullWidth>
            <Autocomplete
              id="combo-box-demo"
              options={locationGroupData}
              getOptionLabel={(option) => {
                return option ? option.name : "";
              }}
              value={locationValue || null}
              onChange={handleLocationSelectChange}
              renderInput={(params) => (
                <TextField {...params} label="Select Location" />
              )}
              isOptionEqualToValue={(option, value) =>
                option && value && option.key === value
              }
            />
            {locationValue && (
              <div className="secondSelect">
                <FormControl fullWidth>
                  <Autocomplete
                    id="combo-box-demo"
                    options={workerPlanFolderData}
                    disabled={isLoading}
                    getOptionLabel={(option) => {
                      return option ? option.name : "";
                    }}
                    value={workPlanSelect || null}
                    onChange={handleWorkPlanSelectChange}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Service Type" />
                    )}
                    isOptionEqualToValue={(option, value) =>
                      option && value && option.key === value
                    }
                  />
                </FormControl>
              </div>
            )}
          </FormControl>
        ) : (
          <CircularProgress />
        )}
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            justifyContent: "flex-end",
            mt: 2,
          }}
        >
          {!initial && (
            <Button variant="outlined" onClick={handleCancel}>
              Cancel
            </Button>
          )}
          <LoadingButton
            variant="contained"
            onClick={handleSubmit}
            loading={isFetchingFiles}
            disabled={!locationValue || !workPlanSelect}
          >
            View
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default LocationGroupModal;
