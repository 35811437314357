import React, { useEffect, useRef, useState } from "react";
import Timeline from "react-visjs-timeline";
import "vis/dist/vis.min.css";
import { useSelector } from "react-redux";
import {
  convertStartAndEndTimes,
  formatTimestampForCrewIntel,
  getTimelineColorBasedOnLocationKey,
  locationKeyNameConversion,
} from "../../../utils/common";

const DeviceBasedTimeline = () => {
  const timelineRef = useRef();
  const lookUp = useSelector((state) => state.global.lookUpData);
  const selectedDay = useSelector((state) => state.global.selectedDay);
  const summaryData = useSelector((state) => state.gisDashboard.summaryData);
  const [groupData, setGroupData] = useState([]);
  const [itemsData, setItemsData] = useState([]);
  const [itemsSummaryData, setItemsSummaryData] = useState([]);
  const [summary, setSummary] = useState(false);
  const [options, setOptions] = useState({
    stack: false,
    height: "32vh",
    zoomMin: 1000, // Adjust as necessary
    zoomMax: 100000000000,
    orientation: { axis: "top", item: "top" },
    groupOrder: "content",
    showTooltips: true,
    preferZoom: true,
    verticalScroll: true,
    groupHeightMode: "fitItems",
    groupOrder: function (a, b) {
      return b.value - a.value;
    },
  });

  const transformDataForTimeline = (summaryData) => {
    const timelineData = [];
  
    summaryData?.forEach((summary) => {
      const { location_key, devices_list } = summary || {};
      const locationKey = locationKeyNameConversion(location_key)

  
      if (devices_list) {
        Object.entries(devices_list).forEach(([deviceId, deviceData]) => {
          const { sessions } = deviceData || {};
  
          if (sessions) {
            Object.entries(sessions).forEach(([date, timeStamps]) => {
              if (Array.isArray(timeStamps)) {
                timeStamps.forEach((timeStamp, index) => {
                  if (Array.isArray(timeStamp) && timeStamp.length === 2) {
                    const [start, end] = timeStamp;
  
                    timelineData.push({
                      id: `${locationKey}-${deviceId}-${date}-${index}`,
                      start: formatTimestampForCrewIntel(Math.ceil(start)),
                      end: formatTimestampForCrewIntel(Math.ceil(end)),
                      group: `${locationKey}-${deviceId}`,
                      className: `${getTimelineColorBasedOnLocationKey(location_key)}`, // Custom CSS class
                    });
                  }
                });
              }
            });
          }
        });
      }
    });
  
    return timelineData;
  };

  const transformGroupDataForTimeline = (summaryData) => {
    const groupData = [];
    const uniqueDevices = {}

    if (summaryData) {
      summaryData?.forEach((summary) => {
        if (!summary) {
          return;
        }
        const locationKey = locationKeyNameConversion(summary?.location_key)

        const data = Object.keys(summary?.devices_list || {}).map((deviceId) => {
            return `${locationKey}-${deviceId}`
          })
    
        Object.keys(summary?.devices_list || {}).map((deviceId) => {
          if (!deviceId) {
            return;
          }
          const group = {
            id: `${locationKey}-${deviceId}`,
            content: `${locationKey}`,
          };
          if(uniqueDevices[deviceId]){
            uniqueDevices[deviceId].nestedGroups = [`${locationKey}-${deviceId}`,...uniqueDevices[deviceId].nestedGroups]
          } else {
            uniqueDevices[deviceId] = {
              nestedGroups: [`${locationKey}-${deviceId}`]
            }
          }
    
          groupData.push(group);
        });
      });
    }

    Object.keys(uniqueDevices).map((deviceId)=>{
        groupData.push({
            id: deviceId,
            content: `${deviceId}`,
            showNested: false,
            nestedGroups: uniqueDevices[deviceId]?.nestedGroups,
        })
        
    })

    return groupData;
  };

  const findMinMaxTimeFromLookup = (summaryData) => {
    let minStartTime = null;
    let maxEndTime = null;

    summaryData?.forEach((summary) => {
        const { location_key, devices_list } = summary || {};
    
        if (devices_list) {
          Object.entries(devices_list).forEach(([deviceId, deviceData]) => {
            const { sessions } = deviceData || {};
    
            if (sessions) {
              Object.entries(sessions).forEach(([date, timeStamps]) => {
                if (Array.isArray(timeStamps)) {
                  timeStamps.forEach((timeStamp, index) => {
                    if (Array.isArray(timeStamp) && timeStamp.length === 2) {
                      const [start, end] = timeStamp;

                      const startDate = formatTimestampForCrewIntel(
                        start
                      );
                      const endDate = formatTimestampForCrewIntel(
                        end
                      );
                      if (!minStartTime || startDate < minStartTime) {
                        minStartTime = startDate;
                      }
                      if (!maxEndTime || endDate > maxEndTime) {
                        maxEndTime = endDate;
                      }
                    }
                  });
                }
              });
            }
          });
        }
      });

    return { minStartTime, maxEndTime };
  };

  useEffect(() => {
    if (summaryData) {
      let lookupData = { ...lookUp };
        const timelineData = transformDataForTimeline(summaryData);
        setItemsData(timelineData);
      //   const timelineDataSummary = transformDataForTimelineSummary(lookupData);
      //   setItemsSummaryData(timelineDataSummary);
      //   setItemsData((prevData) => [...prevData, ...timelineDataSummary]);
      const groupData = transformGroupDataForTimeline(summaryData);
      setGroupData(groupData);
      const bounds = findMinMaxTimeFromLookup(summaryData);
      const chartStart = new Date(bounds.minStartTime);
      const chartEnd = new Date(bounds.maxEndTime);
      const adjustedTimes = convertStartAndEndTimes(chartStart, chartEnd);
      const clusterOpts = {
        start: adjustedTimes.start,
        end: adjustedTimes.end,
        min: adjustedTimes.start + 1,
        max: adjustedTimes.end + 1,
      };
      setOptions((prevOptions) => ({
        ...prevOptions,
        ...clusterOpts,
      }));
    }
  }, [summaryData, selectedDay]);

  return (
    <div>
      {groupData && (
        <Timeline
          ref={timelineRef}
          options={options}
          groups={groupData}
          items={summary ? itemsSummaryData : itemsData}
        />
      )}
    </div>
  );
};

export default DeviceBasedTimeline;
