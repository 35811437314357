import React from "react";
import { Card, CardContent, Typography, Grid, Box, styled } from "@mui/material";
import { getCustomColor } from "../../utils/theme";
import { getMeasuringUnit } from "../../utils/common";

// Define a custom styled component for the scrollable card
const ScrollableCard = styled(Card)(({ theme }) => ({
  background: "#edf6f0",
  boxShadow: "none",
  height: "200px",
  overflow: "auto", // Enable scrolling when content exceeds height
}));

const ParcelService = ({ parcelService }) => {
  const blackShade1 = getCustomColor("blackShade1");

  return (
    <Grid container spacing={3} justifyContent="center" alignItems="center">
      {parcelService?.map((service, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          {/* Replace Card with ScrollableCard */}
          <ScrollableCard className="parcelService">
            <CardContent>
              <Typography variant="h5">{service.services}</Typography>
              <Box>
                <Typography
                  sx={{ color: blackShade1.main }}
                  fontWeight="600"
                  variant="body1"
                  component="span"
                  color="text.secondary"
                >
                  Skills:{" "}
                </Typography>
                <Typography
                  variant="body1"
                  component="span"
                  color="text.secondary"
                >
                  {service.parcelSkills}
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{ color: blackShade1.main }}
                  fontWeight="600"
                  variant="body1"
                  component="span"
                  color="text.secondary"
                >
                  Equipments:{" "}
                </Typography>
                <Typography
                  variant="body1"
                  component="span"
                  color="text.secondary"
                >
                    {service?.parcelEquipments?.map((equipment, idx) => (
                      <div key={idx}>

                       <Typography
                       fontWeight={"700"}
                       sx={{ color: blackShade1.light }}
                       component="div"
                       variant="body1"
                     >
                       {equipment.equipmentName}
                     </Typography>
                     <Typography
                       sx={{ color: blackShade1.light }}
                       component="div"
                       variant="body2"
                     >
                       Production Rate: {equipment.productionRate?.toFixed(2)} {getMeasuringUnit(equipment?.productionRateUnit)} 
                     </Typography>
                       <Typography
                       sx={{ color: blackShade1.light }}
                       component="div"
                       variant="body2"
                     >
                       Transition Pace: {equipment?.transitionPace?.toFixed(2)} {getMeasuringUnit(equipment?.transitionPaceUnit)} 
                     </Typography>
                      </div>
                    ))}
                </Typography>
              </Box>
            </CardContent>
          </ScrollableCard>
        </Grid>
      ))}
    </Grid>
  );
};

export default ParcelService;
