import { Box, Divider, Skeleton, Switch, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { RiArrowLeftFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import {
  setCoveredLayersDateWiseGeojson,
  setDetailedData,
  setIsPathOfMotion,
  setIsSummary,
  setPathOfMotionData,
  setPathOfMotionEntryPointsData,
  setSelectedGISServiceDate,
  setSelectedGISServicePathOfMotion,
  setSelectedServiceForDetails,
} from "../../slices/gisDashboardSlice";
import {
  convertAreaUnitToStoredUnit,
  convertSecondsToHoursAndMinutes,
  getServiceColorFromLocationKey,
  getWeekOfYear,
} from "../../utils/common";
import { getCustomColor } from "../../utils/theme";
import { useLazyGetServiceDailyQuery } from "../../services/Properties";
import OperationTable from "./Layers/OperationTable";
import PathOfMotionDetails from "./Component/PathOfMotionDetails";
import useViewportWidth from "../../Hooks/useViewportWidth";

const SidebarDetail = () => {
  const blackShade1 = getCustomColor("blackShade1");
  const greenShade1 = getCustomColor("greenShade1");
  const metaData = localStorage.getItem("metaData");
  const width = useViewportWidth();
  const areaUnit = metaData?.units_config?.area?.type;
  const dispatch = useDispatch();
  const [operationTableData, setOpertaionTableData] = useState([]);
  const [getServiceDaily, { isLoading }] = useLazyGetServiceDailyQuery();
  const selectedDay = useSelector((state) => state.gisDashboard.selectedDay);
  const isPathOfMotion = useSelector(
    (state) => state.gisDashboard.isPathOfMotion
  );
  const user = JSON.parse(localStorage.getItem("user"));
  const selectedDevices = useSelector(
    (state) => state.gisDashboard.selectedDevices
  );
  const selectedGISServicePathOfMotion = useSelector(
    (state) => state.gisDashboard.selectedGISServicePathOfMotion
  );
  const selectedServiceForDetails = useSelector(
    (state) => state.gisDashboard.selectedServiceForDetails
  );
  const selectedLocationValue = useSelector(
    (state) => state.gisDashboard.selectedLocationValue
  );
  const weekCalenderValue = useSelector(
    (state) => state.gisDashboard.weekCalenderValue
  );
  const handleCloseDetails = () => {
    dispatch(setIsSummary(true));
    dispatch(setSelectedServiceForDetails(null));
    // dispatch(setIsPathOfMotion(!isPathOfMotion));
  };

  const time = convertSecondsToHoursAndMinutes(
    selectedServiceForDetails?.total_duration
  );

  const getServicesDailyData = async () => {
    const response = await getServiceDaily({
      orgName: user?.organization,
      location: `${selectedLocationValue?.key?.toUpperCase()}-${getWeekOfYear(
        weekCalenderValue
      )}`,
      service: selectedServiceForDetails?.location_key,
    });
    setOpertaionTableData(response?.data?.results);
    dispatch(setDetailedData(response?.data?.results));
  };

  useEffect(() => {
    if (selectedServiceForDetails) {
      getServicesDailyData();
    }
  }, [selectedServiceForDetails]);

  useEffect(() => {
    if (operationTableData) {
      const uniqueServices = {};
      operationTableData.forEach((summary) => {
        if (!uniqueServices[summary.date]) {
          uniqueServices[summary.date] = true;
        }
      });
      dispatch(setSelectedGISServiceDate(uniqueServices));
    }
  }, [operationTableData]);

  useEffect(() => {
    if (operationTableData) {
      let coveredGeoJson = {
        type: "FeatureCollection",
        features: [],
      };

      operationTableData?.map((summary) => {
        let featureObj = {
          type: "Feature",
          properties: {
            location_key: summary.location_key,
            serviceName: getServiceColorFromLocationKey(summary.location_key),
            date: summary.date,
          },
          geometry:
            summary?.location_key?.includes("hard-edge") ||
            summary?.location_key?.includes("hardedge") ||
            summary?.location_key?.includes("softedge") ||
            summary?.location_key?.includes("soft-edge")
              ? summary?.distance_covered_geom
              : summary?.area_covered_geom,
        };
        return coveredGeoJson.features.push(featureObj);
      });
      dispatch(setCoveredLayersDateWiseGeojson(coveredGeoJson));
    }
  }, [operationTableData]);

  useEffect(() => {
    if (operationTableData) {
      const features = [];

      operationTableData.forEach((item) => {
        if (isPathOfMotion && selectedDay === item.date) {
          Object.keys(item.devices_list).forEach((device) => {
            Object.keys(item.devices_list[device].path_of_motion).forEach(
              (path) => {
                const feature = {
                  type: "Feature",
                  geometry: item.devices_list[device].path_of_motion,
                  properties: {
                    location_key: item.location_key,
                    device_id: device,
                    day: path,
                  },
                };
                if (selectedDevices[device]) {
                  features.push(feature);
                }
              }
            );
          });
        }
      });

      const result = [];

      features?.forEach((feature) => {
        const { coordinates } = feature.geometry;
        for (let i = 0; i < coordinates.length - 1; i++) {
          result.push({
            source: coordinates[i],
            target: coordinates[i + 1],
            deviceId: feature.properties.device_id,
          });
        }
      });

      dispatch(setPathOfMotionData(result));
    }
  }, [operationTableData, selectedDevices, selectedDay, isPathOfMotion]);

  useEffect(() => {
    if (operationTableData) {
      const features = {};

      operationTableData.forEach((item) => {
        if (isPathOfMotion && selectedDay === item.date) {
          Object.keys(item.devices_list).forEach((device) => {
            if (selectedDevices[device]) {
              Object.keys(item.devices_list[device].path_of_motion).forEach(
                (path) => {
                  features[device] = [
                    ...item.devices_list[device].path_of_motion.coordinates,
                  ];
                }
              );
            }
          });
        }
      });

      const result = [];

      Object.keys(features)?.forEach((device) => {
        for (let i = 0; i < features[device].length - 1; i++) {
          result.push({
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: features[device][i],
            },
            properties: {
              id: `${device}-${i}`, // Unique identifier for the marker
              //   crewMemberId: crewMemberId,
              deviceId: device,
              text: i + 1, // Text or label for the marker
            },
          });
        }
      });

      const finalData = {
        type: "FeatureCollection",
        features: result,
      };

      dispatch(setPathOfMotionEntryPointsData(finalData));
    }
  }, [operationTableData, selectedDevices, selectedDay, isPathOfMotion]);

  return (
    <>
      <Box className="sidebarSelectContainer">
        <Box className="sidebarDetailHeaderLeft">
          <RiArrowLeftFill cursor="pointer" onClick={handleCloseDetails} />
          <Box className="calenderButton" sx={{ background: greenShade1.main }}>
            <Typography
              component="div"
              fontWeight="600"
              fontSize="12px"
              sx={{ color: "primary.dark" }}
              variant="subtitle2"
            >
              {selectedLocationValue?.name}
            </Typography>
          </Box>
          <Typography
            component="div"
            fontWeight="600"
            fontSize="12px"
            sx={{ color: "primary.dark" }}
            variant="subtitle2"
          >
            {selectedServiceForDetails?.location_key}
          </Typography>
        </Box>
      </Box>

      <Divider />
      <Box className="summaryCardBoxDetails">
        <div className="summaryCardBodyContainer">
          <Typography
            component="h3"
            fontWeight="600"
            sx={{ color: blackShade1.main, marginTop: "10px" }}
            variant="body2"
          >
            {selectedServiceForDetails?.completion_percentage.toFixed(1)}% Area
            covered with{" "}
            {Object.keys(selectedServiceForDetails?.devices_list)?.length}{" "}
            Members and{" "}
            {Object.keys(selectedServiceForDetails?.beacons_list)?.length}{" "}
            Equipments
          </Typography>
          <div className="summaryCalculationContainer">
            <div className="summaryCalculationContainerLeft">
              <div>
                <Typography
                  component="span"
                  fontWeight="600"
                  sx={{ color: "primary.dark" }}
                  variant="body1"
                >
                  {selectedServiceForDetails?.location_key?.includes(
                    "hard-edge"
                  ) ||
                  selectedServiceForDetails?.location_key?.includes(
                    "hardedge"
                  ) ||
                  selectedServiceForDetails?.location_key?.includes(
                    "soft-edge"
                  ) ||
                  selectedServiceForDetails?.location_key?.includes("softedge")
                    ? convertAreaUnitToStoredUnit(
                        selectedServiceForDetails?.cumulative_distance_covered,
                        "m",
                        areaUnit || "ft"
                      )
                    : convertAreaUnitToStoredUnit(
                        selectedServiceForDetails?.cumulative_area_covered,
                        "sqm",
                        areaUnit || "sqft"
                      )}
                </Typography>{" "}
                <Typography
                  component="span"
                  sx={{ color: "primary.dark" }}
                  variant="subtitle2"
                >
                  {selectedServiceForDetails?.location_key?.includes(
                    "hard-edge"
                  ) ||
                  selectedServiceForDetails?.location_key?.includes(
                    "hardedge"
                  ) ||
                  selectedServiceForDetails?.location_key?.includes(
                    "soft-edge"
                  ) ||
                  selectedServiceForDetails?.location_key?.includes("softedge")
                    ? areaUnit || "ft"
                    : areaUnit || "sqft"}{" "}
                  out of
                </Typography>{" "}
                <Typography
                  component="span"
                  fontWeight="600"
                  sx={{ color: "primary.dark" }}
                  variant="body1"
                >
                  {selectedServiceForDetails?.location_key?.includes(
                    "hard-edge"
                  ) ||
                  selectedServiceForDetails?.location_key?.includes(
                    "hardedge"
                  ) ||
                  selectedServiceForDetails?.location_key?.includes(
                    "soft-edge"
                  ) ||
                  selectedServiceForDetails?.location_key?.includes("softedge")
                    ? convertAreaUnitToStoredUnit(
                        selectedServiceForDetails?.location_distance,
                        "m",
                        areaUnit || "ft"
                      )
                    : convertAreaUnitToStoredUnit(
                        selectedServiceForDetails?.location_area,
                        "sqm",
                        areaUnit || "sqft"
                      )}{" "}
                </Typography>
                <Typography
                  component="span"
                  sx={{ color: "primary.dark" }}
                  variant="subtitle2"
                >
                  {selectedServiceForDetails?.location_key?.includes(
                    "hard-edge"
                  ) ||
                  selectedServiceForDetails?.location_key?.includes(
                    "hardedge"
                  ) ||
                  selectedServiceForDetails?.location_key?.includes(
                    "soft-edge"
                  ) ||
                  selectedServiceForDetails?.location_key?.includes("softedge")
                    ? areaUnit || "ft"
                    : areaUnit || "sqft"}
                </Typography>
              </div>
              <div>
                <Typography
                  component="span"
                  fontSize="10px"
                  sx={{ color: "primary.dark" }}
                  variant="subtitle2"
                >
                  Area covered
                </Typography>
              </div>
            </div>
            <div className="summaryCalculationContainerRight">
              <div>
                <Typography
                  component="span"
                  fontWeight="600"
                  sx={{ color: "primary.dark" }}
                  variant="body1"
                >
                  {time?.hours}
                </Typography>{" "}
                {time?.hours > 0 && (
                  <Typography
                    component="span"
                    fontSize="12px"
                    sx={{ color: "primary.dark" }}
                    variant="subtitle2"
                  >
                    hr
                  </Typography>
                )}{" "}
                <Typography
                  component="span"
                  fontWeight="600"
                  sx={{ color: "primary.dark" }}
                  variant="body1"
                >
                  {time?.minutes}
                </Typography>{" "}
                <Typography
                  component="span"
                  fontSize="12px"
                  sx={{ color: "primary.dark" }}
                  variant="subtitle2"
                >
                  min
                </Typography>
              </div>
              <div>
                <Typography
                  component="span"
                  fontSize="10px"
                  sx={{ color: "primary.dark" }}
                  variant="subtitle2"
                >
                  Total Operated time
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </Box>
      <Box className="operationHistoryContainer">
        <Typography
          component="div"
          fontWeight="600"
          fontSize="16px"
          sx={{ color: blackShade1.main }}
          variant="subtitle2"
        >
          Operation History
        </Typography>
        {isLoading ? (
          <Skeleton
            variant="rectangular"
            width="100%"
            animation="wave"
            height={170}
            sx={{ borderRadius: "4px" }}
          />
        ) : (
          <OperationTable operationTableData={operationTableData} />
        )}
      </Box>
    </>
  );
};

export default SidebarDetail;
