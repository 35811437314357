import React, { useEffect } from "react";
import MapScreen from "../MapScreen/MapScreen";
import { useDispatch, useSelector } from "react-redux";
import {
  setAnimationData,
  setInitialCrewMembers,
  setLandingPageMap,
  setLookUpData,
  setMapData,
  setSelectedCrewMembersId,
} from "../../slices/globalSlice";
import { toast } from "react-toastify";
import { RotateLoader } from "react-spinners";
import  {
  fetchS3Data,
} from "../../Modals/LocationGroupModal/LocationGroupModal";
import { useLocation } from "react-router-dom";
import {
  setSelectedLocation,
  setSelectedServiceType,
  setWorkerPlanFolderData,
} from "../../slices/workPlanSlice";
import { useGetWorkPlansMutation } from "../../services/Properties";
import {
  ShuffleJson,
  assignCrewMemberColors,
  assignCrewMemberColorsToAnimation,
  calculateServicesTotal,
  calculateTotalAssignmentsForCrewMember,
} from "../../utils/common";
import { useLazyGetFilesUrlQuery } from "../../services/OpenApi";

const MainScreen = () => {
  const dispatch = useDispatch();
  const locationQuery = useLocation();
  const user = useSelector((state) => state.global.user);
  const lookUpData = useSelector((state) => state.global.lookUpData);
  const initialCrewMembers = useSelector(
    (state) => state.global.initialCrewMembers
  );
  const [getWorkPlans] = useGetWorkPlansMutation();
  const [getFilesUrl] = useLazyGetFilesUrlQuery();

  const handleLocationQuery = async (locationQuery, user) => {
    if (locationQuery && user && Object.keys(user).length > 0) {
      const searchParams = new URLSearchParams(locationQuery.search);
      const orderId = searchParams.get("orderId");
  
      if (orderId) {
        const runPlannerId = searchParams.get("runPlannerId");
        const data = {
          orgName: user.organization,
          orderId,
          runPlannerId,
        };
  
        try {
          const workDataFolder = await getFilesUrl(data).unwrap();
  
          const mapData = await fetchS3Data(workDataFolder.GeoJsonUrl);
          ShuffleJson(mapData);
          dispatch(setMapData(mapData));
          dispatch(setLandingPageMap(mapData));
  
          const lookupData = await fetchS3Data(workDataFolder.LookupUrl);
          const fileData = lookupData.lookUpOutput || lookupData;
          const final = calculateTotalAssignmentsForCrewMember(fileData);
          const withServices = calculateServicesTotal(final);
          const crewColors = assignCrewMemberColors(withServices);
          dispatch(setLookUpData(crewColors));
          dispatch(setInitialCrewMembers(withServices.crewMemberDayWise));
        } catch (error) {
          toast.error(error?.error || "An error occurred");
        }
      } else {
        const location = searchParams.get("location");
        const workFolder = searchParams.get("workFolder");
        const data = {
          organization: user.organization,
          locationGroup: location,
        };
  
        try {
          const workDataFolder = await getWorkPlans(data).unwrap();
          dispatch(setWorkerPlanFolderData(workDataFolder.results));
  
          const currentFolder = workDataFolder.results.find(
            (item) => item.key === workFolder
          );
          const MapScreenGeoJson = currentFolder?.files.find(
            (item) => item.file_type === "site_plan_map"
          );
          const LookupFile = currentFolder?.files.find(
            (item) => item.file_type === "planner_output_lookup"
          );
          const animationFile = currentFolder?.files.find(
            (item) => item.file_type === "planner_animation"
          );
  
          if (animationFile) {
            const animationData = await fetchS3Data(animationFile.file_s3_url);
            const animatedData = assignCrewMemberColorsToAnimation(animationData);
            if (animatedData.hasOwnProperty("0")) {
              delete animatedData["0"];
            }
            dispatch(setAnimationData(animatedData));
          }
  
          dispatch(setSelectedLocation(location));
          dispatch(setSelectedServiceType(workFolder));
  
          const mapData = await fetchS3Data(MapScreenGeoJson.file_s3_url);
          ShuffleJson(mapData);
          dispatch(setMapData(mapData));
          dispatch(setLandingPageMap(mapData));
  
          const lookupData = await fetchS3Data(LookupFile.file_s3_url);
          const fileData = lookupData.lookUpOutput || lookupData;
          const final = calculateTotalAssignmentsForCrewMember(fileData);
          const withServices = calculateServicesTotal(final);
          const crewColors = assignCrewMemberColors(withServices);
          dispatch(setLookUpData(crewColors));
          dispatch(setInitialCrewMembers(withServices.crewMemberDayWise));
        } catch (error) {
          toast.error(error?.error || "An error occurred");
        }
      }
    }
  };
  

  useEffect(() => {
    handleLocationQuery(locationQuery, user);
  }, [locationQuery, user]);
  

  useEffect(() => {
    if (lookUpData) {
      dispatch(
        setSelectedCrewMembersId(
          Object.keys(lookUpData?.crewMemberDayWise).reduce(
            (acc, crewMemberId) => {
              acc[crewMemberId] = false;
              return acc;
            },
            {}
          )
        )
      );
    }
  }, [lookUpData]);
  useEffect(() => {
    if (lookUpData?.crewMemberDayWise) {
      dispatch(setInitialCrewMembers(lookUpData.crewMemberDayWise));
    }
  }, [lookUpData]);

  return (
    <>
      {initialCrewMembers ? (
      <MapScreen initialCrewMembers={initialCrewMembers} />) : (
      <div className="spinner">
        <RotateLoader color="#8FE445" size={20} margin={5} />
      </div>
      )}
    </>
  );
};

export default MainScreen;
