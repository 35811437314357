import { Button, CircularProgress, IconButton } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  handleNextStep,
  setParcelGeoJSON,
} from "../../../slices/stepperFormSlice";
import { useCreateParcelMutation } from "../../../services/OpenApi";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { MdDelete } from "react-icons/md";
import { setParcelData } from "../../../slices/drawingSlice";
import ConfirmationModal from "../../../Modals/ConfirmationModal/ConfirmationModal";

const CreateParcelForm = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const {
    drawing: { parcelData },
  } = useSelector((state) => state);
  const user = useSelector((state) => state.global.user);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [currentSelectedId, setCurrentSelectedId] = useState(null);
  const [createParcel, { isLoading }] = useCreateParcelMutation();

  const onSubmit = async () => {
    let geojsonStructure = {
      type: "FeatureCollection",
      features: [],
    };
    parcelData.map((shape) => {
      return geojsonStructure.features.push(shape.geojson);
    });
    const totalArea = parcelData.reduce((acc, curr) => acc + curr.geojson.properties.area, 0);
    if(totalArea/4047 > 100){
      toast.error("Total Measured area should be less than 100 acres", {
        autoClose: 4000,
        hideProgressBar: true,
      });
      return
    }
    const geoJson = geojsonStructure;
    dispatch(setParcelGeoJSON(geoJson));
    try {
      const response = await createParcel({
        orderId: params?.id,
        orgName: user?.organization,
        content: JSON.stringify(geoJson),
      });
      if (response?.data) {
        toast.success("Parcel created successfully", {
          autoClose: 1000,
          hideProgressBar: true,
        });
        dispatch(handleNextStep());
      } else if (response?.error) {
        toast.error(response?.error?.error || response?.error || response?.error?.data?.message, {
          autoClose: 2000,
          hideProgressBar: true,
        });
      }
    } catch (err) {
      console.log("error => ", err);
    }
  };

  const handleDeleteParcel = (index) => {
    setCurrentSelectedId(index);
    setIsConfirmationModalOpen(true);
  };

  const handleOk = () => {
    dispatch(
      setParcelData(parcelData.filter((shape, i) => i !== currentSelectedId))
    );
    setCurrentSelectedId(null);
    setIsConfirmationModalOpen(false);
  };

  return (
    <div className="StepsFormContainer">
      <h3 className="StepsFormContainerHeading">Create Parcel</h3>
      {parcelData?.map((shape, index) => {
        return (
          <div key={index} className="polygonContainer">
            <div>Area {index + 1}</div>
            <div className="deleteContainer">
              {(shape?.geojson?.properties?.area * 10.76391042).toFixed(2)} ft² {`(${((shape?.geojson?.properties?.area)/4047).toFixed(2)} acres)`}
              <IconButton
                onClick={() => handleDeleteParcel(index)}
                size="small"
                sx={{ ml: 2 }}
                aria-haspopup="true"
              >
                <MdDelete color="grey" size={18} />
              </IconButton>
            </div>
          </div>
        );
      })}
      <Button
        type="submit"
        fullWidth
        disabled={parcelData.length === 0 || isLoading}
        variant="contained"
        onClick={onSubmit}
        sx={{ mt: 3, mb: 2 }}
      >
        {isLoading ? <CircularProgress size={24} /> : "Confirm"}
      </Button>
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        setIsOpen={setIsConfirmationModalOpen}
        body="Do you want to delete this parcel?"
        handleOk={handleOk}
      />
    </div>
  );
};

export default CreateParcelForm;
