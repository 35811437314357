import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GeoJsonLayer } from "@deck.gl/layers";
import { DeckGlOverlay } from "../../../utils/deckgl-overlay";
import { getServiceColorRgb } from "../../../utils/common";

function getDeckGlLayers(layerData,isSummary) {
  if (!layerData) return [];

  return [
    new GeoJsonLayer({
      id: "geojson",
      data: layerData,
      stroked: true,
      filled: true,
      getLineWidth: 0.2,
      opacity: 1,
      visible: isSummary ? false : true,
      getFillColor: (f) => {
        return getServiceColorRgb(f?.properties?.serviceName);
      },
      getLineColor: (f) => getServiceColorRgb(f?.properties?.serviceName),
      lineWidthMinPixels: 2,
    }),
  ];
}

const CoveredServiceLayersDayWise = ({ layerData, isSummary }) => {
  const [isVisible, setIsVisible] = useState(true);

   // this visible functionality is added due to webgl context lost issue
   useEffect(() => {
    const handleVisibilityChange = () => {
      setIsVisible(!document.hidden);
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);
  
  return <DeckGlOverlay layers={getDeckGlLayers(layerData, isSummary)} pickable={true} isVisible={isVisible}/>;
};

export default CoveredServiceLayersDayWise;
