import React, { useEffect, useRef, useState } from "react";
import Timeline from "react-visjs-timeline";
import "vis/dist/vis.min.css";
import {
  convertStartAndEndTimes, ganttChartDateTimeFormat,
} from "../../../utils/common";

const BeaconsGanttChart = ({ equipmentData, devicesData, deviceId }) => {
  const timelineRef = useRef();
  const [groupData, setGroupData] = useState([]);
  const [itemsData, setItemsData] = useState([]);
  const [options, setOptions] = useState({
    stack: false,
    zoomMin: 1000, // Adjust as necessary
    zoomMax: 100000000000,
    orientation: { axis: "top", item: "top" },
    groupOrder: "content",
    showTooltips: true,
    preferZoom: true,
    verticalScroll: true,
    groupHeightMode: "fitItems",
    groupOrder: function (a, b) {
      return b.value - a.value;
    },
  });

  const transformDataForTimeline = (devicesData) => {
    const timelineData = [];

    devicesData?.features?.forEach((feature) => {
      const { beacon_id, beacon_name, start_time, end_time } =
        feature?.properties || {};
      timelineData.push({
        id: `${beacon_id}-${deviceId}-${beacon_name}-${feature?.id}`,
        start: ganttChartDateTimeFormat(start_time),
        end: ganttChartDateTimeFormat(end_time),
        group: `${deviceId}-${beacon_name}`,
        title: `<div>
          <div class="individualContainer">
          <div>Device Name : ${deviceId}</div>
          <div>Equipment Name : ${beacon_name.replace("_", " ")}</div>
          <div>Start Time : ${ganttChartDateTimeFormat(start_time)}</div>
          <div>End Time : ${ganttChartDateTimeFormat(
            end_time
          )}</div>
          </div>
          </div>`,
          className: `noColorItem`, 
      });
    });

    return timelineData;
  };

  const findMinMaxTimeFromLookup = (devicesData) => {
    let minStartTime = null;
    let maxEndTime = null;

    devicesData?.features?.forEach((feature) => {
      const { start_time, end_time } = feature?.properties || {};
      const startDate = ganttChartDateTimeFormat(start_time);
      const endDate = ganttChartDateTimeFormat(end_time);

      if (!minStartTime || startDate < minStartTime) {
        minStartTime = startDate;
      }
      if (!maxEndTime || endDate > maxEndTime) {
        maxEndTime = endDate;
      }
    });

    return { minStartTime, maxEndTime };
  };
  const transformGroupDataForTimeline = (equipmentData) => {
    const groupData = [];

    if (equipmentData) {
      equipmentData?.forEach((equipment) => {
        groupData.push({
          id: `${deviceId}-${equipment?.beacon_name}`,
          content: `${equipment?.beacon_name}`,
        });
      });
    }

    return groupData;
  };

  useEffect(() => {
    if (equipmentData && devicesData) {
      const timelineData = transformDataForTimeline(devicesData);
      setItemsData(timelineData);
      const groupData = transformGroupDataForTimeline(equipmentData);
      setGroupData(groupData);
      const bounds = findMinMaxTimeFromLookup(devicesData);
      const chartStart = new Date(bounds.minStartTime);
      const chartEnd = new Date(bounds.maxEndTime);
      const adjustedTimes = convertStartAndEndTimes(chartStart, chartEnd);
      const clusterOpts = {
        start: adjustedTimes.start,
        end: adjustedTimes.end,
        min: adjustedTimes.start + 1,
        max: adjustedTimes.end + 1,
      };
      setOptions((prevOptions) => ({
        ...prevOptions,
        ...clusterOpts,
      }));
    }
  }, [equipmentData, devicesData]);

  return (
    <div>
      {groupData && (
        <Timeline
          ref={timelineRef}
          options={options}
          groups={groupData}
          items={itemsData}
        />
      )}
    </div>
  );
};

export default BeaconsGanttChart;
