import {
  Box,
  Checkbox,
  Drawer,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IoClose } from "react-icons/io5";
import { getCustomColor } from "../../../utils/theme";
import { crewMemberColors, getDateFromCalender } from "../../../utils/common";
import {
  setIsIdleLayers,
  setIsMissingLayers,
  setIsTransitLayers,
} from "../../../slices/gisDashboardSlice";
import { LayerColors } from "../../../utils/defaultVars";
import { Mixpanel } from "../../../utils/mixPanel";

const BeaconLayersControlDrawer = ({
  isLayerControlDrawerOpen,
  handleLayerControlsClick,
}) => {
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("user"));
  const blackShade1 = getCustomColor("blackShade1");
  const isIdleLayers = useSelector((state) => state.gisDashboard.isIdleLayers);
  const isMissingLayers = useSelector(
    (state) => state.gisDashboard.isMissingLayers
  );
  const devicesDashboardLocationValue = useSelector(
    (state) => state.gisDashboard.devicesDashboardLocationValue
  );
  const selectedDateForDevices = useSelector(
    (state) => state.gisDashboard.selectedDateForDevices
  );
  const isTransitLayers = useSelector(
    (state) => state.gisDashboard.isTransitLayers
  );
  const isBeaconBasedColor = useSelector(
    (state) => state.gisDashboard.isBeaconBasedColor
  );

  return (
    <Drawer
      anchor="top"
      open={isLayerControlDrawerOpen}
      hideBackdrop
      variant="persistent"
      sx={{
        "& .MuiDrawer-paper": {
          borderRadius: "10px 10px",
          width: 245,
          position: "fixed",
          bottom: 0,
          top: "10vh",
          right: 10,
          left: "auto",
          zIndex: "1",
          maxHeight: "26vh",
        },
      }}
    >
      <div className="mainContainer">
        <div className="RightControls">
          <div
            className="mapControlsTitleContainer"
            style={{ marginBottom: "5px" }}
          >
            <Typography component="div" fontWeight="600" variant="body2">
              Layer Controls
            </Typography>
            <IoClose
              cursor={"pointer"}
              size={24}
              onClick={handleLayerControlsClick}
            />
          </div>
          <Box>
            <Grid container spacing={1}>
              <div className="layerCheckboxContainer">
                <div className="layerCheckboxLeft">
                  <Checkbox
                    size="small"
                    checked={isIdleLayers}
                    style={{ marginTop: "-5px" }}
                    onChange={() => {
                      dispatch(setIsIdleLayers(!isIdleLayers));
                      Mixpanel.identify(user.email);
                      Mixpanel.track(
                        `${isIdleLayers ? "Toggle Off" : "Toggle On"} Idle Layers - ${
                          devicesDashboardLocationValue?.name
                        } (${getDateFromCalender(selectedDateForDevices)})`
                      );
                    }}
                  />
                  <Typography
                    style={{ textAlign: "left" }}
                    component="h3"
                    fontWeight="600"
                    variant="body2"
                  >
                    Idle / Less Confidence Production Layers
                  </Typography>
                </div>
                <div>
                  {isBeaconBasedColor && (
                    <Box
                      sx={{
                        width: "15px",
                        height: "15px",
                        background: LayerColors?.idle?.hex,
                        borderRadius: "50%",
                        marginLeft: "10px",
                      }}
                    />
                  )}
                </div>
              </div>

              <div className="layerCheckboxContainer">
                <div className="layerCheckboxLeft">
                  <Checkbox
                    size="small"
                    checked={isTransitLayers}
                    onChange={() => {
                      dispatch(setIsTransitLayers(!isTransitLayers));
                      Mixpanel.identify(user.email);
                      Mixpanel.track(
                        `${isTransitLayers ? "Toggle Off" : "Toggle On"} Transit Layers - ${
                          devicesDashboardLocationValue?.name
                        } (${getDateFromCalender(selectedDateForDevices)})`
                      );
                    }}
                  />
                  <Typography component="h3" fontWeight="600" variant="body2">
                    Transit Layers
                  </Typography>
                </div>
                <div>
                  {isBeaconBasedColor && (
                    <Box
                      sx={{
                        width: "15px",
                        height: "15px",
                        background: LayerColors?.transit?.hex,
                        borderRadius: "50%",
                        marginLeft: "10px",
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="layerCheckboxContainer">
                <div className="layerCheckboxLeft">
                  <Checkbox
                    size="small"
                    checked={isMissingLayers}
                    onChange={() =>
                      dispatch(setIsMissingLayers(!isMissingLayers))
                    }
                  />
                  <Typography component="h3" fontWeight="600" variant="body2">
                    Missing Layers
                  </Typography>
                </div>
                <div>
                  {isBeaconBasedColor && (
                    <Box
                      sx={{
                        width: "15px",
                        height: "15px",
                        background: LayerColors?.missing?.hex,
                        borderRadius: "50%",
                        marginLeft: "10px",
                      }}
                    />
                  )}
                </div>
              </div>
            </Grid>
          </Box>
        </div>
      </div>
    </Drawer>
  );
};

export default BeaconLayersControlDrawer;
