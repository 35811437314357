import React, { useEffect, useState } from "react";
import { ControlPosition, Map, MapControl } from "@vis.gl/react-google-maps";
import ParkingsCoordinatesMarker from "../GoogleMapsDraw/ParkingsCoordinatesMarker";
import { useDrawingManager } from "../GoogleMapsDraw/useDrawingManager";
import { UndoRedoControl } from "../GoogleMapsDraw/UndoRedoControl";
import { useSelector } from "react-redux";

const getBoundingBox = (geojson) => {
    let minLat = Infinity, minLng = Infinity, maxLat = -Infinity, maxLng = -Infinity;
  
    geojson.features.forEach((feature) => {
      feature.geometry.coordinates[0].forEach(([lng, lat]) => {
        if (lat < minLat) minLat = lat;
        if (lat > maxLat) maxLat = lat;
        if (lng < minLng) minLng = lng;
        if (lng > maxLng) maxLng = lng;
      });
    });
  
    return {
      south: minLat,
      west: minLng,
      north: maxLat,
      east: maxLng,
    };
  };

const SummaryMap = ({ parcelData, parkingData }) => {
  const drawingManager = useDrawingManager({
    isVisible: false,
  });
  const state = useSelector((state) => state.drawing);

  const [viewport, setViewport] = useState({
    center: { lat: 40.7, lng: -74 },
    zoom: 12,
    width: 800,
    height: 400,
    bearing: 0,
    mapTypeId: "satellite", // Change this value for different map views
    mapTypeControl: false,
    zoomControl: true,
    zoomControlOptions: {
      position: ControlPosition?.LEFT_BOTTOM,
    },
    scaleControl: true,
    streetViewControl: true,
    streetViewControlOptions: {
      position: ControlPosition?.LEFT_BOTTOM,
    },
    fullscreenControl: false,
  });

  useEffect(() => {
    if (parcelData && parcelData.features && parcelData.features.length > 0) {
      const bounds = getBoundingBox(parcelData);
      setViewport((prev) => ({
        ...prev,
        center: {
          lat: (bounds.north + bounds.south) / 2,
          lng: (bounds.east + bounds.west) / 2,
        },
        zoom: 17, // Let the map fit to bounds
      }));
    }
  }, [parcelData]);
  return (
    <Map
      gestureHandling={"greedy"}
      {...viewport}
      zoom={viewport?.zoom}
      onCameraChanged={(v) => setViewport(v)}
      center={viewport?.center}
      mapId="satellite"
      defaultBounds={{
        south: 40.5,
        west: -74.2,
        north: 40.9,
        east: -73.8,
      }}
    >
      {parkingData?.length > 0 && (
        <ParkingsCoordinatesMarker parkings={parkingData} />
      )}
        <UndoRedoControl drawingManager={drawingManager} readOnly={true} currentState={state} parcelGeoJSON={parcelData}/>
    </Map>
  );
};

export default SummaryMap;
