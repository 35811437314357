// Type definitions converted to JSDoc comments

/**
 * @typedef {google.maps.Marker|google.maps.Polygon|google.maps.Polyline|google.maps.Rectangle|google.maps.Circle} OverlayGeometry
 */

/**
 * @typedef {Object} DrawResult
 * @property {google.maps.drawing.OverlayType} type
 * @property {OverlayGeometry} overlay
 */

/**
 * @typedef {Object} Snapshot
 * @property {number} [radius]
 * @property {google.maps.LatLngLiteral} [center]
 * @property {google.maps.LatLngLiteral} [position]
 * @property {Array<google.maps.LatLng>} [path]
 * @property {google.maps.LatLngBoundsLiteral} [bounds]
 */

/**
 * @typedef {Object} Overlay
 * @property {google.maps.drawing.OverlayType} type
 * @property {OverlayGeometry} geometry
 * @property {Snapshot} snapshot
 */

/**
 * @typedef {Object} State
 * @property {Array<Array<Overlay>>} past
 * @property {Array<Overlay>} now
 * @property {Array<Array<Overlay>>} future
 */

/**
 * @enum {string}
 */
export const DrawingActionKind = {
  SET_OVERLAY: 'SET_OVERLAY',
  UPDATE_OVERLAYS: 'UPDATE_OVERLAYS',
  UNDO: 'UNDO',
  REDO: 'REDO'
};

/**
 * @typedef {Object} ActionWithTypeOnly
 * @property {Exclude<DrawingActionKind, DrawingActionKind.SET_OVERLAY>} type
 */

/**
 * @typedef {Object} SetOverlayAction
 * @property {DrawingActionKind.SET_OVERLAY} type
 * @property {DrawResult} payload
 */

/**
 * @typedef {ActionWithTypeOnly|SetOverlayAction} Action
 */

/**
 * @param {OverlayGeometry} overlay
 * @returns {overlay is google.maps.Circle}
 */
export function isCircle(overlay) {
  return typeof overlay.getCenter !== 'undefined';
}

/**
 * @param {OverlayGeometry} overlay
 * @returns {overlay is google.maps.Marker}
 */
export function isMarker(overlay) {
  return typeof overlay.getPosition !== 'undefined';
}

/**
 * @param {OverlayGeometry} overlay
 * @returns {overlay is google.maps.Polygon}
 */
export function isPolygon(overlay) {
  return typeof overlay.getPath !== 'undefined';
}

/**
 * @param {OverlayGeometry} overlay
 * @returns {overlay is google.maps.Polyline}
 */
export function isPolyline(overlay) {
  return typeof overlay.getPath !== 'undefined';
}

/**
 * @param {OverlayGeometry} overlay
 * @returns {overlay is google.maps.Rectangle}
 */
export function isRectangle(overlay) {
  return typeof overlay.getBounds !== 'undefined';
}
