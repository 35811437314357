import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Divider,
  List,
  ListItem,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { MdExpandLess } from "react-icons/md";
import { StyledTableCell } from "./PlannerDetailsDrawer";
import { getCustomColor } from "../../utils/theme";
import { setSkills } from "../../utils/common";

const PlannerInputData = () => {
  const blackShade1 = getCustomColor("blackShade1");
  const [transformedData, setTransformedData] = useState();
  const lookUpData = useSelector((state) => state.global.lookUpData);

  function transformData(data) {
    const crews = {};
    if (!data) return;
    // Iterate through crew members
    Object.entries(data?.crews).forEach(([crewId, member]) => {
      if (crewId === 0) return;

      // Initialize crew object if not exists
      if (!crews[crewId]) {
        crews[crewId] = {
          crewMembers: [],
          equipments: {},
          name: member?.name,
        };
      }
    });
    Object.values(data?.crewMembers).forEach((member) => {
      const { crewId, name, title, skills } = member;
      if (crewId === 0) return;

      // Initialize crew object if not exists
      if (!crews[crewId]) {
        crews[crewId] = {
          crewMembers: [],
          equipments: {},
        };
      }

      // Add crew member
      crews[crewId]?.crewMembers?.push({ id: crewId, name, title, skills });
    });

    Object.values(data?.equipments).forEach((equipment) => {
      const { crewId, type } = equipment;
      if (crewId === 0) return;

      // Initialize equipment type count if not exists
      if (!crews[crewId]?.equipments[type]) {
        crews[crewId].equipments[type] = 0;
      }

      // Increase equipment count
      crews[crewId].equipments[type]++;
    });

    return { crews };
  }

  useEffect(() => {
    if (lookUpData) {
      const result = transformData(lookUpData);
      setTransformedData(result?.crews);
    }
  }, [lookUpData]);
  return (
    transformedData &&
    Object.keys(transformedData)?.map((crewId, idx) => {
      return (
        <Accordion key={crewId * idx}>
          <AccordionSummary expandIcon={<MdExpandLess />}>
            <Typography
              variant="subtitle1"
              sx={{ color: "#2e7d32", fontWeight: "bold" }}
            >
              {transformedData[crewId]?.name} ({" "}
              {transformedData[crewId]?.crewMembers?.length} Members )
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <List sx={{ width: "100%" }}>
                {transformedData[crewId]?.crewMembers?.map((member, idx) => (
                  <ListItem
                    key={idx}
                    sx={{
                      borderBottom: "1px solid #eeeeee",
                      padding: "8px",
                      "&:last-child": { border: 0 },
                    }}
                  >
                    <Box>
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        {member?.title}: {member?.name}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          paddingTop: "8px",
                        }}
                      >
                        {Object.entries(member?.skills).map(
                          ([skill, level], idx) => (
                            <Chip
                              key={idx}
                              label={`${skill} (${setSkills(level)})`}
                              variant="outlined"
                              size="small"
                              sx={{ marginRight: 1, marginBottom: 1 }}
                            />
                          )
                        )}
                      </Box>
                    </Box>
                  </ListItem>
                ))}
              </List>
              <Divider sx={{ my: 2 }} />
              <Typography
                  component="span"
                  fontWeight="600"
                  marginLeft={1}
                  sx={{ color: blackShade1.main }}
                  variant="body1"
                >
                  Available Equipments
                </Typography>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Name</StyledTableCell>
                      <StyledTableCell align="right">Quantity</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.entries(transformedData[crewId]?.equipments).map(
                      ([type, count], idx) => {
                        return (
                          <TableRow key={`${crewId}-${type}-${idx}`}>
                            <StyledTableCell>{type}</StyledTableCell>
                            <StyledTableCell align="right">
                              {" "}
                              {count}{" "}
                            </StyledTableCell>
                          </TableRow>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </AccordionDetails>
        </Accordion>
      );
    })
  );
};

export default PlannerInputData;
