import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import { toast } from "react-toastify";

const token =
  "pk.eyJ1IjoiYXR1bGt1bXIiLCJhIjoiY2xyYWNzN2JoMDhyazJsbnh3MWR1aWU2MCJ9.BDBKCFichBcahbwbDOxmjA";
mapboxgl.accessToken = token;

const ParkingModal = ({
  isOpen,
  setIsOpen,
  parkingAddressVal,
  parkingCoordinates,
  submitFunction,
  editData,
}) => {
  const [parkingName, setParkingName] = useState("");
  const [parkingType, setParkingType] = useState("Parking Lot");
  const [parkingAddress, setParkingAddress] = useState();
  const handleParkingType = (e) => {
    setParkingType(e.target.value);
  };
  const handleParkingAddress = (e) => {
    setParkingAddress(e.target.value);
  };
  const handleAddParking = () => {
    handleCancel();
    setParkingName("");
    setParkingType();
    setParkingAddress();
    if(editData){
      submitFunction(parkingName, parkingType, parkingAddress, editData.points, editData.id);
    } else {
      submitFunction(parkingName, parkingType, parkingAddress, parkingCoordinates);

    }
  };
  const handleCancel = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (editData) {
      setParkingName(editData?.parkingName);
      setParkingType(editData?.parkingType);
      setParkingAddress(editData?.address);
    }
  }, [editData]);
  useEffect(()=>{
    if(!editData){
      setParkingAddress(parkingAddressVal)
    }
  },[parkingAddressVal])

  return (
    <Modal open={isOpen}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <form>
          <div className="nameSkillsContainer">
            <div>
              <div>Parking Name</div>
              <TextField
                margin="normal"
                required
                className="crewName"
                placeholder="Enter parking name"
                name="parkingName"
                size="small"
                fullWidth
                value={parkingName}
                onChange={(e) => setParkingName(e.target.value)}
                autoFocus
              />
              <div>Parking Type</div>
              <Select
                value={parkingType}
                fullWidth
                className="crewName"
                size="small"
                onChange={handleParkingType}
              >
                <MenuItem value="Parking Lot">Parking Lot</MenuItem>
                <MenuItem value="Roadside">Roadside</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </Select>
              <div style={{ marginTop: "10px" }}>Parking Address</div>
              <TextField
                margin="normal"
                required
                fullWidth
                multiline
                rows={3}
                type="textArea"
                className="crewName"
                name="parkingAddress"
                value={parkingAddress}
                onChange={handleParkingAddress}
                size="small"
                autoFocus
              />
            </div>
          </div>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              justifyContent: "flex-end",
              mt: 2,
            }}
          >
            <Button variant="outlined" onClick={handleCancel}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleAddParking}>
              {editData ? "Edit Parking" : "Add Parking"}
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default ParkingModal;
