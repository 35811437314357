import { AdvancedMarker, Pin } from "@vis.gl/react-google-maps";
import React from "react";

const LocationCoordinateMarker = ({ coordinate }) => {
  return (
    <div style={{ zIndex: 2 }}>
      <AdvancedMarker
        position={coordinate}
        zIndex={2}
      >
        <Pin></Pin> 
      </AdvancedMarker>
    </div>
  );
};

export default LocationCoordinateMarker;
