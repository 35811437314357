import { AdvancedMarker } from "@vis.gl/react-google-maps";
import React from "react";
import Pin from "../../components/Mapbox/Pin";

const ParkingsCoordinatesMarker = ({ parkings }) => {
  return parkings.map((parkingdata, index) => {
    return (
      <div style={{ zIndex: 2 }} key={index}>
        <AdvancedMarker
          position={{
            lat: parkingdata?.points?.lat,
            lng: parkingdata?.points?.lon,
          }}
          zIndex={2}
        >
          <Pin text="P" color="#ffa600"></Pin>
        </AdvancedMarker>
      </div>
    );
  });
};

export default ParkingsCoordinatesMarker;
