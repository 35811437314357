import { AdvancedMarker } from "@vis.gl/react-google-maps";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { DeckGlOverlay } from "../../../utils/deckgl-overlay";
import ArrowHead from "../../../components/Mapbox/ArrowHead";
import { GeoJsonLayer, IconLayer } from "@deck.gl/layers";
import { hexToRgb } from "../../../utils/common";

function svgToDataURL(color, angle) {
  const rotate = `rotate(${82 - angle})`;
  return `data:image/svg+xml;charset=utf-8,${encodeURIComponent(
    `<svg
      fill="${color}"
      height="20px"
      width="20px"
      viewBox="0 0 20 20"
      transform="${rotate}"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g data-name="Layer 2">
        <g data-name="navigation">
          <rect width="20" height="20" opacity="0" />
          <path d="M20 20a.94.94 0 0 1-.55-.17l-6.9-4.56a1 1 0 0 0-1.1 0l-6.9 4.56a1 1 0 0 1-1.44-1.28l8-16a1 1 0 0 1 1.78 0l8 16a1 1 0 0 1-.23 1.2A1 1 0 0 1 20 20z" />
        </g>
      </g>
    </svg>`
  )}`;
}

function getDeckGlLayers(data, zoom) {
  if (!data) return [];
  return [
    new IconLayer({
      id: "arrowLayer",
      data: data,
      getColor: (d) => hexToRgb(d.color),
      getIcon: (d) => ({
        url: svgToDataURL(d.color, d.angle),
        width: 20, // Match this with the SVG width
        height: 20, // Match this with the SVG height
      }),
      getPosition: (d) => d.coordinates,
      pickable: false,
      // getAngle: (d) => d.angle,
      getSize: zoom >= 18 ? 20 : 0, // Show icons only when zoom is 14 or higher
      visible: zoom >= 18, // Make layer visible only at higher zoom levels
      updateTriggers: {
        getSize: zoom,
        visible: zoom,
      },
    }),
  ];
}

const CrewMemberWiseDirectionArrows = ({
  filteredGeoJson,
  crewMemberDayWise,
  viewport,
  lookUpData,
  selectedServiceIds,
  getEntryPointForPolygonId,
}) => {
  const mapData = useSelector((state) => state.global.mapData);

  const lookupGeoJSONByPathId = (geoJSON, targetPathId) => {
    if (geoJSON && geoJSON?.features) {
      for (const feature of geoJSON?.features) {
        if (
          feature?.properties?.pathId &&
          feature?.properties?.pathId.toString() === targetPathId?.toString()
        ) {
          const {
            geometry,
            geometry: { type },
            properties,
          } = feature;
          return { geometry, type, properties };
        }
      }
    }
    return null;
  };
  const extractEquallySpacedSubarray = (arr, m) => {
    const n = arr?.length;

    // Check if m is valid
    if (m <= 0 || m >= n) {
      return [];
    }
    // Calculate the step size
    let stepSize = Math.floor(n / (m + 1));
    // Initialize the result array
    const result = [];

    // Extract equally spaced indices
    for (let i = 1; i <= m; i++) {
      const index = Math.floor(i * stepSize);
      result.push(arr[index]);
    }

    return result;
  };

  const calculateArrowPositions = (geometry, polygonId) => {
    const arrowPositions = [];
    const lineCoordinates = geometry?.coordinates;

    const minDistanceThreshold = 0.0000095; // Adjust this threshold based on your requirements
    for (let i = 1; i < lineCoordinates?.length; i++) {
      const [lon1, lat1] = lineCoordinates[i - 1];
      const [lon2, lat2] = lineCoordinates[i];

      // Calculate distance between consecutive coordinates
      const distance = Math.sqrt((lon2 - lon1) ** 2 + (lat2 - lat1) ** 2);
      // Place arrow only if distance exceeds the threshold
      if (distance > minDistanceThreshold) {
        // Calculate mid-point between consecutive coordinates
        const midPoint = [(lon1 + lon2) / 2.0, (lat1 + lat2) / 2.0];

        // Calculate angle of the line segment
        const angle = Math.atan2(lat2 - lat1, lon2 - lon1) * (180 / Math.PI);
        arrowPositions.push({ position: midPoint, angle });
      }
    }

    // time to cleanup some unnecessary arrows
    if (
      lineCoordinates?.length >= 4 && // complex polygons
      arrowPositions?.length > 2 && // More than 2 arrows
      arrowPositions?.length / lineCoordinates?.length >= 0.18 && // if the ratio of arrows is too high?
      arrowPositions?.length / lineCoordinates?.length < 1.0 // if the ratio of arrows is too high?
    ) {
      const finalArray = extractEquallySpacedSubarray(
        arrowPositions,
        Math.ceil(arrowPositions.length / 10)
      );

      return finalArray;
    }
    return arrowPositions;
  };

  const [isVisible, setIsVisible] = useState(true);

  const [arrowPositionsState, setArrowPositionsState] = useState([]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsVisible(!document.hidden);
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useMemo(() => {
    if (crewMemberDayWise) {
      const newArrowPositions = [];

      Object.entries(crewMemberDayWise).forEach(
        ([crewMemberId, crewMember]) => {
          const { day, crewMemberColor } = crewMember;

          day.forEach((dayInfo) => {
            const filteredPolygonIds = dayInfo.polygon.filter((polygonId) => {
              const polygon = lookUpData.polygons[polygonId];
              return (
                polygon &&
                selectedServiceIds.includes(polygon?.serviceId?.toString())
              );
            });

            filteredPolygonIds.forEach((polygonId) => {
              const entryPoint = getEntryPointForPolygonId(polygonId);
              let gData = lookupGeoJSONByPathId(mapData, polygonId);
              let reverseGeometry = true;

              if (gData?.geometry?.coordinates) {
                if (
                  entryPoint.lon === gData?.geometry?.coordinates[0][0] &&
                  entryPoint.lat === gData?.geometry?.coordinates[0][1]
                ) {
                  reverseGeometry = false;
                }
                if (reverseGeometry) {
                  if (gData?.geometry?.coordinates?.length > 0) {
                    gData = {
                      ...gData,
                      geometry: {
                        ...gData.geometry,
                        coordinates: [
                          ...gData?.geometry?.coordinates,
                        ].reverse(),
                      },
                    };
                  }
                }
              }

              if (gData?.geometry?.geometries) {
                if (
                  entryPoint.lon ===
                    gData?.geometry?.geometries[0]?.coordinates[0][0] &&
                  entryPoint.lat ===
                    gData?.geometry?.geometries[0]?.coordinates[0][1]
                ) {
                  reverseGeometry = false;
                }
                if (reverseGeometry) {
                  if (gData?.geometry?.geometries[0]?.coordinates?.length > 0) {
                    const revisedGeometries = gData?.geometry?.geometries.map(
                      (geometry) => {
                        if (
                          geometry?.type === "LineString" &&
                          geometry?.coordinates?.length > 0
                        ) {
                          return {
                            ...geometry,
                            coordinates: geometry?.coordinates
                              ?.slice()
                              .reverse(),
                          };
                        }
                        return geometry;
                      }
                    );

                    gData = {
                      ...gData,
                      geometry: {
                        ...gData?.geometry,
                        geometries: revisedGeometries,
                      },
                    };
                  }
                }
              }

              let arrowPositions;
              if (gData?.geometry?.coordinates) {
                arrowPositions = calculateArrowPositions(
                  gData?.geometry,
                  polygonId
                );
              } else if (gData?.geometry?.geometries[0].type === "LineString") {
                arrowPositions = calculateArrowPositions(
                  gData?.geometry?.geometries[0],
                  polygonId
                );
              }

              if (arrowPositions) {
                newArrowPositions.push(
                  ...arrowPositions.map(({ position, angle }) => ({
                    coordinates: [position[0], position[1]],
                    angle,
                    color: crewMemberColor,
                    polygonId,
                  }))
                );
              }
            });
          });
        }
      );

      setArrowPositionsState(newArrowPositions);
    }
  }, [crewMemberDayWise, filteredGeoJson, viewport]);
  // const crewWiseMarkers = useMemo(
  //   () =>
  //     crewMemberDayWise &&
  //     Object.entries(crewMemberDayWise).map(([crewMemberId, crewMember]) => {
  //       const { day, crewMemberColor } = crewMember;
  //       return day.map((dayInfo) => {
  //         const filteredPolygonIds = dayInfo.polygon.filter((polygonId) => {
  //           const polygon = lookUpData.polygons[polygonId];
  //           return (
  //             polygon &&
  //             selectedServiceIds.includes(polygon?.serviceId?.toString())
  //           );
  //         });
  //         const color = crewMemberColor;
  //         return filteredPolygonIds.map((polygonId, i) => {
  //           const entryPoint = getEntryPointForPolygonId(polygonId);
  //           let gData = lookupGeoJSONByPathId(mapData, polygonId);
  //           let reverseGeometry = true;
  //           if (gData?.geometry?.coordinates) {
  //             if (
  //               entryPoint.lon === gData?.geometry?.coordinates[0][0] &&
  //               entryPoint.lat === gData?.geometry?.coordinates[0][1]
  //             ) {
  //               reverseGeometry = false;
  //             }
  //             if (reverseGeometry) {
  //               if (
  //                 gData &&
  //                 gData?.geometry &&
  //                 gData?.geometry?.coordinates &&
  //                 gData?.geometry?.coordinates?.length > 0
  //               ) {
  //                 gData = {
  //                   ...gData,
  //                   geometry: {
  //                     ...gData.geometry,
  //                     coordinates: [...gData?.geometry?.coordinates].reverse(),
  //                   },
  //                 };
  //               }
  //             }
  //           }
  //           if (gData?.geometry?.geometries) {
  //             if (
  //               entryPoint.lon ===
  //                 gData?.geometry?.geometries[0]?.coordinates[0][0] &&
  //               entryPoint.lat ===
  //                 gData?.geometry?.geometries[0]?.coordinates[0][1]
  //             ) {
  //               reverseGeometry = false;
  //             }
  //             if (reverseGeometry) {
  //               if (
  //                 gData &&
  //                 gData?.geometry &&
  //                 gData?.geometry?.geometries[0] &&
  //                 gData?.geometry?.geometries[0]?.coordinates?.length > 0
  //               ) {
  //                 const revisedGeometries = gData?.geometry?.geometries.map(
  //                   (geometry) => {
  //                     // Check if the geometry is a LineString
  //                     if (
  //                       geometry?.type === "LineString" &&
  //                       geometry?.coordinates &&
  //                       geometry?.coordinates?.length > 0
  //                     ) {
  //                       // Reverse the coordinates for LineString
  //                       return {
  //                         ...geometry,
  //                         coordinates: geometry?.coordinates?.slice().reverse(),
  //                       };
  //                     }
  //                     return geometry;
  //                   }
  //                 );

  //                 // Update the gData with the new geometries
  //                 gData = {
  //                   ...gData,
  //                   geometry: {
  //                     ...gData?.geometry,
  //                     geometries: revisedGeometries,
  //                   },
  //                 };
  //               }
  //             }
  //           }
  //           let arrowPositions;
  //           if (gData?.geometry?.coordinates) {
  //             arrowPositions = calculateArrowPositions(
  //               gData?.geometry,
  //               polygonId
  //             );
  //           } else if (gData?.geometry?.geometries[0].type === "LineString") {
  //             arrowPositions = calculateArrowPositions(
  //               gData?.geometry?.geometries[0],
  //               polygonId
  //             );
  //           }
  //           console.log("arrow positions ==>>> ",arrowPositions)
  //           return (
  //             <>
  //               {arrowPositions &&
  //                 arrowPositions.map(({ position, angle }, idx) => (
  //                   <div style={{ zIndex: 2 }} offset={[0, -7]} key={idx}>
  //                     <AdvancedMarker
  //                       key={`arrow-marker-${polygonId}-${idx}`}
  //                       // latitude={position[1]}
  //                       // longitude={position[0]}
  //                       position={{lat: position[1]- 0.000004, lng: position[0]}}

  //                     >
  //                       <ArrowHead
  //                         key={`arrow-head-${polygonId}-${idx}`}
  //                         color={color}
  //                         opacity={1}
  //                         angle={(angle - viewport.map.bearing).toString()}
  //                       />
  //                     </AdvancedMarker>
  //                   </div>
  //                 ))}
  //             </>
  //           );
  //         });
  //       });
  //     }),
  //   [crewMemberDayWise, filteredGeoJson, viewport]
  // );
  return (
    <>
      <DeckGlOverlay
        layers={getDeckGlLayers(arrowPositionsState, viewport?.detail?.zoom)}
        isVisible={isVisible}
      />
    </>
  );
};

export default CrewMemberWiseDirectionArrows;
