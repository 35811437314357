import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useDispatch, useSelector } from "react-redux";
import { MdEdit } from "react-icons/md";
import { IconButton } from "@mui/material";
import { setActiveStep } from "../../slices/stepperFormSlice";

export default function StepperForm() {
  const {
    stepperForm: { activeStep, stepsLabels },
  } = useSelector((state) => state);
  const dispatch = useDispatch()

  const handleStepEdit = (id) => {
    dispatch(setActiveStep(id))
  };
  return (
    <Box sx={{ width: "100%"}}>
      <Stepper activeStep={activeStep-1} orientation="horizontal">
        {stepsLabels.map((step, index) => (
          <Step key={step.id}>
            <StepLabel>
              {step.label}{" "}
              {index < activeStep - 1 && ( // Render edit button only for steps before active step and not for step 1
                <span>
                  <IconButton
                    color="primary"
                    onClick={() => handleStepEdit(step?.id)}
                  >
                    <MdEdit fontSize={14} />
                  </IconButton>
                </span>
              )}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}

