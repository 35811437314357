import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  Divider,
  TextField,
} from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import { useAddLocationGroupsMutation } from "../../services/Properties";
import { toast } from "react-toastify";

const AddLocationGroup = ({ isOpen, setIsOpen }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [addLocationGroups, { isLoading }] = useAddLocationGroupsMutation();

  const onSubmit = async (data) => {
    const body = {
      organisation: user?.organization,
      content: {
        name: data?.locationName,
        intel_enabled: true,
      },
    };
    const response = await addLocationGroups(body);
    if(response?.data){
        toast.success("Location Group Added Successfully", {
            autoClose: 2000,
            hideProgressBar: true,
          });
        handleCancel();
    }
  };
  const handleCancel = () => {
    setIsOpen(false);
    reset();
  };
  return (
    <Dialog open={isOpen} fullWidth maxWidth="xs">
      <DialogTitle fontWeight={700} fontSize="20px" align="left">
        Add Location
      </DialogTitle>
      <Divider />
      <div className="dialogContainer">
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            width: 400,
            mx: "auto",
            borderRadius: 2,
          }}
        >
          <TextField
            fullWidth
            name="locationName"
            id="locationName"
            placeholder="Location name"
            size="small"
            error={!!errors.locationName}
            helperText={errors.locationName && "Location name is required"}
            {...register("locationName", { required: true })}
          />
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              justifyContent: "flex-end",
              mt: 2,
            }}
          >
            <Button variant="outlined" onClick={handleCancel}>
              Cancel
            </Button>
            <Button disabled={isLoading} variant="contained" type="submit">
              {isLoading ? <CircularProgress size={24} /> : "Upload"}
            </Button>
          </Box>
        </Box>
      </div>
    </Dialog>
  );
};

export default AddLocationGroup;
