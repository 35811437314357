import React, { useState } from 'react'
import Pin from '../../../components/Mapbox/Pin';
import { AdvancedMarker, Marker } from '@vis.gl/react-google-maps';

const ParkingsMarkers = ({lookUpData}) => {
    const [selectedParking, setSelectedParking] = useState(null);
  return (
    <div>
         {/* for renderning parking markers */}
         {lookUpData &&
          lookUpData?.parkings &&
          Object.keys(lookUpData.parkings).map((parkingId, index) => {
            const parking = lookUpData.parkings[parkingId];
            return (
              <div style={{ zIndex: 2 }} key={parkingId}>
                <AdvancedMarker
                position={{lat: parking.point.lat, lng: parking.point.lon}}
                  zIndex={2}
                    
                //   onClick={(e) => {
                //     e.originalEvent.stopPropagation();
                //     setSelectedParking({
                //       ...parking,
                //       parkingId:parkingId
                //     });
                //   }}
                >
                  <Pin text="P" color="#ffa600"></Pin>
                </AdvancedMarker>
              </div>
            );
          })}
        {/* {selectedParking && (
          <Popup
            latitude={selectedParking?.point?.lat}
            longitude={selectedParking?.point?.lon}
            onClose={() => setSelectedParking(null)}
            offsetTop={-30}
          >
            <div>
              <h3>{selectedParking?.parkingType}</h3>
              <p>{selectedParking?.address}</p>
              <p>Parking Id : {selectedParking?.parkingId}</p>
              <p>Parking Type : {selectedParking?.parkingType}</p>
            </div>
          </Popup>
        )} */}
    </div>
  )
}

export default ParkingsMarkers