import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isSolving: false,
  isParkingMap: true,
  executePlanForFirstTime: true,
  drivePath: null,
  rePlanningFileName: null,
  avoidCrewMembersData: [],
  avoidCrewMembersDataForTable: [],
  preferCrewMembersData: [],
  preferCrewMembersDataForTable: [],
  pinnedPolygons: [],
  planner: {},
  crews: [],
  parkings: [],
  workingHour: {
    timeForWork: 480,
  },
  noOfDays: 1,
  skills: null,
  equipments: null,
  serviceData: null,
  addNewServices: [],
};

const createCrewSlice = createSlice({
  name: "createCrew",
  initialState,
  reducers: {
    resetCrewSlice: () => initialState,
    setIsSolving: (state, action) => {
      return {
        ...state,
        isSolving: action.payload,
      };
    },
    setIsParkingMap: (state, action) => {
      return {
        ...state,
        isParkingMap: action.payload,
      };
    },
    setExecutePlanForFirstTime: (state, action) => {
      return {
        ...state,
        executePlanForFirstTime: action.payload,
      };
    },
    setDrivePath: (state, action) => {
      return {
        ...state,
        drivePath: action.payload,
      };
    },
    setRePlanningFileName: (state, action) => {
      return {
        ...state,
        rePlanningFileName: action.payload,
      };
    },
    setAvoidCrewMembersData: (state, action) => {
      return {
        ...state,
        avoidCrewMembersData: action.payload,
      };
    },
    setAvoidCrewMembersDataForTable: (state, action) => {
      return {
        ...state,
        avoidCrewMembersDataForTable: action.payload,
      };
    },
    setPreferCrewMembersData: (state, action) => {
      return {
        ...state,
        preferCrewMembersData: action.payload,
      };
    },
    setPreferCrewMembersDataForTable: (state, action) => {
      return {
        ...state,
        preferCrewMembersDataForTable: action.payload,
      };
    },
    setPlanner: (state, action) => {
      return {
        ...state,
        planner: action.payload,
      };
    },
    setCrews: (state, action) => {
      return {
        ...state,
        crews: action.payload,
      };
    },
    setParkings: (state, action) => {
      return {
        ...state,
        parkings: action.payload,
      };
    },
    setWorkingHour: (state, action) => {
      return {
        ...state,
        workingHour: {
          timeForWork: action.payload,
        },
      };
    },
    setNoOfDays: (state, action) => {
      return {
        ...state,
        noOfDays: action.payload,
      };
    },
    setSkills: (state, action) => {
      return {
        ...state,
        skills: action.payload,
      };
    },
    setEquipments: (state, action) => {
      return {
        ...state,
        equipments: action.payload,
      };
    },
    setServiceData: (state, action) => {
      return {
        ...state,
        serviceData: action.payload,
      };
    },
    setAddNewService: (state, action) => {
      return {
        ...state,
        addNewServices: action.payload,
      };
    },
  },
});

export const {
  resetCrewSlice,
  setIsSolving,
  setIsParkingMap,
  setExecutePlanForFirstTime,
  setDrivePath,
  setRePlanningFileName,
  setAvoidCrewMembersData,
  setAvoidCrewMembersDataForTable,
  setPreferCrewMembersData,
  setPreferCrewMembersDataForTable,
  setPlanner,
  setCrews,
  setParkings,
  setWorkingHour,
  setSkills,
  setEquipments,
  setServiceData,
  setAddNewService,
} = createCrewSlice.actions;

export default createCrewSlice.reducer;
