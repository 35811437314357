import {
  Box,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { StyledTableCell } from "../../MapScreen/PlannerDetailsDrawer";
import { getCustomColor } from "../../../utils/theme";
import { formatTotalTime, parseTimeString } from "../../../utils/common";

const ProductionTimeTable = ({ timeSummary }) => {
  const blackShade1 = getCustomColor("blackShade1");

  const timefunctioncalculateTotalProductionTime = (timeSummary) => {
    let totalSeconds = 0;

    // Loop through each beacon type and add its time to the totalObject.keys(timeSummary).forEach(beaconType => {
    Object.keys(timeSummary).forEach((beaconType) => {
      const timeString = timeSummary[beaconType];
      totalSeconds += parseTimeString(timeString);
    });

    return formatTotalTime(totalSeconds);
  };
  return (
    <>
      <Typography
        fontWeight="600"
        sx={{ color: blackShade1.main, marginBottom: "5px"  }}
        variant="body1"
      >
        Service wise production
      </Typography>

      <TableContainer
        className="propertySummary"
        sx={{ height: 160 }}
        component={Paper}
      >
        <Table size="small" stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Equipment Type</StyledTableCell>
              <StyledTableCell align="center">Total Time</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {timeSummary &&
              Object.keys(timeSummary).map((beaconType, index) => {
                return (
                  <TableRow key={index}>
                    <StyledTableCell style={{ fontSize: "13px" }} align="center">
                      {beaconType}
                    </StyledTableCell>
                    <StyledTableCell
                      style={{ fontSize: "13px" }}
                      align="center"
                    >
                      {timeSummary[beaconType]}
                    </StyledTableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box marginTop="10px">
        <Typography
          sx={{ color: blackShade1.main }}
          variant="body1"
          fontWeight="600"
          component="span"
        >
          Total production time :{" "}
        </Typography>
        <Typography variant="body1" component="span">
          {timeSummary && timefunctioncalculateTotalProductionTime(timeSummary)}
        </Typography>
      </Box>
    </>
  );
};

export default ProductionTimeTable;
