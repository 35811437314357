import { Menu, Typography } from "@mui/material";
import {
  APIProvider,
  ControlPosition,
  Map,
  useMap,
  useMapsLibrary,
} from "@vis.gl/react-google-maps";
import React, { useEffect, useState } from "react";
import { FaCalendar } from "react-icons/fa";
import WeekCalender from "../../components/WeekCalender/WeekCalender";
import DayCalender from "../../components/DayCalender/DayCalender";
import { getDateForApi, getDateFromCalender } from "../../utils/common";
import { useLazyGetDeviceDetailsForOtherDaysQuery, useLazyGetDeviceDetailsForTodayQuery, useLazyGetStreamDataForDeviceQuery } from "../../services/Properties";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { fetchS3Data } from "../../Modals/LocationGroupModal/LocationGroupModal";

const DeviceDetails = () => {
  //   const map = useMap();
  const mapsLibrary = useMapsLibrary("maps");
  const user = JSON.parse(localStorage.getItem("user"));
  const [anchorEl, setAnchorEl] = useState(null);
  const params = useParams();
  const open = Boolean(anchorEl);
  const [selectedDate, setSelectedDate] = useState();
  const [getDeviceDetailsForToday, { isLoading }] =
    useLazyGetDeviceDetailsForTodayQuery();
  const [getDeviceDetailsForOtherDays, { isLoading: isOtherDayLoading }] =
  useLazyGetDeviceDetailsForOtherDaysQuery();
  const [getStreamDataForDevice, { isLoading: isStreamDataLoading }] =
  useLazyGetStreamDataForDeviceQuery();
  const [viewport, setViewport] = useState({
    center: { lat: 40.7, lng: -74 },
    zoom: 2,
    width: 800,
    height: 400,
    bearing: 0,
    streetViewControl: true,
    fullscreenControl: false,
    fullscreenControlOptions: true,
  });

  const handleCalendarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCalendarClose = () => {
    setAnchorEl(null);
  };

  const fetchTodayData = async (selectedDate) => {
    try {
      const response = await getDeviceDetailsForToday({
        organisation: user?.organization,
        deviceId: params?.id,
        limit: 3000,
        offset: 0,
      });
      if (response?.data) {
      } else if (response?.error) {
        toast.error(response?.error?.error || response?.error?.data?.message, {
          autoClose: 2000,
          hideProgressBar: true,
        });
      }
    } catch (err) {
      console.log("error => ", err);
    }
  };
  const fetchOtherDayData = async (selectedDate) => {
    try {
      const response = await getDeviceDetailsForOtherDays({
        // organisation: user?.organization,
        organisation: "topgreen-johns_creek",
        deviceId: params?.id,
        date: getDateForApi(selectedDate),
      });
      if (response?.data) {
        const streamData = response?.data?.results.filter((result) => result.file_type === "stream_data")
        if(streamData) {
          const streamResponse = await getStreamDataForDevice({
            organisation: user?.organization,
            streamId: streamData[0]?.id
          })
          try {
            const response = await fetch(streamResponse?.data?.file_s3_url);
            if (!response.ok) {
              throw new Error("Data could not be fetched!");
            }
            const csvData = await response.text();
            const data = csvData.split("\n").map((row) => {
              return row.split(",");
            });
          } catch (error) {
            toast.error("Something went wrong", {
              autoClose: 1000,
              hideProgressBar: true,
            });
          }
        }
      } else if (response?.error) {
        toast.error(response?.error?.error || response?.error?.data?.message, {
          autoClose: 2000,
          hideProgressBar: true,
        });
      }
    } catch (err) {
      console.log("error => ", err);
    }
  };

  useEffect(() => {
    if (selectedDate) {
      if (
        getDateFromCalender(selectedDate) === getDateFromCalender(new Date())
      ) {
        fetchTodayData(selectedDate);
      } else {
        fetchOtherDayData(selectedDate)
      }
    }
  }, [selectedDate]);

  return (
    <div className="mapPanelContainer">
      <div className="mapboxMapContainer">
        <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY}>
          <Map
            {...viewport}
            zoom={viewport?.zoom}
            defaultZoom={viewport?.zoom}
            center={viewport?.center}
            defaultCenter={viewport?.center}
            onCameraChanged={(v) => setViewport(v)}
            gestureHandling={"greedy"}
            draggableCursor="default" // Change this to any cursor style you want when the map is idle
            draggingCursor="move"
            tiltInteractionEnabled={true}
            defaultTilt={0}
            reuseMaps={true}
            disableDefaultUI={true}
            mapId={"bf51a910020fa252"}
            defaultBounds={{
              south: 40.5,
              west: -74.2,
              north: 40.9,
              east: -73.8,
            }}
            mapTypeControlOptions={{
              style: mapsLibrary?.MapTypeControlStyle?.HORIZONTAL_BAR,
              position: ControlPosition.TOP_CENTER,
            }}
          >
            <button className="viewMapControls" onClick={handleCalendarClick}>
              <Typography>
                {selectedDate
                  ? getDateFromCalender(selectedDate)
                  : "Select Date"}
              </Typography>{" "}
              {<FaCalendar color="#326703" fontSize={16} />}
            </button>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleCalendarClose}
              disableScrollLock={true}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&::before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <DayCalender
                dayCalenderValue={selectedDate}
                handleDayCalender={setSelectedDate}
              />
            </Menu>
          </Map>
        </APIProvider>
      </div>
    </div>
  );
};

export default DeviceDetails;
