import React, { useState } from "react";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import DeviceBasedTimeline from "./DeviceBasedTimeline";
import useViewportWidth from "../../../Hooks/useViewportWidth";
import ServiceBasedTimeline from "./ServiceBasedTimeline";
import { Box, Button, MenuItem, Select, Typography } from "@mui/material";
import { getCustomColor } from "../../../utils/theme";

const GISTimelineDrawer = ({
  isPlannerDrawerOpen,
  isTimelineDrawerOpen,
  setIsTimelineDrawerOpen,
  drawerRef,
}) => {
  const width = useViewportWidth();
  const [tabValue, setTabValue] = useState(0);
  const greenShade1 = getCustomColor("greenShade1");
  const blackShade1 = getCustomColor("blackShade1");
  return (
    <Drawer
      open={isTimelineDrawerOpen}
      direction="bottom"
      className="gisTimelineDrawer"
      lockBackgroundScroll={false}
      enableOverlay={false}
      style={{
        height: "auto",
        borderRadius: "10px",
        bottom: "2vh",
        right: "2vw",
        left: isPlannerDrawerOpen && width > 768 ? "38vw" : "2vw",
        width: isPlannerDrawerOpen && width > 768 ? "58vw" : "95vw",
      }}
    >
      <Box className="gisTimelineHeader">
        {width > 500 ? (
          <>
            <Box>
              <Typography
                component={"div"}
                variant="h6"
                fontWeight={"600"}
                sx={{ color: blackShade1.main }}
                fontSize={30}
              >
                Gantt Chart
              </Typography>
            </Box>
            <Box
              sx={{ background: greenShade1.main }}
              className="timelineTabContainer"
            >
              <Box
                sx={{
                  backgroundColor: tabValue === 0 ? "primary.dark" : "white",
                  color: tabValue === 0 ? "white" : "black",
                  border: `1px solid ${tabValue === 0 ? "#4EA403" : "grey"}`,
                }}
                className="timelineTabButton"
                onClick={() => setTabValue(0)}
              >
                Services
              </Box>
              <Box
                sx={{
                  backgroundColor: tabValue === 1 ? "primary.dark" : "white",
                  color: tabValue === 1 ? "white" : "black",
                  border: `1px solid ${tabValue === 1 ? "#4EA403" : "grey"}`,
                }}
                className="timelineTabButton"
                onClick={() => setTabValue(1)}
              >
                Crew
              </Box>
            </Box>
          </>
        ) : (
          <Select
            value={tabValue}
            className="tabSelect"
            sx={{ marginBottom: "10px" }}
            onChange={(e) => setTabValue(e.target.value)}
            fullWidth
          >
            <MenuItem selected value={0}>
              Device Wise
            </MenuItem>
            <MenuItem value={1}>Service Wise</MenuItem>
          </Select>
        )}
        <Box>
          <button onClick={() => setIsTimelineDrawerOpen(!isTimelineDrawerOpen)} sx={{borderRadius: "30px", backgroundColor: "grey", color: "white"}} className="timelineCloseButton">Close</button>
        </Box>
      </Box>

      <div ref={drawerRef}>
        <div className="timelineContainer">
          <div>
            {tabValue === 0 && <ServiceBasedTimeline />}
            {tabValue === 1 && <DeviceBasedTimeline />}
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default GISTimelineDrawer;
