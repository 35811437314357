import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DeckGlOverlay } from "../../../utils/deckgl-overlay";
import { GeoJsonLayer } from "@deck.gl/layers";
import useViewportWidth from "../../../Hooks/useViewportWidth";
import { addRandomOffsettoNumberCircles } from "../../../utils/common";

function getDeckGlLayers(data, showNumberCircles) {
  if (!data || !showNumberCircles) return [];
  return [
    new GeoJsonLayer({
      id: "TextsLayer",
      data: data,
      pointType: "circle+text",
      visible: showNumberCircles ? true : false,
      getPosition: (d) => d.geometry.coordinates,
      getFillColor: [255, 255, 255, 240],
      getLineColor: (d) => {
        const color = d.properties.color.slice(1); // Remove '#' from color
        return [
          parseInt(color.substring(0, 2), 16),
          parseInt(color.substring(2, 4), 16),
          parseInt(color.substring(4, 6), 16),
          200,
        ];
      },
      getText: (f) => {
        return `${f.properties.text}`;
      }, // Ensure text is a string
      textFontWeight: 900,
      getTextSize: 12,
      stroked: true,
      getLineWidth: 2,
      lineWidthMaxPixels: 2,
      iconBillboard: true,
      getPointRadius: 10,
      pointSizeScale: 15,
      pointRadiusMaxPixels: 10,
      textSizeMaxPixels: 10,
      textSizeScale: 1,
      pickable: false,
    }),
  ];
}

const EntryPoints = ({
  filteredGeoJson,
  crewMemberDayWise,
  selectedServiceIds,
  showNumberCircles,
  getEntryPointForPolygonId,
}) => {
  const [numberMarkersJson, setNumberMarkersJson] = useState(null);
  const lookUpData = useSelector((state) => state.global.lookUpData);
  const [isVisible, setIsVisible] = useState(true);
  const width = useViewportWidth();

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsVisible(!document.hidden);
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (crewMemberDayWise) {
      const crewMarkerDataJson = {
        type: "FeatureCollection",
        features: Object.entries(crewMemberDayWise).flatMap(
          ([crewMemberId, crewMember]) =>
            crewMember.day.flatMap((dayInfo) => {
              const filteredPolygonIds = dayInfo.polygon.filter((polygonId) => {
                const polygon = lookUpData.polygons[polygonId];
                return (
                  polygon &&
                  selectedServiceIds.includes(polygon.serviceId.toString())
                );
              });

              // Make sure to return the result of this map so the flatMap can process it
              return filteredPolygonIds.flatMap((polygonId, i) => {
                // Use flatMap or filter out undefined if necessary
                if (!polygonId) return []; // Or handle this case as needed
                const entryPoint = getEntryPointForPolygonId(polygonId);
                if (!entryPoint) return []; // Or handle this case as needed
                const color = crewMember.crewMemberColor;
                const coordinatesWithOffset = addRandomOffsettoNumberCircles([
                  entryPoint?.lon,
                  entryPoint?.lat,
                ]);

                return [
                  {
                    type: "Feature",
                    geometry: {
                      type: "Point",
                      coordinates: coordinatesWithOffset,
                    },
                    properties: {
                      id: `${crewMemberId}-${i}`, // Unique identifier for the marker
                      crewMemberId: crewMemberId,
                      color: color,
                      text: i + 1, // Text or label for the marker
                    },
                  },
                ];
              });
            })
        ),
      };

      setNumberMarkersJson(crewMarkerDataJson);
    }
  }, [crewMemberDayWise, filteredGeoJson]);
  return (
      <DeckGlOverlay
        layers={getDeckGlLayers(numberMarkersJson, showNumberCircles)}
        typedArrayManagerProps={
          width < 800 ? { overAlloc: 1, poolSize: 0 } : null
        }
        isVisible={isVisible}
      />
  );
};

export default EntryPoints;
