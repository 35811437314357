import {
  Box,
  Checkbox,
  Drawer,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IoClose } from "react-icons/io5";
import {
  setDevicesColor,
  setSelectedDay,
  setSelectedDevices,
} from "../../../slices/gisDashboardSlice";
import { getCustomColor } from "../../../utils/theme";
import { crewMemberColors } from "../../../utils/common";

const PathOfMotionControlDrawer = ({
  detailsData,
  isPathOfMotionDrawerOpen,
  handlePathOfMotionClick,
}) => {
  const dispatch = useDispatch();
  const blackShade1 = getCustomColor("blackShade1");
  const devicesColor = useSelector((state) => state.gisDashboard.devicesColor);
  const selectedDay = useSelector((state) => state.gisDashboard.selectedDay);
  const [dayOptions, setDayOptions] = useState(null);
  const handleChange = (event) => {
    const selectedDay = event.target.value;
    dispatch(setSelectedDay(selectedDay));
  };
  const selectedDevices = useSelector(
    (state) => state.gisDashboard.selectedDevices
  );

  useEffect(() => {
    if (detailsData) {
      let options = [];
      detailsData.forEach((item) => {
        if (!options.includes(item?.date)) {
          options.push(item?.date);
        }
      });
      dispatch(setSelectedDay(options[0]));
      setDayOptions(options);
    }
  }, [detailsData]);

  useEffect(() => {
    if (detailsData && selectedDay) {
      const uniqueDevices = {};
      const uniqueColorsForDevices = {};

      detailsData.forEach((item) => {
        if (selectedDay === item?.date)
          Object.keys(item.devices_list).forEach((device) => {
            uniqueDevices[device] = true;
            uniqueColorsForDevices[device] = "";
          });
      });

      const deviceIds = Object.keys(uniqueDevices);

      deviceIds.forEach((deviceId, index) => {
        // Calculate the color index using modulo operation to loop through colors
        const colorIndex = index % crewMemberColors.length;
        uniqueColorsForDevices[deviceId] = crewMemberColors[colorIndex];
      });
      dispatch(setDevicesColor(uniqueColorsForDevices));
      dispatch(setSelectedDevices(uniqueDevices));
    }
  }, [detailsData, selectedDay]);

  const handleDevicesCheckboxChange = (serviceType) => {
    dispatch(setSelectedDevices(serviceType));
  };

  return (
    <Drawer
      anchor="top"
      open={isPathOfMotionDrawerOpen}
      hideBackdrop
      variant="persistent"
      sx={{
        "& .MuiDrawer-paper": {
          borderRadius: "10px 10px",
          width: 220,
          position: "fixed",
          bottom: 0,
          top: "10vh",
          right: 10,
          left: "auto",
          zIndex: "1",
          maxHeight: "25vh",
        },
      }}
    >
      <div className="drawerWrapper">
        <div className="mainContainer">
          <div className="RightControls">
            <div className="mapControlsTitleContainer">
              <Typography component="div" fontWeight="600" variant="body2">
                Path Controls
              </Typography>
              <div className="closeButton">
                <IoClose size={24} onClick={handlePathOfMotionClick} />
              </div>
            </div>
            <Box>
              <Select
                value={selectedDay}
                size="small"
                input={<OutlinedInput sx={{fontSize: '13px'}} />}
                onChange={handleChange}
                fullWidth
              >
                {dayOptions &&
                  dayOptions.map((day) => (
                    <MenuItem key={day} value={day}>{`${day}`}</MenuItem>
                  ))}
              </Select>
            </Box>
            <Box>
              <Typography component="div" fontWeight="600" variant="body2">
                Devices
              </Typography>
              <Grid container spacing={1}>
                {Object.keys(selectedDevices).map((device) => {
                  return (
                    <Grid item xs={6} className="devicesCheckbox" key={device}>
                      <Checkbox
                        sx={{ padding: "0px" }}
                        size="small"
                        checked={selectedDevices[device]}
                        onChange={() => handleDevicesCheckboxChange(device)}
                      />
                      <Typography
                        component="h6"
                        sx={{ color: blackShade1.main }}
                        variant="subtitle2"
                      >
                        {device}
                      </Typography>
                      <Box
                        className="pathOfMotionColor"
                        sx={{
                          backgroundColor: devicesColor[device],
                          cursor: "pointer",
                        }}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default PathOfMotionControlDrawer;
