import React from "react";
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { getCustomColor } from "../../utils/theme";

const ParkingInfo = ({ parkings }) => {
  const blackShade1 = getCustomColor("blackShade1");

  return (
    <Grid container spacing={3} justifyContent="center" alignItems="center">
      {parkings?.length > 0 &&
        parkings?.map((parking, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card sx={{background: "#edf6f0", boxShadow: "none"}}>
              <CardContent>
                <Typography variant="h5">{parking?.parkingName}</Typography>
                <Box>
                  <Typography
                    sx={{ color: blackShade1.main }}
                    fontWeight="600"
                    variant="body1"
                    component="span"
                    color="text.secondary"
                  >
                    Type:{" "}
                  </Typography>
                  <Typography
                    variant="body1"
                    component="span"
                    color="text.secondary"
                  >
                    {parking?.parkingType}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    sx={{ color: blackShade1.main }}
                    fontWeight="600"
                    variant="body1"
                    component="span"
                    color="text.secondary"
                  >
                    Address:{" "}
                  </Typography>
                  <Typography
                    variant="body1"
                    component="span"
                    color="text.secondary"
                  >
                    {parking?.address}
                  </Typography>
                </Box>

                {/* <Typography variant="body1">
                  Coordinates: ({parking?.points?.lat}, {parking?.points?.lon})
                </Typography> */}
              </CardContent>
            </Card>
          </Grid>
        ))}
    </Grid>
  );
};

export default ParkingInfo;
