import React from "react";
import {
  convertTimestampToFormattedDate,
  getCrewMemberName,
  getEquipmentName,
  getServiceName,
} from "../../utils/common";
import { InfoWindow } from "@vis.gl/react-google-maps";
import { Typography } from "@mui/material";

const PolygonDetailsPopup = ({
  selectedPolygon,
  clickedPolygonData,
  setClickedPolygonData,
  lookUpData,
}) => {
  return (
    <>
      {selectedPolygon && (
        <InfoWindow
          position={{lat: selectedPolygon?.entryPoint?.lat, lng: selectedPolygon?.entryPoint?.lon}}
          offsetTop={-30}
          closeButton={false}
          closeOnClick={false}
        >
          <div>
            <p>Path Id : {selectedPolygon?.pathId}</p>
            <p>
              Arrival Time :{" "}
              {convertTimestampToFormattedDate(selectedPolygon?.arrivalTime)}
            </p>
            <p>
              Departure Time :{" "}
              {convertTimestampToFormattedDate(selectedPolygon?.departureTime)}
            </p>
            <p>
              Travel Distance :{" "}
              {selectedPolygon?.travelDistance}
            </p>
            <p>Entry Point : lat {selectedPolygon?.entryPoint?.lat}, lon {selectedPolygon?.entryPoint?.lon}  </p>
            <p>Exit Point : lat {selectedPolygon?.exitPoint?.lat},lon {selectedPolygon?.exitPoint?.lon}  </p>
            <p>Service Name : {selectedPolygon?.serviceName}</p>
            <p>Equipment Name : {selectedPolygon?.equipmentName}</p>
          </div>
        </InfoWindow>
      )}

      {clickedPolygonData && (
        <InfoWindow
          position={{lat: clickedPolygonData?.coordinates?.[1], lng: clickedPolygonData?.coordinates?.[0]}}
          anchor="left"
          pixelOffset={[0, -20]}
          disableAutoPan={true}
          shouldFocus={false}
          onClose={()=> setClickedPolygonData(null)}
          closeButton={false}
        >
          <div className="hoveredRow">
            <Typography component={"span"} variant="body2" fontWeight={600}>Crew Member : </Typography>
            <Typography component={"span"} variant="body2">
              {getCrewMemberName(clickedPolygonData?.crewMemberId, lookUpData)}
            </Typography>
          </div>
          <div className="hoveredRow">
            <Typography component={"span"} variant="body2" fontWeight={600}>Service : </Typography>
            <Typography component={"span"} variant="body2">
              {getServiceName(clickedPolygonData?.serviceId, lookUpData)}
            </Typography>
          </div>
          <div className="hoveredRow">
            <Typography component={"span"} variant="body2" fontWeight={600}>Equipment : </Typography>
            <Typography component={"span"} variant="body2">
              {getEquipmentName(clickedPolygonData?.crewEquipmentId, lookUpData)}
            </Typography>
          </div>
          <div className="hoveredRow">
            <Typography component={"span"} variant="body2" fontWeight={600}>Polygon Id : </Typography>
            <Typography component={"span"} variant="body2">
              {clickedPolygonData?.properties?.pathId}
            </Typography>
          </div>
          <div className="hoveredRow">
            <Typography component={"span"} variant="body2" fontWeight={600}>
              {clickedPolygonData?.properties?.measuredType} : 
            </Typography>
            <Typography component={"span"} variant="body2">
              {clickedPolygonData?.properties?.measuredQty?.toFixed(0)}{" "}
              {clickedPolygonData?.properties?.measuredType === "area"
                ? "ft²"
                : "ft"}
            </Typography>
          </div>
          <div className="hoveredRow">
            <Typography component={"span"} variant="body2" fontWeight={600}>Start Time : </Typography>
            <Typography component={"span"} variant="body2">
              {convertTimestampToFormattedDate(clickedPolygonData?.arrivalTime)}
            </Typography>
          </div>
          <div className="hoveredRow">
            <Typography component={"span"} variant="body2" fontWeight={600}>End Time : </Typography>
            <Typography component={"span"} variant="body2">
              {convertTimestampToFormattedDate(clickedPolygonData?.departureTime)}
            </Typography>
          </div>
        </InfoWindow>
      )}
    </>
  );
};

export default PolygonDetailsPopup;
