import React, { useEffect, useState } from "react";
import { DeckGlOverlay } from "../../../utils/deckgl-overlay";
import { GeoJsonLayer } from "@deck.gl/layers";
import useViewportWidth from "../../../Hooks/useViewportWidth";

function getDeckGlLayers(data) {
  if (!data) return [];
  return [
    new GeoJsonLayer({
      id: "mapHoveredGeoJsonLayer",
      data: data,
      stroked: true,
      filled: true,
      getLineWidth: 0.5,
      lineWidthMinPixels: 1,
      opacity: 1,
      getFillColor: [255, 255, 255, 200],
      getLineColor: [0, 0, 0, 0],
      pickable: false
    }),
  ];
}

const MapHoveredPolygonLayer = ({ hoveredGeoJson }) => {
  const [isVisible, setIsVisible] = useState(true);
  const width = useViewportWidth();

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsVisible(!document.hidden);
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return (
    <DeckGlOverlay
      layers={getDeckGlLayers(hoveredGeoJson)}
      typedArrayManagerProps={
        width < 800 ? { overAlloc: 1, poolSize: 0 } : null
      }
      isVisible={isVisible}
    />
  );
};

export default MapHoveredPolygonLayer;
