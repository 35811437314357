import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DeckGlOverlay } from "../../../utils/deckgl-overlay";
import { GeoJsonLayer } from "@deck.gl/layers";
import useViewportWidth from "../../../Hooks/useViewportWidth";

function getDeckGlLayers(data, devicesColor,isSummary) {
  if (!data) return [];

  return [
    new GeoJsonLayer({
      id: "PointsLayer",
      data: data,
      visible: isSummary ? false : true,
      pointType: "circle+text",
      getPosition: (d) => d?.geometry?.coordinates,
      getFillColor: [255, 255, 255, 240],
      getLineColor: (d) => {
        const color = devicesColor[d?.properties?.deviceId].slice(1); // Remove '#' from color
        return [
          parseInt(color.substring(0, 2), 16),
          parseInt(color.substring(2, 4), 16),
          parseInt(color.substring(4, 6), 16),
          200,
        ];
      },

      getText: (f) => {
        return `${f.properties.text}`;
      }, // Ensure text is a string
      textFontWeight: 900,
      getTextSize: 12,
      stroked: true,
      getLineWidth: 2,
      lineWidthMaxPixels: 2,
      iconBillboard: true,
      getPointRadius: 10,
      pointSizeScale: 15,
      pointRadiusMaxPixels: 10,
      textSizeMaxPixels: 10,
      textSizeScale: 1,
      pickable: false,
    }),
  ];
}

const PathOfMotionNumberPoints = ({ isSummary }) => {
  const [isVisible, setIsVisible] = useState(true);
  const pathOfMotionEntryPointsData = useSelector(
    (state) => state.gisDashboard.pathOfMotionEntryPointsData
  );
  const devicesColor = useSelector((state) => state.gisDashboard.devicesColor);
  const width = useViewportWidth();

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsVisible(!document.hidden);
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return (
    <DeckGlOverlay
      layers={getDeckGlLayers(pathOfMotionEntryPointsData, devicesColor,isSummary)}
      typedArrayManagerProps={
        width < 800 ? { overAlloc: 1, poolSize: 0 } : null
      }
      isVisible={isVisible}
    />
  );
};

export default PathOfMotionNumberPoints;
