import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  IconButton,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IoMdClose } from "react-icons/io";
import { SketchPicker } from "react-color";
import _ from "lodash";
import { setLookUpData } from "../../slices/globalSlice";

const ColorPickerModal = ({ isOpen, setIsOpen, crewMemberColorData }) => {
  const [currentColor, setCurrentColor] = useState();
  useEffect(() => {
    setCurrentColor(crewMemberColorData?.crewMemberColor);
  }, [crewMemberColorData]);
  const lookUpData = useSelector((state) => state.global.lookUpData);

  const dispatch = useDispatch();

  function updateCrewMemberColor(data, crewMemberId, newColor) {
    const updatedData = _.cloneDeep(data);
    if (updatedData?.crewMemberDayWise[crewMemberId]) {
      updatedData.crewMemberDayWise[crewMemberId].crewMemberColor = newColor;
    }
    return updatedData;
  }
  const handleSubmit = () => {
    const updatedCrewMemberDayWiseData = updateCrewMemberColor(
      lookUpData,
      crewMemberColorData?.crewMemberId,
      currentColor
    );
    dispatch(setLookUpData(updatedCrewMemberDayWiseData));
    setIsOpen(false);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  const handleChangeComplete = (color) => {
    setCurrentColor(color.hex);
  };

  return (
    <Dialog open={isOpen} fullWidth maxWidth="xs">
      <DialogTitle fontWeight={700} fontSize="22px" align="left">
        Update color
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleCancel}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <IoMdClose />
      </IconButton>
      <div className="confirmationModalBody">
        <div className="confirmationModalBodyContent">
          <SketchPicker
            color={currentColor}
            width="95%"
            onChangeComplete={handleChangeComplete}
          />
        </div>
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            justifyContent: "flex-end",
            mt: 2,
          }}
        >
          <Button fullWidth variant="contained" onClick={handleSubmit}>
            Update Color
          </Button>
        </Box>
      </div>
    </Dialog>
  );
};

export default ColorPickerModal;
