import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  IconButton,
  Box,
  Typography,
  Grid,
  CircularProgress,
  InputAdornment,
  Switch,
} from "@mui/material";
import { MdAdd, MdDelete, MdRemove } from "react-icons/md";
import { getCustomColor } from "../../../utils/theme";
import {
  useAddCrewDataMutation,
  useLazyGetCrewDataQuery,
} from "../../../services/OpenApi";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { handleNextStep } from "../../../slices/stepperFormSlice";

const CrewAdditionForm = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.global.user);
  const blackShade1 = getCustomColor("blackShade1");
  const [crews, setCrews] = useState([]);
  const [workingHours, setWorkingHours] = useState(8);
  const [planningDays, setPlanningDays] = useState(1);
  const [isExpectedDays, setIsExpectedDays] = useState(false);
  const [addCrewData, { isLoading }] = useAddCrewDataMutation();
  const [getCrewData] = useLazyGetCrewDataQuery();

  const handleCrewMemberSizeChange = (index, value) => {
    const newCrews = crews.map((crew, i) =>
      i === index ? { ...crew, crewMemberSize: value } : crew
    );
    setCrews(newCrews);
  };

  const handleAddCrew = () => {
    setCrews([...crews, { crewMemberSize: 1 }]);
  };

  const handleRemoveCrew = (index) => {
    if (crews.length > 1) {
      setCrews(crews.filter((_, i) => i !== index));
    }
  };

  const onSubmit = async () => {
    try {
      const data = {
        orderId: params?.id,
        orgName: user?.organization,
        content: {
          workingHour: workingHours * 60, // convert hours to minutes
          numberOfDaysPlanning: isExpectedDays ? planningDays : 0,
          crews: crews,
        },
      };
      const response = await addCrewData(data).unwrap();

      if (response?.data) {
        toast.success("Crew created successfully", {
          autoClose: 1000,
          hideProgressBar: true,
        });
        dispatch(handleNextStep());
      } else if (response?.error) {
        toast.error(
          response?.error?.error ||
            response?.error ||
            response?.error?.data?.message,
          {
            autoClose: 2000,
            hideProgressBar: true,
          }
        );
      }
    } catch (err) {
      dispatch(handleNextStep());
      console.log("error => ", err);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await getCrewData({
          orderId: params?.id,
          orgName: user?.organization,
        }).unwrap();
        if (response) {
          setCrews(response?.data?.crews);
          setWorkingHours(response?.data?.workingHours / 60);
          if (response?.data?.numberOfDaysPlanning > 0) {
            setIsExpectedDays(true);
            setPlanningDays(response?.data?.numberOfDaysPlanning);
          } else {
            setIsExpectedDays(false);
            setPlanningDays(1);
          }
        }
      } catch (err) {
        if (err?.data?.httpStatus === 404) {
          if (err?.data?.message === "Crew and member not created yet") {
            setCrews([{ crewMemberSize: 4 }]);
          }
        }
        console.log("error", err);
      }
    })();
  }, []);

  return (
    <div className="StepsFormContainer">
      <h3 className="StepsFormContainerHeading">Add Crew</h3>
      {crews &&
        crews.map((crew, index) => (
          <Box
            key={index}
            className="minMaxContainer"
            display="flex"
            alignItems="center"
            marginBottom={2}
            width="100%"
          >
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={6}>
                <Typography
                  fontWeight="600"
                  sx={{ color: blackShade1.main }}
                  variant="body1"
                >{`Crew ${index + 1}`}</Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Size"
                  value={crew.crewMemberSize}
                  inputProps={{ min: 1, max: 9, readOnly: true }}
                  variant="outlined"
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          size="small"
                          onClick={() =>
                            handleCrewMemberSizeChange(
                              index,
                              Math.max(1, crew.crewMemberSize - 1)
                            )
                          }
                          disabled={crew.crewMemberSize <= 1}
                        >
                          <MdRemove fontSize={12} />
                        </IconButton>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onClick={() =>
                            handleCrewMemberSizeChange(
                              index,
                              Math.min(9, crew.crewMemberSize + 1)
                            )
                          }
                          disabled={crew.crewMemberSize >= 9}
                        >
                          <MdAdd fontSize={12} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  color="error"
                  onClick={() => handleRemoveCrew(index)}
                  disabled={crews.length === 1}
                >
                  <MdDelete fontSize="20" />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
        ))}
      {crews.length < 5 && (
        <Button
          variant="text"
          onClick={handleAddCrew}
          sx={{ color: "primary.dark" }}
          startIcon={<MdAdd />}
        >
          Add Crew
        </Button>
      )}
      <Grid
        marginTop="20px"
        className="minMaxContainer"
        container
        spacing={2}
        alignItems="center"
      >
        <Grid item xs={8}>
          <Typography
            fontWeight="600"
            sx={{ color: blackShade1.main }}
            variant="body1"
          >
            Working Hours (per day)
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            value={workingHours}
            inputProps={{ min: 1, readOnly: true }}
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    size="small"
                    onClick={() =>
                      setWorkingHours((prev) => Math.max(1, prev - 1))
                    }
                    disabled={workingHours <= 1}
                  >
                    <MdRemove fontSize={12} />
                  </IconButton>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    onClick={() => setWorkingHours((prev) => prev + 1)}
                    disabled={workingHours >= 24}
                  >
                    <MdAdd fontSize={12} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Box marginTop={3}>
          <Switch
            color="primary"
            checked={isExpectedDays}
            onChange={() => setIsExpectedDays((prev) => !prev)}
          />{" "}
          <Typography
            fontWeight="600"
            component="span"
            fontSize={13}
            sx={{ color: blackShade1.main }}
            variant="body1"
          >
            Do you want to plan with fixed number of days ?
          </Typography>
        </Box>
        {isExpectedDays && (
          <>
            <Grid item xs={8}>
              <Typography
                fontWeight="600"
                sx={{ color: blackShade1.main }}
                variant="body1"
              >
                Expected days for planning
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <TextField
                value={planningDays}
                inputProps={{ min: 1, readOnly: true }}
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        size="small"
                        onClick={() =>
                          setPlanningDays((prev) => Math.max(1, prev - 1))
                        }
                        disabled={planningDays <= 1}
                      >
                        <MdRemove fontSize={12} />
                      </IconButton>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        onClick={() => setPlanningDays((prev) => prev + 1)}
                      >
                        <MdAdd fontSize={12} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </>
        )}
      </Grid>
      <Button
        type="submit"
        fullWidth
        disabled={isLoading}
        variant="contained"
        onClick={onSubmit}
        sx={{ mt: 3, mb: 2 }}
      >
        {isLoading ? <CircularProgress size={24} /> : "Confirm"}
      </Button>
    </div>
  );
};

export default CrewAdditionForm;
