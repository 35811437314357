import React, { useEffect, useState } from "react";
import { DeckGlOverlay } from "../../../utils/deckgl-overlay";
import { GeoJsonLayer } from "@deck.gl/layers";
import { getServiceColorRgb, hexToRgb } from "../../../utils/common";
import { useSelector } from "react-redux";
import useViewportWidth from "../../../Hooks/useViewportWidth";

function getCrewMemberWiseServiceLayers(
  data,
  serviceWise,
  handleHoveredPolygon,
  width
) {
  if (!data) return [];
  return [
    new GeoJsonLayer({
      id: "geojsonCrewWiseServiceWise",
      data: data,
      filled: true,
      opacity: 1,
      getLineWidth: 0.2,
      visible: serviceWise ? true : false,
      getFillColor: (f) => getServiceColorRgb(f?.properties?.serviceType),
      pickable: width < 800 ? false : true,
      onHover: (info) => handleHoveredPolygon(info),
    }),
    new GeoJsonLayer({
      id: "geojsonCrewWiseServiceWiseLines",
      data: data,
      filled: false,
      getLineWidth: 0.2,
      opacity: 0.4,
      visible: serviceWise ? true : false,
      getLineColor: (f) => getServiceColorRgb(f?.properties?.serviceType),
      pickable: width < 800 ? false : true,
      onHover: (info) => handleHoveredPolygon(info),
    }),
    new GeoJsonLayer({
      id: "geojsonCrewWise",
      data: data,
      stroked: true,
      filled: true,
      getLineWidth: 0.2,
      opacity: !serviceWise ? 0.4 : 1,
      visible: !serviceWise ? true : false,
      lineWidthMinPixels: 2,
      pickable: width < 800 ? false : true,
      getFillColor: (f) => hexToRgb(f?.properties?.color),
      getLineColor: (f) => hexToRgb(f?.properties?.color),
      onHover: (info) => handleHoveredPolygon(info),
    }),
  ];
}
const CrewMemberServiceLayers = ({ filteredGeoJson, handleHoveredPolygon }) => {
  const [isVisible, setIsVisible] = useState(true);
  const width = useViewportWidth();
  const serviceWise = useSelector((state) => state.global.serviceWise);
   // this visible functionality is added due to webgl context lost issue
   useEffect(() => {
    const handleVisibilityChange = () => {
      setIsVisible(!document.hidden);
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);
  return (
    <DeckGlOverlay
      layers={getCrewMemberWiseServiceLayers(
        filteredGeoJson,
        serviceWise,
        handleHoveredPolygon,
        width
      )}
      pickable={width < 800 ? false : true}
      typedArrayManagerProps = {width < 800 ? { overAlloc: 1, poolSize: 0 } : null}
      isVisible={isVisible}
    />
  );
};

export default CrewMemberServiceLayers;
