import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useResetPasswordMutation } from "../../services/Auth";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { getCustomColor } from "../../utils/theme";
import { CircularProgress } from "@mui/material";
import AuthDesignWrapper from "../../components/AuthDesignWrapper/AuthDesignWrapper";

// TODO remove, this demo shouldn't need to reset the theme.
export default function ForgotPassword() {
  const {
    register: registerEmail,
    handleSubmit: handleEmailSubmit,
    getValues: getValuesEmail,
    formState: { errors: errorsEmail },
  } = useForm();
  const {
    register: registerPassword,
    handleSubmit: handleFormSubmit,
    getValues,
    formState: { errors: errorsPassword },
  } = useForm();
  const blackShade1 = getCustomColor("blackShade1");
  const [isRegistered, setIsRegistered] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [resetPassword, { isLoading }] = useResetPasswordMutation();

  const onEmailSubmit = async (data) => {
    const values = {
      email: getValuesEmail("email"),
    };
    try {
      const response = await resetPassword(values).unwrap();
      if (response) {
        setIsRegistered(true);
        toast.success(response?.detail, {
          autoClose: 2000,
          hideProgressBar: true,
        });
      }
    } catch (error) {
      toast.error(error?.data?.detail, {
        autoClose: 1000,
        hideProgressBar: true,
      });
    }
  };

  return (
    <AuthDesignWrapper
      body={
        <>
          <Typography
            component="h3"
            fontWeight="600"
            sx={{ color: blackShade1.main }}
            variant="h5"
          >
            Forgot Password
          </Typography>
          {!isRegistered ? (
            <Box
              component="form"
              noValidate
              onSubmit={handleEmailSubmit(onEmailSubmit)}
              sx={{ mt: 1 }}
            >
              <Typography
                fontWeight={"700"}
                sx={{ color: blackShade1.light }}
                component="body1"
                variant="body1"
              >
                Email
              </Typography>
              <TextField
                fullWidth
                size="small"
                margin="dense"
                id="email"
                name="email"
                placeholder="Enter an email id to get started"
                inputProps={{
                  form: {
                    autocomplete: "off",
                  },
                }}
                autoFocus
                {...registerEmail("email", {
                  required: "Email is required",
                  pattern: {
                    value:
                      /^[a-zA-Z0-9._+-]+@(?!gmail|yahoo)([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/,
                    message:
                      "Enter a valid email address with domain other than gmail/yahoo",
                  },
                })}
                error={!!errorsEmail.email}
                helperText={errorsEmail.email ? errorsEmail.email.message : ""}
              />

              <Button
                type="submit"
                fullWidth
                disabled={isLoading}
                variant="contained"
                sx={{ mt: 3, mb: 2, boxShadow: "none" }}
              >
                {isLoading ? <CircularProgress size={24} /> : "Continue"}
              </Button>
              <div className="createOne">
                <Typography component="h3" variant="body2">
                  Already have an account?{" "}
                  <Typography
                    onClick={() => navigate("/login")}
                    sx={{ cursor: "pointer" }}
                    component="span"
                    color="primary.dark"
                    variant="body2"
                  >
                    Sign In
                  </Typography>
                </Typography>
              </div>
            </Box>
          ) : (
            <>
              <Typography
                component="h3"
                textAlign="center"
                marginTop="20px"
                variant="body2"
              >
                Verification email sent to{" "}
                <Typography
                  component="span"
                  color="primary.dark"
                  variant="body2"
                >
                  {getValuesEmail("email")}
                </Typography>
              </Typography>
              <Typography
                component="h3"
                textAlign="center"
                marginTop="20px"
                variant="body2"
              >
                Please check your inbox and click on the verification link.
              </Typography>
            </>
          )}
        </>
      }
    ></AuthDesignWrapper>
  );
}
