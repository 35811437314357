import {
  APIProvider,
  ControlPosition,
  Map,
  useMapsLibrary,
} from "@vis.gl/react-google-maps";
import React, { useEffect, useState } from "react";
import { useMap } from "react-map-gl";
import { useLazyGetDevicesQuery } from "../../services/Properties";
import { ClusteredDeviceMarkers } from "./ClusteredDeviceMarkers";

const DevicesLiveLocation = () => {
  const map = useMap();
  const mapsLibrary = useMapsLibrary("maps");
  const user = JSON.parse(localStorage.getItem("user"));
  const [devices, setDevices] = useState([]);
  const [getDevices, isLoading] = useLazyGetDevicesQuery();
  
  const [viewport, setViewport] = useState({
    center: { lat: 40.7, lng: -74 },
    zoom: 2,
    width: 800,
    height: 400,
    bearing: 0,
    streetViewControl: true,
    fullscreenControl: false,
    fullscreenControlOptions: true,
  });

  useEffect(() => {
    (async () => {
      try {
        const response = await getDevices(user?.organization);
        if(response?.data){
          setDevices(response?.data?.results)
        }
      } catch (err) {
        console.log(err);
      }
    })()
  }, []);
  return (
    <div className="mapPanelContainer">
      <div className="mapboxMapContainer">
        <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY}>
          <Map
            {...viewport}
            zoom={viewport?.zoom}
            defaultZoom={viewport?.zoom}
            center={viewport?.center}
            defaultCenter={viewport?.center}
            onCameraChanged={(v) => setViewport(v)}
            gestureHandling={"greedy"}
            draggableCursor="default" // Change this to any cursor style you want when the map is idle
            draggingCursor="move"
            tiltInteractionEnabled={true}
            defaultTilt={0}
            reuseMaps={true}
            mapId={"bf51a910020fa25a"}
            defaultBounds={{
              south: 40.5,
              west: -74.2,
              north: 40.9,
              east: -73.8,
            }}
            mapTypeControlOptions={{
              style: mapsLibrary?.MapTypeControlStyle?.HORIZONTAL_BAR,
              position: ControlPosition.TOP_CENTER,
            }}
            >
            {devices && <ClusteredDeviceMarkers devices={devices} setDevices={setDevices}/>}
          </Map>
        </APIProvider>
      </div>
    </div>
  );
};

export default DevicesLiveLocation;
