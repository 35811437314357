import React, { useState } from "react";
import {
  useDeleteBeaconMutation,
  useGetBeaconsQuery,
} from "../../services/Properties";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import SecondaryButton from "../../components/Buttons/SecondaryButton";
import { MdAdd, MdDelete, MdEdit } from "react-icons/md";
import { StyledTableCell } from "../MapScreen/PlannerDetailsDrawer";
import { getCustomColor } from "../../utils/theme";
import AddBeaconModal from "../../Modals/AddBeaconModal/AddBeaconModal";
import ConfirmationModal from "../../Modals/ConfirmationModal/ConfirmationModal";

const Beacons = () => {
  const blackShade1 = getCustomColor("blackShade1");
  const user = JSON.parse(localStorage.getItem("user"));
  const [deleteBeaconKey, setDeleteBeaconKey] = useState(null);
  const [isAddBeaconModalOpen, setIsAddBeaconModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [editBeaconData, setEditBeaconData] = useState(null);
  const [deleteBeacon] = useDeleteBeaconMutation();
  const { data: beaconsData, isLoading } = useGetBeaconsQuery(
    user?.organization
  );

  const handleEditClick = (beacon) => {
    setIsAddBeaconModalOpen(true);
    setEditBeaconData(beacon);
  };

  const handleOk = () => {
    try {
      const response = deleteBeacon({
        organisation: user?.organization,
        assetKey: deleteBeaconKey,
      });
      if (response) {
        setIsConfirmationModalOpen(false);
        setDeleteBeaconKey(null)
      }
    } catch (err) {
      setIsConfirmationModalOpen(false);
    }
  };

  return (
    <div className="propertiesContainer">
      {beaconsData?.results?.length > 0 ? (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              component="h3"
              fontWeight="600"
              sx={{ color: blackShade1.main }}
              variant="h5"
            >
              Beacons List
            </Typography>
            <SecondaryButton
              type="button"
              marginTop="15px"
              marginBottom="10px"
              align="right"
              onClick={() => setIsAddBeaconModalOpen(true)}
              sx={{ width: "100px" }}
              variant="contained"
              background="#E0FFC4"
              textcolor="#3F8203"
            >
              <MdAdd /> Add Beacon
            </SecondaryButton>
          </Box>
          <div className="parcelTableContainer">
            <TableContainer component={Paper}>
              <Table stickyHeader size="small" aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell sx={{ maxWidth: 100 }}>
                      Brand
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        maxWidth: 200,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      align="center"
                    >
                      Display Name
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        maxWidth: 200,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      align="center"
                    >
                      Type
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        maxWidth: 150,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      align="center"
                    >
                      Beacon Id
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        maxWidth: 150,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      align="center"
                    >
                      Modal
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        maxWidth: 50,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      align="center"
                    >
                      Deck Size
                    </StyledTableCell>
                    <StyledTableCell align="center">Actions</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {beaconsData?.results?.map((beaconData) => {
                    const beacon = beaconData?.core_attributes;
                    return (
                      <TableRow key={beacon.beacon_id}>
                        <StyledTableCell
                          sx={{
                            maxWidth: 100,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <Tooltip title={beacon.brand}>
                            <span>{beacon.brand}</span>
                          </Tooltip>
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{
                            maxWidth: 200,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                          align="center"
                        >
                          <Tooltip title={beacon.display_name}>
                            <span>{beacon.display_name}</span>
                          </Tooltip>
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{
                            maxWidth: 150,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                          align="center"
                        >
                          <Tooltip title={beacon?.type}>{beacon?.type}</Tooltip>
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{
                            maxWidth: 150,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                          align="center"
                        >
                          {beacon?.beacon_id}
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          sx={{
                            maxWidth: 50,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {beacon?.modal}
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          sx={{
                            maxWidth: 50,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {beacon?.deck_size}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          <div className="tableActions">
                            <div>
                              <Tooltip title="Edit Beacon">
                                <MdEdit
                                  onClick={() => {
                                    handleEditClick(beaconData);
                                  }}
                                  cursor={"pointer"}
                                  fontSize={18}
                                />
                              </Tooltip>
                            </div>
                            <Tooltip title="Delete">
                              <MdDelete
                                cursor={"pointer"}
                                onClick={() => {
                                  setIsConfirmationModalOpen(true);
                                  setDeleteBeaconKey(beaconData?.key)
                                }}
                                fontSize={18}
                              />{" "}
                            </Tooltip>
                          </div>
                        </StyledTableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <ConfirmationModal
              isOpen={isConfirmationModalOpen}
              setIsOpen={setIsConfirmationModalOpen}
              body="Do you want to delete this beacon ?"
              handleOk={handleOk}
            />
            <AddBeaconModal
              isOpen={isAddBeaconModalOpen}
              setIsOpen={setIsAddBeaconModalOpen}
              editData={editBeaconData}
              setEditBeaconData={setEditBeaconData}
            />
          </div>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            display: isLoading ? "none" : "flex",
          }}
          className="emptyProperties"
        >
          <img src="/images/emptyProperty.png" width="350px" height="340px" />
          <SecondaryButton
            type="button"
            align="right"
            onClick={() => setIsAddBeaconModalOpen(true)}
            sx={{
              width: "100px",
              marginBottom: "10px",
            }}
            variant="contained"
            background="#E0FFC4"
            textcolor="#3F8203"
          >
            <MdAdd /> Add Beacon
          </SecondaryButton>
        </Box>
      )}
    </div>
  );
};

export default Beacons;
