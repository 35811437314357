import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { GeoJsonLayer } from "@deck.gl/layers";
import { DeckGlOverlay } from "../../../utils/deckgl-overlay";
import { getServiceColorRgb, hexToRgb } from "../../../utils/common";

function getDeckGlLayers(layerData, colorsData, isLayerVisible, setHoverInfo, layerColor) {
  if (!layerData || !colorsData) return [];
  return [
    new GeoJsonLayer({
      id: "geojson-devices-route",
      data: layerData,
      stroked: true,
      filled: true,
      getLineWidth: 0.2,
      opacity: 1,
      glOptions: {
        preserveDrawingBuffer: true
      },
      visible: isLayerVisible,
      getFillColor: (f) => {
        return layerColor ? layerColor : getServiceColorRgb(f?.properties?.serviceName);
      },
      getLineColor: (f) => {
          return  layerColor ? layerColor : hexToRgb(colorsData[f?.properties?.beacon_type]);
      },
      lineWidthMinPixels: 2,
      pickable: true,
      onHover: (info, event) => {
        if (info && info.object) {
          setHoverInfo(info);
        } else {
          setHoverInfo(null);
        }
      },
    }),
  ];
}

const BeaconBasedRouteLayer = ({ layerData, colorsData, setHoverInfo,isLayerVisible, layerColor}) => {
  const [isVisible, setIsVisible] = useState(true);

  // this visible functionality is added due to webgl context lost issue
  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsVisible(!document.hidden);
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return (
    <DeckGlOverlay
      layers={getDeckGlLayers(
        layerData, colorsData, isLayerVisible,setHoverInfo, layerColor
      )}
      pickable={true}
      isVisible={isVisible}
    />
  );
};

export default BeaconBasedRouteLayer;

