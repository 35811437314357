import { APIProvider } from "@vis.gl/react-google-maps";
import React, { useEffect, useState } from "react";
import UploadKmlGoogleMap from "./UploadKmlGoogleMap";
import { Typography } from "@mui/material";
import UploadKmlModal from "../../Modals/UploadKmlModal/UploadKmlModal";
import { useGetIntelLocationsQuery} from "../../services/Properties";
import AddLocationGroup from "../../Modals/AddLocationGroup/AddLocationGroup";

const UploadKmlScreen = () => {
  const [isUploadKmlDrawerOpen, setIsUploadKmlDrawerOpen] = useState(false);
  const [isAddLocationDrawerOpen, setIsAddLocationDrawerOpen] = useState(false);
  const [locationGroups, setLocationGroups] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  const { data: locationData } = useGetIntelLocationsQuery(user?.organization);

  const handlePathOfMotionClick = () => {
    setIsUploadKmlDrawerOpen(true);
  };
  useEffect(() => {
    if (locationData) {
      setLocationGroups(locationData?.results);
    }
  }, [locationData]);
  return (
    <div className="mapPanelContainer">
      <div className="mapboxMapContainer">
        <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY}>
          <UploadKmlGoogleMap />
        </APIProvider>
        <button className="viewMapControls" onClick={handlePathOfMotionClick}>
          <Typography>Upload Kml</Typography>{" "}
        </button>
        <button
          className="addLocationGroup"
          onClick={() => setIsAddLocationDrawerOpen(true)}
        >
          <Typography>Add Location Group</Typography>{" "}
        </button>
        <UploadKmlModal
          isOpen={isUploadKmlDrawerOpen}
          setIsOpen={setIsUploadKmlDrawerOpen}
          locationGroups={locationGroups}
        />
        <AddLocationGroup
          isOpen={isAddLocationDrawerOpen}
          setIsOpen={setIsAddLocationDrawerOpen}
        />
      </div>
    </div>
  );
};

export default UploadKmlScreen;
