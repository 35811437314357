import { InfoWindow, useMap } from "@vis.gl/react-google-maps";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import Pin from "../../components/Mapbox/Pin";
import { Marker } from "./Marker";
import { Typography } from "@mui/material";
import { convertTimestampToFormattedDateEpoch } from "../../utils/common";
import { useNavigate } from "react-router-dom";
/**
 * The ClusteredDeviceMarkers component is responsible for integrating the
 * markers with the markerclusterer.
 */
export const ClusteredDeviceMarkers = ({ devices, setDevices }) => {
  const navigate = useNavigate()
  const [markers, setMarkers] = useState({});
  const [selectedDeviceId, setSelectedDeviceId] = useState(null);
  const selectedDevice = useMemo(
    () =>
      devices && selectedDeviceId
        ? devices.find((t) => {
            return t.device_id == selectedDeviceId;
          })
        : null,
    [devices, selectedDeviceId]
  );

  // create the markerClusterer once the map is available and update it when
  // the markers are changed
  const map = useMap();
  const clusterer = useMemo(() => {
    if (!map) return null;

    return new MarkerClusterer({ map });
  }, [map]);

  useEffect(() => {
    if (!clusterer) return;
    clusterer.clearMarkers();
    clusterer.addMarkers(Object.values(markers));
    // {zoomOnClick: false}
    // clusterer.
  }, [clusterer, markers]);

  // this callback will effectively get passsed as ref to the markers to keep
  // tracks of markers currently on the map
  const setMarkerRef = useCallback((marker, key) => {
    setMarkers((markers) => {
      if ((marker && markers[key]) || (!marker && !markers[key]))
        return markers;

      if (marker) {
        return { ...markers, [key]: marker };
      } else {
        const { [key]: _, ...newMarkers } = markers;

        return newMarkers;
      }
    });
  }, []);

  const handleInfoWindowClose = useCallback(() => {
    setSelectedDeviceId(null);
  }, []);

  const handleMarkerClick = useCallback((device) => {
    navigate(`/devices/${device?.device_id}`) 
    setDevices(null)
  }, []);

  const handleMouseOver = useCallback((device) => {
    setSelectedDeviceId(device?.device_id);
  }, []);

  const handleMouseOut = useCallback(() => {
    setSelectedDeviceId(null);
  }, []);

  return (
    <>
      {devices.map((device) => (
        <Marker
          key={device.device_id}
          device={device}
          onClick={handleMarkerClick}
          onMouseOver={() => handleMouseOver(device)} // Pass hover event
          onMouseOut={handleMouseOut}
          setMarkerRef={setMarkerRef}
        >
          <Pin text="E" color="#ff5e00"></Pin>
        </Marker>
      ))}

      {selectedDeviceId && (
        <InfoWindow
          anchor={markers[selectedDeviceId]}
          onCloseClick={handleInfoWindowClose}
        >
          <div className="hoveredRow">
            <Typography component={"span"} variant="body2" fontWeight={600}>
              Device Id :{" "}
            </Typography>
            <Typography component={"span"} variant="body2">
              {selectedDevice?.device_id}
            </Typography>
          </div>
          <div className="hoveredRow">
            <Typography component={"span"} variant="body2" fontWeight={600}>
              Active :{" "}
            </Typography>
            <Typography component={"span"} variant="body2">
              {selectedDevice?.is_active ? "Active" : "InActive"}
            </Typography>
          </div>
          <div className="hoveredRow">
            <Typography component={"span"} variant="body2" fontWeight={600}>
              Last Seen :{" "}
            </Typography>
            <Typography component={"span"} variant="body2">
              {convertTimestampToFormattedDateEpoch(selectedDevice?.last_seen)}
            </Typography>
          </div>
          <div className="hoveredRow">
            <Typography component={"span"} variant="body2" fontWeight={600}>
              Battery Level :{" "}
            </Typography>
            <Typography component={"span"} variant="body2">
              {selectedDevice?.battery_level}
            </Typography>
          </div>
        </InfoWindow>
      )}
    </>
  );
};
