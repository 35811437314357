export const defaultVars = {
  area: {
    name: "sqft",
    type: "sqft",
    maxDecimals: 2,
  },
  speed: {
    name: "mph",
    type: "mph",
    maxDecimals: 2,
  },
  linear: {
    name: "sqm",
    type: "sqm",
    maxDecimals: 2,
  },
  production: {
    name: "sqm",
    type: "sqm",
    maxDecimals: 2,
  },
};

export const LayerColors = {
  idle: {
    rgb: [75, 0, 130],
    hex: "#4B0082",
  },
  transit: {
    rgb: [255, 140, 0],
    hex: "#FF8C00",
  },
  missing: {
    rgb: [128, 0, 0],
    hex: "#800000",
  },
};
