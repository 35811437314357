import React, { useEffect, useState } from "react";
import { PathLayer } from "@deck.gl/layers";
import { DeckGlOverlay } from "../../../utils/deckgl-overlay";
import { hexToRgb } from "../../../utils/common";
import { PathStyleExtension } from "@deck.gl/extensions";
import { useSelector } from "react-redux";

function getDeckGlLayers(lineData, devicesColor,isSummary) {
  if (!lineData) return [];
  return [
    new PathLayer({
      id: "dashed-path-layer",
      data: lineData,
      getPath: (d) => [d.source, d.target], // Adjust according to your data structure
      getColor: (d) => hexToRgb(devicesColor[d.deviceId]),
      getWidth: 0.2,
      getDashArray: [5, 5],
      extensions: [new PathStyleExtension({ dash: true })],
      dashJustified: true,
      visible: isSummary ? false : true,
      dashGapPickable: true,
      widthMinPixels: 2,
      pickable: false,
    }),
  ];
}

const PathOfMotionServiceLayers = ({ layerData,isSummary}) => {
  const [isVisible, setIsVisible] = useState(true);

  const devicesColor = useSelector((state) => state.gisDashboard.devicesColor);
  // this visible functionality is added due to webgl context lost issue
  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsVisible(!document.hidden);
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return (
    <DeckGlOverlay
      layers={getDeckGlLayers(layerData, devicesColor,isSummary)}
      isVisible={isVisible}
    />
  );
};

export default PathOfMotionServiceLayers;
