import React, { useRef } from "react";
import { useMap } from "@vis.gl/react-google-maps";

import { useDrawingManagerEvents, useOverlaySnapshots } from "./undoRedo";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationModal from "../../Modals/ConfirmationModal/ConfirmationModal";

export const UndoRedoControl = ({
  drawingManager,
  readOnly,
  parcelGeoJSON,
}) => {
  const map = useMap();
  const dispatch = useDispatch();
  const parcelData = useSelector((state) => state.drawing.parcelData);

  const overlaysShouldUpdateRef = useRef;
  const {
    modalOpen,
    setModalOpen,
    handleConfirm,
    handleClose,
    pendingPolygon,
  } = useDrawingManagerEvents(
    drawingManager,
    overlaysShouldUpdateRef,
    dispatch,
    parcelGeoJSON,
    readOnly
  );
  useOverlaySnapshots(map, parcelData, overlaysShouldUpdateRef);
  return (
    <ConfirmationModal
      isOpen={modalOpen}
      setIsOpen={setModalOpen}
      body="Areas are overlapping. Do you still want to continue?"
      PrimaryButtonTitle="Continue"
      secondaryButtonClick={handleClose}
      handleOk={handleConfirm}
    />
  );
};
