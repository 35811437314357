import React, { useState, useRef, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useMapsLibrary } from '@vis.gl/react-google-maps';
import { Paper } from '@mui/material';

const SearchBar = ({ onPlaceSelected }) => {
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const autocompleteService = useRef(null);
  const mapsApi = useMapsLibrary('places'); // Load the Google Maps API with the 'places' library

  useEffect(() => {
    if (mapsApi) {
      autocompleteService.current = new mapsApi.AutocompleteService();
    }
  }, [mapsApi]);

  const handleInputChange = (event, value) => {
    setInputValue(value || '');
    if (value && autocompleteService.current) {
      autocompleteService.current.getPlacePredictions({ input: value }, (predictions) => {
        if (predictions) {
          setOptions(predictions.map(prediction => ({
            description: prediction.description,
            placeId: prediction.place_id,
          })));
        }
      });
    } else {
      setOptions([]);
    }
  };

  const handleOptionSelected = (event, value) => {
    if (value && mapsApi) {
      const placesService = new mapsApi.PlacesService(document.createElement('div'));
      placesService.getDetails({ placeId: value.placeId }, (place, status) => {
        if (status === mapsApi.PlacesServiceStatus.OK && place.geometry) {
          onPlaceSelected(place);
        }
      });
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  return (
    <Autocomplete
      freeSolo
      inputValue={inputValue}
      onInputChange={handleInputChange}
      options={options}
      getOptionLabel={(option) => option?.description || ''}
      PaperComponent={({ children }) => <Paper style={{ background: 'white' }}>{children}</Paper>}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search for property"
          variant="filled"
          fullWidth
          onKeyDown={handleKeyDown}
          sx={{
            background: 'white',
            borderRadius: 3,
            '& .MuiOutlinedInput-root': {
              fontSize: '1.2rem',
            },
            '& .MuiInputLabel-root': {
              fontSize: '1.2rem',
            },
          }}
        />
      )}
      onChange={handleOptionSelected}
    />
  );
};

export default SearchBar;
