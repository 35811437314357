import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs";
import { Box, Divider, Grid, Skeleton, Stack } from "@mui/material";
import NestedFolderList from "./NestedFolderList";
import FolderList from "./FolderList";
import { setLocationGroupData } from "../../../slices/workPlanSlice";
import { useGetPlannerLocationGroupsMutation } from "../../../services/Properties";
import { toast } from "react-toastify";

const SavedPlans = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const locationGroupData = useSelector(
    (state) => state.workPlan.locationGroupData
  );
  const dispatch = useDispatch();

  const [getPlannerLocationGroups, { isLoading }] = useGetPlannerLocationGroupsMutation();
  const [currentPath, setCurrentPath] = useState("");
  const [showLoader, setShowLoader] = useState(true);

  const handleFolderClick = (path) => {
    setCurrentPath(path);
  };

  const handleBreadcrumbClick = (path) => {
    setCurrentPath(path);
  };

  useEffect(() => {
    (async () => {
      try {
        const locationData = await getPlannerLocationGroups(
          user?.organization
        ).unwrap();
        dispatch(setLocationGroupData(locationData?.results));
        localStorage.setItem(
          "locationGroups",
          JSON.stringify(locationData?.results)
        );
        setShowLoader(false);
      } catch (error) {
        setShowLoader(false);
        toast.error(error?.data?.detail, {
          autoClose: 2000,
          hideProgressBar: true,
        });
      }
    })();
  }, []);

  if (isLoading || showLoader) {
    return (
      <>
        <Grid container marginTop="1px" spacing={2}>
          <Grid item xs={12}>
            <Skeleton variant="text" width={200} height={40} />
          </Grid>
          {[...Array(12)].map((_, index) => (
            <Grid item xs={12} sm={6} md={4} lg={2} key={index}>
              <Skeleton
                variant="rectangular"
                width="100%"
                height={74}
                sx={{ borderRadius: "4px" }}
              />
            </Grid>
          ))}
        </Grid>
      </>
    );
  }
  return (
    <div>
      {locationGroupData && locationGroupData.length > 0 && (
        <Box my={2}>
          <Breadcrumbs
            path={currentPath}
            onBreadcrumbClick={handleBreadcrumbClick}
          />
          <Divider />
          {currentPath === "" ? (
            <FolderList onFolderClick={handleFolderClick} />
          ) : (
            <NestedFolderList
              folderPath={currentPath}
              onFolderClick={handleFolderClick}
            />
          )}
          <></>
        </Box> )}
    </div>
  );
};

export default SavedPlans;
