import React, { useCallback } from "react";
import { AdvancedMarker } from "@vis.gl/react-google-maps";
import LawnMower from "../../Svg/LawnMower";

export const Marker = (props) => {
  const { device, onClick, setMarkerRef, onMouseOver,  onMouseOut} = props;

  const handleClick = useCallback(() => onClick(device), [onClick, device]);
  const ref = useCallback(
    (marker) => setMarkerRef(marker, device?.device_id),
    [setMarkerRef, device.device_id]
  );

  return (
    <AdvancedMarker
      position={{
        lat: device?.last_gps?.coordinates[1],
        lng: device?.last_gps?.coordinates[0],
      }}
      ref={ref}
      onClick={handleClick} 
    >
      <span className="clusterMarker" onMouseOver={onMouseOver} onMouseOut={onMouseOut} >
        
      {/* <LawnMower /> */}
      <img src="/images/15.png" width="40px" height="40px" />

        </span>
    </AdvancedMarker>
  );
};
