import React, { useEffect, useState } from 'react'
import { DeckGlOverlay } from "../../../utils/deckgl-overlay";
import { GeoJsonLayer } from "@deck.gl/layers";
import { getServiceColorRgb } from "../../../utils/common";
import useViewportWidth from '../../../Hooks/useViewportWidth';

function getDeckGlLayers(data) {
  if (!data) return [];

  return [
    new GeoJsonLayer({
      id: "timelineHoveredGeoJsonLayer",
      data: data,
      stroked: true,
      filled: true,
      getLineWidth: 1,
      opacity: 1,
      getFillColor: (f) => {
        return f?.properties?.serviceType == 'obstacle' ? [0,0,0,120] : getServiceColorRgb(f?.properties?.serviceType)
      },
      getLineColor: (f) => getServiceColorRgb(f?.properties?.serviceType),
      pickable: false
    }),
  ];
}
const TimelineHoveredPolygonLayer = ({hoveredGeoJson}) => {
  const [isVisible, setIsVisible] = useState(true);
  const width = useViewportWidth();

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsVisible(!document.hidden);
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);
  return (
    <DeckGlOverlay
      layers={getDeckGlLayers(hoveredGeoJson)}
      typedArrayManagerProps = {width < 800 ? { overAlloc: 1, poolSize: 0 } : null}
      isVisible={isVisible}
    />
  )
}

export default TimelineHoveredPolygonLayer