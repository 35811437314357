import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Divider,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { convertProductionUnitRate, convertTransitionPace } from "../../utils/common";

const AddServicesAndEquipmentModal = ({
  isOpen,
  setIsOpen,
  UpdateServiceData,
  editData,
  skillAndEquipmentDropdown,
}) => {
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  const [formData, setFormData] = useState(null);

  const handleCancel = () => {
    setIsOpen(false);
    reset();
  };

  useEffect(() => {
    if (editData) {
      reset(editData);
      setFormData(editData);
    } else {
      reset();
      setFormData(null);
    }
  }, [editData, isOpen, reset]);

  const handleAddOrUpdateCrewEquipment = (data) => {
    if (Object.keys(errors).length === 0) {
      UpdateServiceData(data);
      setIsOpen(false);
    }
  };

  const currentService = skillAndEquipmentDropdown?.find(
    (data) => data?.name === editData?.name
  );

  const handleSkillChange = (event) => {
    const selectedSkill = event.target.value;
    setValue("serviceSkill.skillName", selectedSkill);
  };

  const handleEquipmentChange = (index, value) => {
    const selectedEquipment = currentService?.equipment?.find(
      (equipment) => equipment.equipmentName === value
    );
    const updatedEquipment = [...formData.serviceEquipment];
    updatedEquipment[index] = selectedEquipment;
    setFormData((prevData) => ({
      ...prevData,
      serviceEquipment: updatedEquipment,
    }));
    setValue(`serviceEquipment.${index}`, selectedEquipment);
  };
  const handleProductionRateUnitChange = (index, field, value) => {
    const updatedEquipment = [...formData.serviceEquipment];
    const productionRate = convertProductionUnitRate(
      updatedEquipment[index]?.productionRate,
      updatedEquipment[index]?.productionRateUnit,
      value
    );

    updatedEquipment[index] = {
      ...updatedEquipment[index],
      productionRate: productionRate,
      [field]: value,
    };

    setFormData((prevData) => ({
      ...prevData,
      serviceEquipment: updatedEquipment,
    }));
    setValue(`serviceEquipment.${index}.${field}`, value);
    setValue(`serviceEquipment.${index}.productionRate`, parseFloat(productionRate.toFixed(2)));
  };
  const handleTransitionPaceUnitChange = (index, field, value) => {
    const updatedEquipment = [...formData.serviceEquipment];

    const transitionPace = convertTransitionPace(
      updatedEquipment[index]?.transitionPace,
      updatedEquipment[index]?.transitionPaceUnit,
      value
    );

    updatedEquipment[index] = {
      ...updatedEquipment[index],
      transitionPace: transitionPace,
      [field]: value,
    };

    setFormData((prevData) => ({
      ...prevData,
      serviceEquipment: updatedEquipment,
    }));
    setValue(`serviceEquipment.${index}.${field}`, value);
    setValue(`serviceEquipment.${index}.transitionPace`, parseFloat(transitionPace.toFixed(2)));
  };

  const handleFieldChange = (index, field, value) => {
    const updatedEquipment = [...formData.serviceEquipment];
    updatedEquipment[index] = {
      ...updatedEquipment[index],
      [field]: parseFloat(value),
    };
    setFormData((prevData) => ({
      ...prevData,
      serviceEquipment: updatedEquipment,
    }));
    setValue(`serviceEquipment.${index}.${field}`, parseFloat(value));
  };

  const handleAddEquipment = () => {
    if (formData.serviceEquipment.length < 2) {
      const newEquipment = { ...formData.serviceEquipment[0] }; // Clone the first equipment
      setFormData((prevData) => ({
        ...prevData,
        serviceEquipment: [...prevData.serviceEquipment, newEquipment],
      }));
      setValue(
        `serviceEquipment.${formData.serviceEquipment.length}`,
        newEquipment
      );
    }
  };

  const handleRemoveEquipment = (index) => {
    const updatedEquipment = formData.serviceEquipment.filter(
      (_, i) => i !== index
    );
    setFormData((prevData) => ({
      ...prevData,
      serviceEquipment: updatedEquipment,
    }));
    setValue("serviceEquipment", updatedEquipment);
  };

  return (
    <Dialog open={isOpen} maxWidth="m">
      <DialogTitle fontWeight={700} fontSize="24px" align="left">
        {editData ? "Update Service" : "Add Service"}
      </DialogTitle>
      <Divider />
      <div className="dialogContainer">
        <form onSubmit={handleSubmit(handleAddOrUpdateCrewEquipment)}>
          <div className="nameSkillsContainer">
            <div>
              <div className="serviceSubTitle">Skill</div>
              <Controller
                name="serviceSkill.skillName"
                control={control}
                defaultValue={formData?.serviceSkill?.skillName || ""}
                render={({ field }) => (
                  <Select
                    {...field}
                    size="small"
                    fullWidth
                    margin="normal"
                    onChange={(event) => handleSkillChange(event)}
                  >
                    <MenuItem value={currentService?.skill?.skillName}>
                      {currentService?.skill?.skillName}
                    </MenuItem>
                  </Select>
                )}
              />
              <div className="serviceSubTitle1">Equipment</div>
              {formData?.serviceEquipment?.map((equipment, index) => (
                <>
                  <div key={index} className="equipmentDataContainer">
                    <div className="equipmentDataLeft">
                      <div className="serviceSubTitle">Name</div>
                      <Controller
                        name={`serviceEquipment.${index}.equipmentName`}
                        control={control}
                        defaultValue={equipment?.equipmentName || ""}
                        render={({ field }) => (
                          <Select
                            {...field}
                            size="small"
                            fullWidth
                            margin="normal"
                            onChange={(event) =>
                              handleEquipmentChange(index, event.target.value)
                            }
                          >
                            {currentService?.equipment?.map((equipment) => (
                              <MenuItem
                                key={equipment.equipmentName}
                                value={equipment.equipmentName}
                              >
                                {equipment.equipmentName}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </div>
                    <div className="equipmentDataRight">
                      <div className="serviceSubTitle">Production Rate</div>
                      <div className="serviceInputContainer">
                        <Controller
                          name={`serviceEquipment.${index}.productionRate`}
                          control={control}
                          defaultValue={equipment?.productionRate || ""}
                          rules={{
                            required: "Production Rate is required",
                            min: {
                              value: 0,
                              message: "Minimum value should be 0",
                            },
                          }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              required
                              size="small"
                              type="number"
                              error={
                                !!errors?.serviceEquipment?.[index]
                                  ?.productionRate
                              }
                              helperText={
                                errors?.serviceEquipment?.[index]
                                  ?.productionRate
                                  ? errors.serviceEquipment[index]
                                      .productionRate.message
                                  : ""
                              }
                              InputProps={{
                                inputProps: {
                                  min: 0,
                                  step: 0.00000000000000000001,
                                },
                              }}
                              onChange={(event) =>
                                handleFieldChange(
                                  index,
                                  "productionRate",
                                  event.target.value
                                )
                              }
                              s
                            />
                          )}
                        />

                        <Controller
                          name={`serviceEquipment.${index}.productionRateUnit`}
                          control={control}
                          defaultValue={equipment?.productionRateUnit || ""}
                          render={({ field }) => (
                            <Select
                              {...field}
                              size="small"
                              sx={{ width: "120px" }}
                              margin="normal"
                              onChange={(event) =>
                                handleProductionRateUnitChange(
                                  index,
                                  "productionRateUnit",
                                  event.target.value
                                )
                              }
                            >
                              {currentService?.serviceProductionUnits?.map(
                                (unit) => (
                                  <MenuItem
                                    key={unit?.value}
                                    value={unit?.value}
                                  >
                                    {unit?.name}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          )}
                        />
                      </div>
                    </div>
                    <div className="equipmentDataRight">
                      <div className="serviceSubTitle">Top Speed</div>
                      <div className="serviceInputContainer">
                        <Controller
                          name={`serviceEquipment.${index}.transitionPace`}
                          control={control}
                          defaultValue={equipment?.transitionPace || ""}
                          rules={{
                            required: "Top Speed is required",
                            min: {
                              value: 1,
                              message: "Minimum value should be 1",
                            },
                          }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              required
                              size="small"
                              type="number"
                              error={
                                !!errors?.serviceEquipment?.[index]
                                  ?.transitionPace
                              }
                              helperText={
                                errors?.serviceEquipment?.[index]
                                  ?.transitionPace
                                  ? errors.serviceEquipment[index]
                                      .transitionPace.message
                                  : ""
                              }
                              InputProps={{
                                inputProps: {
                                  min: 1,
                                  step: 0.00000000000000000001,
                                },
                              }}
                              onChange={(event) =>
                                handleFieldChange(
                                  index,
                                  "transitionPace",
                                  event.target.value
                                )
                              }
                            />
                          )}
                        />
                        <Controller
                          name={`serviceEquipment.${index}.transitionPaceUnit`}
                          control={control}
                          defaultValue={equipment?.transitionPaceUnit || ""}
                          render={({ field }) => (
                            <Select
                              {...field}
                              size="small"
                              sx={{ width: "120px" }}
                              margin="normal"
                              onChange={(event) =>
                                handleTransitionPaceUnitChange(
                                  index,
                                  "transitionPaceUnit",
                                  event.target.value
                                )
                              }
                            >
                              {currentService?.serviceTransitionPaceUnits?.map(
                                (unit) => (
                                  <MenuItem
                                    key={unit?.value}
                                    value={unit?.value}
                                  >
                                    {unit?.name}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  {index > 0 && (
                    <Button onClick={() => handleRemoveEquipment(index)}>
                      Remove
                    </Button>
                  )}
                </>
              ))}
              {formData?.name === "Turf" &&
                formData.serviceEquipment.length < 2 && (
                  <Button onClick={handleAddEquipment}>Add Equipment</Button>
                )}
            </div>
          </div>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              justifyContent: "flex-end",
              mt: 2,
            }}
          >
            <Button variant="outlined" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={Object.keys(errors).length > 0}
            >
              {editData ? "Update" : "Add"}
            </Button>
          </Box>
        </form>
      </div>
    </Dialog>
  );
};

export default AddServicesAndEquipmentModal;
