import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRefreshTokenMutation } from "../services/Auth";
import {
  reset as resetGlobal,
  setRefreshTokenTimer,
  setToken,
} from "../slices/globalSlice";
import { getTokenExpiry } from "./common";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { reset as resetWorkPlan } from "../slices/workPlanSlice";
import { reset as resetGISDashboard } from "../slices/gisDashboardSlice";

export const useTokenRefresh = () => {
  const dispatch = useDispatch();
  const [refreshToken] = useRefreshTokenMutation();
  const isLogin = useSelector((state) => state.global.isLogin);
  const refreshTokenTimer = useSelector(
    (state) => state.global.refreshTokenTimer
  );
  const navigate = useNavigate();

  useEffect(() => {
    let inactivityTimeout;
    const handleActivity = () => {
      // Reset the inactivity timeout
      if (inactivityTimeout) clearTimeout(inactivityTimeout);
      inactivityTimeout = setTimeout(() => {
        dispatch(resetGlobal());
        dispatch(resetGISDashboard());
        dispatch(resetWorkPlan());
        localStorage.clear();
        toast.error("Session Timeout", {
          autoClose: 1000,
          hideProgressBar: true,
        });
        navigate("/login");
      }, 60 * 60 *1000); // 1 hour of inactivity
    };

    const trackActivity = () => {
      window.addEventListener("mousemove", handleActivity);
      window.addEventListener("keydown", handleActivity);
      window.addEventListener("scroll", handleActivity);
    };

    const clearActivityTracking = () => {
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keydown", handleActivity);
      window.removeEventListener("scroll", handleActivity);
    };

    let token = localStorage.getItem("accessToken");

    if (isLogin) {
      const refresh = async () => {
        let token = localStorage.getItem("accessToken");
        try {
          // Call the refresh token mutation
          const { data } = await refreshToken({ token: token });
          // Dispatch action to handle the new token
          if (data.token) {
            dispatch(setToken(data?.token));
            startTimer(data?.token);
            localStorage.setItem("accessToken", data.token);
            handleActivity(); // Reset inactivity timer on token refresh
          } else {
            dispatch(resetGlobal());
            dispatch(resetGISDashboard());
            dispatch(resetWorkPlan());
            localStorage.clear();
            navigate("/login");
          }
        } catch (error) {
          dispatch(resetGlobal());
          dispatch(resetGISDashboard());
          dispatch(resetWorkPlan());
          localStorage.clear();
          navigate("/login");
        }
      };

      // Schedule token refresh
      const startTimer = (token) => {
        const expiryTime = getTokenExpiry(token); // Assuming you have a function to get token expiry
        if (!expiryTime) {
          dispatch(resetGlobal());
          dispatch(resetGISDashboard());
          dispatch(resetWorkPlan());
          toast.error("Session Timeout", {
            autoClose: 1000,
            hideProgressBar: true,
          });
          localStorage.clear();
          navigate("/login");
        }
        const currentTime = Date.now();
        const refreshMargin = 5 * 60 * 1000; // 5 minutes before expiry
        const delay = expiryTime - currentTime - refreshMargin;
        const newTimer = setTimeout(refresh, delay);
        dispatch(setRefreshTokenTimer(newTimer)); // Update the timer state
      };

      if (!refreshTokenTimer) {
        startTimer(token);
      }

      // Start tracking activity
      handleActivity();
      trackActivity();

      return () => {
        clearTimeout(refreshTokenTimer); // Cleanup on unmount or dependency change
        clearTimeout(inactivityTimeout);
        clearActivityTracking();
      };
    }
  }, [dispatch, refreshToken, isLogin, refreshTokenTimer, navigate]);

  return null; // This hook does not render anything
};
