import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  locationGroupData:null,
  workerPlanFolderData:null,
  selectedLocation: "",
  selectedServiceType:"",
  isLocationSet:false,
};

const workPlanSlice = createSlice({
  name: "workPlan",
  initialState,
  reducers: {
    reset: () => initialState,
    setLocationGroupData: (state, action) => {
      return {
        ...state,
        locationGroupData: action.payload,
      };
    },
    setWorkerPlanFolderData: (state, action) => {
      return {
        ...state,
        workerPlanFolderData: action.payload,
      };
    },
    setSelectedLocation: (state, action) => {
      return {
        ...state,
        selectedLocation: action.payload,
      };
    },
    setSelectedServiceType: (state, action) => {
      return {
        ...state,
        selectedServiceType: action.payload,
      };
    },
    setIsLocationSet: (state, action) => {
      return {
        ...state,
        isLocationSet: action.payload,
      };
    }
  },
});

export const {
  reset,
  setLocationGroupData,
  setWorkerPlanFolderData,
  setSelectedLocation,
  setSelectedServiceType,
  setIsLocationSet,
} = workPlanSlice.actions;

export default workPlanSlice.reducer;
