import React, { useState } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  styled,
  tableCellClasses,
} from "@mui/material";
import {
  useDeleteOrderMutation,
  useGetOrderQuery,
} from "../../../services/OpenApi";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";
import { MdAdd, MdDelete, MdEdit } from "react-icons/md";
import { getCustomColor } from "../../../utils/theme";
import { IoMdEye } from "react-icons/io";
import ConfirmationModal from "../../../Modals/ConfirmationModal/ConfirmationModal";
import { useNavigate } from "react-router-dom";
import PlansModal from "../../../Modals/PlansModal/PlansModal";
import { formatReadableDate } from "../../../utils/common";

const StyledTableCell = styled(TableCell)(({ theme, width }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    fontWeight: 600,
    fontSize: "16px",
    width: width,
  },
  [`&.${tableCellClasses.body}`]: {
    color: theme.palette.custom.blackShade1.light,
    fontWeight: 600,
    fontSize: "13px",
    width: width,
  },
  whiteSpace: "nowrap",
}));

const ParcelTable = () => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const blackShade1 = getCustomColor("blackShade1");
  const [deleteId, setDeleteId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedParcelId, setSelectedParcelId] = useState(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const { data: orderData, isLoading } = useGetOrderQuery({
    orgName: user?.organization,
  });
  const [deleteOrder] = useDeleteOrderMutation();
  const [showLoader, setShowLoader] = useState(true);
  const [anchorEls, setAnchorEls] = useState({});

  const handleAddressClick = (id) => {
    const baseUrl = window.location.origin;
    const newUrl = `${baseUrl}/parcel/${id}`;
    window.open(newUrl, "_blank");
  };

  const handleClick = (event, row) => {
    setAnchorEls((prev) => ({ ...prev, [row.id]: event.currentTarget }));
  };

  const handleClose = (rowId) => {
    setAnchorEls((prev) => ({ ...prev, [rowId]: null }));
  };

  const onPropertyDelete = (id) => {
    setIsConfirmationModalOpen(true);
    setDeleteId(id);
    handleClose(id);
  };

  const handleOk = async () => {
    try {
      const response = await deleteOrder({
        orderId: deleteId,
        orgName: user?.organization,
      });
      setIsConfirmationModalOpen(false);
    } catch (err) {
      setIsConfirmationModalOpen(false);
    }
  };

  const handleViewClick = (row) => {
    if (row?.status === "SUBMITTED") {
      navigate(`summary/${row.id}`);
    } else {
      setIsModalOpen(true);
      setSelectedParcelId(row.id);
    }
  };

  const planStatus = (status) => {
    switch (status) {
      case "SUBMITTED":
        return "Submitted";
      case "PLANNED":
        return "Planned";
      default:
        return "In Process";
    }
  };

  return orderData?.data?.length > 0 ? (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography
          component="h3"
          fontWeight="600"
          sx={{ color: blackShade1.main }}
          variant="h5"
        >
          Measured Properties
        </Typography>
        <SecondaryButton
          type="button"
          marginTop="15px"
          marginBottom="10px"
          align="right"
          onClick={() => window.open("/survey", "_blank")}
          sx={{ width: "100px" }}
          variant="contained"
          background="#E0FFC4"
          textcolor="#3F8203"
        >
          <MdAdd /> Add new property
        </SecondaryButton>
      </Box>
      <div className="parcelTableContainer">
        <TableContainer component={Paper}>
          <Table stickyHeader size="small" aria-label="sticky table">
            <TableHead>
              <TableRow>
                <StyledTableCell sx={{ maxWidth: 100 }}>
                  Site Name
                </StyledTableCell>
                <StyledTableCell
                  sx={{
                    maxWidth: 200,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                  align="center"
                >
                  Address
                </StyledTableCell>
                <StyledTableCell
                  sx={{
                    maxWidth: 150,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                  align="center"
                >
                  Created By
                </StyledTableCell>
                <StyledTableCell
                  sx={{
                    maxWidth: 150,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                  align="center"
                >
                  Created At
                </StyledTableCell>
                <StyledTableCell
                  sx={{
                    maxWidth: 50,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                  align="center"
                >
                  Status
                </StyledTableCell>
                <StyledTableCell align="center">Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orderData?.data?.length > 0 &&
                orderData?.data?.map((row) => (
                  <TableRow key={row.id}>
                    <StyledTableCell
                      sx={{
                        maxWidth: 100,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <Tooltip title={row.site}>
                        <span>{row.site}</span>
                      </Tooltip>
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        maxWidth: 200,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      align="center"
                    >
                      <Tooltip title={row.address}>
                        <span>{row.address}</span>
                      </Tooltip>
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        maxWidth: 150,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      align="center"
                    >
                      <Tooltip title={row?.createdBy}>{row?.createdBy}</Tooltip>
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        maxWidth: 150,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      align="center"
                    >
                      <Tooltip title={formatReadableDate(row?.createdAt)}>
                        {formatReadableDate(row?.createdAt)}
                      </Tooltip>
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{
                        color:
                          row?.status === "SUBMITTED" ? "#4CAF50" : "#FBC02D",
                        fontWeight: 700,
                        maxWidth: 50,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {planStatus(row?.status)}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <Box>
                        {row?.status === "SUBMITTED" ||
                        row?.status === "PLANNED" ? (
                          <div className="tableActions">
                            <Tooltip title="View">
                              <IoMdEye
                                onClick={() => {
                                  handleViewClick(row);
                                  handleClose(row.id);
                                }}
                                cursor={"pointer"}
                                fontSize={18}
                              />{" "}
                            </Tooltip>
                            <Tooltip title="Delete">
                              <MdDelete
                                cursor={"pointer"}
                                onClick={() => onPropertyDelete(row.id)}
                                fontSize={18}
                              />{" "}
                            </Tooltip>
                          </div>
                        ) : (
                          <div className="tableActions">
                            <Tooltip title="Edit">
                              <MdEdit
                                onClick={() => {
                                  handleAddressClick(row.id);
                                  handleClose(row.id);
                                }}
                                cursor={"pointer"}
                                fontSize={18}
                              />
                            </Tooltip>
                            <Tooltip title="Delete">
                              <MdDelete
                                cursor={"pointer"}
                                onClick={() => onPropertyDelete(row.id)}
                                fontSize={18}
                              />{" "}
                            </Tooltip>
                          </div>
                        )}
                      </Box>
                    </StyledTableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <ConfirmationModal
          isOpen={isConfirmationModalOpen}
          setIsOpen={setIsConfirmationModalOpen}
          body="Do you want to delete this property?"
          handleOk={handleOk}
        />
        {selectedParcelId && (
          <PlansModal
            isOpen={isModalOpen}
            setIsOpen={setIsModalOpen}
            parcelId={selectedParcelId}
            setParcelId={setSelectedParcelId}
            setSelectedParcelId={setSelectedParcelId}
          />
        )}
      </div>
    </>
  ) : (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        display: isLoading ? "none" : "flex",
      }}
      className="emptyProperties"
    >
      <img src="/images/emptyProperty.png" width="350px" height="340px" />
      <SecondaryButton
        type="button"
        align="right"
        onClick={() => window.open("/survey", "_blank")}
        sx={{
          width: "100px",
          marginBottom: "10px",
        }}
        variant="contained"
        background="#E0FFC4"
        textcolor="#3F8203"
      >
        <MdAdd /> Add new property
      </SecondaryButton>
    </Box>
  );
};

export default ParcelTable;
