import React, { useEffect, useState } from "react";
import "./Header.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  reset as resetGlobal,
  setAnimationData,
  setRefreshTokenTimer,
} from "../../slices/globalSlice";
import { reset as resetWorkPlan } from "../../slices/workPlanSlice";
import animate from "../../files/BDTechnologies-2-Service-ST.animation.json";
import { assignCrewMemberColorsToAnimation } from "../../utils/common";
import {
  Avatar,
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { MdLogout, MdOutlineChangeCircle, MdSettings } from "react-icons/md";
import { green } from "@mui/material/colors";
import ProductSelectionModal from "../../Modals/ProductSelectionModal/ProductSelectionModal";
import { reset as resetGISDashboard } from "../../slices/gisDashboardSlice";
import { Mixpanel } from "../../utils/mixPanel";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.global.user);
  const isAllProductsPermission = useSelector(
    (state) => state.global.isAllProductsPermission
  );
  const animationData = useSelector((state) => state.global.animationData);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isProductSelectModalOpen, setIsProductSelectModalOpen] =
    useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const animateDataToSet = animationData || animate;
    const animatedData = assignCrewMemberColorsToAnimation(animateDataToSet);
    dispatch(setAnimationData(animatedData));
  }, []);

  const handleLogOut = () => {
    handleClose();
    dispatch(resetGlobal());
    dispatch(resetWorkPlan());
    dispatch(resetGISDashboard());
    toast.success("logged out successfully", {
      autoClose: 1000,
      hideProgressBar: true,
    });
    localStorage.clear();
    Mixpanel.identify(user.email);
    Mixpanel.track(`Logout - ${user.email}`);
    navigate("/login");
    dispatch(setRefreshTokenTimer(null));
  };

  return (
    <Box className="headerContainer" backgroundColor={"primary.main"}>
      <div
        className="logoContainer"
        onClick={() => navigate("/", { replace: true })}
      >
        <img src={"/images/whiteLogo.png"} width="120px" height="25px" />
      </div>
      {user && (
        <div className="menuContainer">
          <Tooltip title="Account settings">
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <Avatar sx={{ width: 34, height: 34, bgcolor: green[300] }} />
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            disableScrollLock={true}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&::before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            {isAllProductsPermission && (
              <MenuItem onClick={() => setIsProductSelectModalOpen(true)}>
                <ListItemIcon>
                  <MdOutlineChangeCircle fontSize="large" />
                </ListItemIcon>
                Switch Service
              </MenuItem>
            )}
            <MenuItem onClick={handleLogOut}>
              <ListItemIcon>
                <MdLogout fontSize="large" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
          {isProductSelectModalOpen && (
            <ProductSelectionModal
              isOpen={isProductSelectModalOpen}
              setIsOpen={setIsProductSelectModalOpen}
            />
          )}
        </div>
      )}
    </Box>
  );
};

export default Header;
