import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  useAddCrewEquipmentDataMutation,
  useLazyGetCrewAvailableEquipmentDataQuery,
  useLazyGetCrewEquipmentDataQuery,
} from "../../../services/OpenApi";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { MdAdd, MdRemove } from "react-icons/md";

const crews = [
  {
    crewId: 1,
    crewEquipment: [],
  },
  {
    crewId: 2,
    crewEquipment: [],
  },
];

const initializeCrewsWithEquipment = (crews, equipment) => {
  return crews.map((crew) => ({
    ...crew,
    crewEquipment: crew?.crewEquipment?.length > 0 ? crew?.crewEquipment : equipment?.map((equip) => ({ ...equip, quantity: 1 })),
  }));
};

const CrewEquipmentForm = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [crewsData, setCrewsData] = useState([]);
  const user = useSelector((state) => state.global.user);
  const [getCrewEquipmentData] = useLazyGetCrewEquipmentDataQuery();
  const [getCrewAvailableEquipmentData] = useLazyGetCrewAvailableEquipmentDataQuery();
  const [addCrewEquipmentData, { isLoading }] = useAddCrewEquipmentDataMutation();

  const handleQuantityChange = (crewId, equipmentId, newQuantity) => {
    setCrewsData((prevState) =>
      prevState.map((crew) =>
        crew.crewId === crewId
          ? {
              ...crew,
              crewEquipment: crew.crewEquipment.map((equip) =>
                equip.equipmentId === equipmentId
                  ? { ...equip, quantity: Math.max(0, Math.min(10, newQuantity)) }
                  : equip
              ),
            }
          : crew
      )
    );
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await getCrewAvailableEquipmentData({
          orderId: params?.id,
          orgName: user?.organization,
        }).unwrap();
        const crewRes = await getCrewEquipmentData({
          orderId: params?.id,
          orgName: user?.organization,
        }).unwrap();
        setCrewsData(initializeCrewsWithEquipment(crewRes?.data, response?.data));
      } catch (err) {
        console.log("error", err);
      }
    })();
  }, []);

  const handleConfirm = async () => {
    try {
      const response = await addCrewEquipmentData({
        orderId: params?.id,
        orgName: user?.organization,
        content: crewsData,
      }).unwrap();
      if (response?.data) {
        toast.success("Equipments added successfully", {
          autoClose: 1000,
          hideProgressBar: true,
        });
        navigate(`${`/summary/${params?.id}`}`);
      } else if (response?.error) {
        toast.error(response?.error?.error || response?.error, {
          autoClose: 2000,
          hideProgressBar: true,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };  

  return (
    <div className="StepsFormContainer">
      <h3 className="StepsFormContainerHeading">Add Crew Equipments</h3>
      <Box p={2}>
        {crewsData.length > 0 &&
          crewsData.map((crew, index) => (
            <Box key={crew.crewId} mb={1} className="minMaxContainer">
              <Typography variant="h6">Crew {index + 1}</Typography>
              {crew.crewEquipment.map((equip) => (
                <Grid container key={equip.equipmentId} alignItems="center" mt={2}>
                  <Grid item xs={7}>
                    <Typography>{equip.equipmentName}</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      label="Quantity"
                      size="small"
                      value={equip.quantity}
                      inputProps={{ min: 1, max: 10, readOnly: true }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton
                              onClick={() =>
                                handleQuantityChange(
                                  crew.crewId,
                                  equip.equipmentId,
                                  equip.quantity - 1
                                )
                              }
                              disabled={equip.quantity <= 1}
                            >
                              <MdRemove fontSize={12}/>
                            </IconButton>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                handleQuantityChange(
                                  crew.crewId,
                                  equip.equipmentId,
                                  equip.quantity + 1
                                )
                              }
                              disabled={equip.quantity >= 10}
                            >
                              <MdAdd fontSize={12}/>
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              ))}
            </Box>
          ))}
      </Box>
      <Button
        type="submit"
        fullWidth
        disabled={isLoading}
        variant="contained"
        onClick={handleConfirm}
        sx={{ mt: 1, mb: 2 }}
      >
        {isLoading ? <CircularProgress size={24} /> : "Confirm"}
      </Button>
    </div>
  );
};

export default CrewEquipmentForm;
