import React from "react";
import GISDashboardMapScreen from "./GISDashboardMapScreen";

const GISDashboardApi = () => {
  return (
    <>
      <GISDashboardMapScreen />
    </>
  );
};

export default GISDashboardApi;
