import { useMap, useMapsLibrary } from '@vis.gl/react-google-maps';
import { useEffect, useState } from 'react';

export function useDrawingManager({initialValue,isVisible}) {
  const map = useMap();
  const drawing = useMapsLibrary('drawing');
  
  const [drawingManager, setDrawingManager] = useState(initialValue);

  useEffect(() => {
    if (!map || !drawing || !window.google) return;

    // Ensure google is defined
    const { google } = window;

    const newDrawingManager = new drawing.DrawingManager({
      map: isVisible ? map : null, // Only set the map if drawing manager is visible
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_LEFT,
        drawingModes: [
          google.maps.drawing.OverlayType.POLYGON,
        ],
      },
      markerOptions: {
        draggable: true,
      },
      polygonOptions: {
        editable: true,
        draggable: true,
        fillOpacity: 0.5,
      },
      polylineOptions: {
        editable: true,
        draggable: true,
      },
    });

    setDrawingManager(newDrawingManager);

    return () => {
      newDrawingManager.setMap(null);
    };
  }, [drawing, map, isVisible]);

  return drawingManager;
}
