import React, { useEffect, useState } from "react";
import { DeckGlOverlay } from "../../../utils/deckgl-overlay";
import { LineLayer, PathLayer } from "@deck.gl/layers";
import { PathStyleExtension } from "@deck.gl/extensions";
import { getServiceColorRgb, hexToRgb } from "../../../utils/common";
import { routeGeneration } from "../../../utils/routePlanFunction";
import useViewportWidth from "../../../Hooks/useViewportWidth";
function getDeckGlLayers(lineData, showConnectingLines) {
  if (!lineData || !showConnectingLines) return [];

  return [
    new PathLayer({
      id: "dashed-path-layer",
      data: lineData,
      getPath: (d) => [d.source, d.target], // Adjust according to your data structure
      getColor: (d) => d.color,
      getWidth: 0.2,
      getDashArray: [5, 5],
      extensions: [new PathStyleExtension({ dash: true })],
      dashJustified: true,
      dashGapPickable: true,
      widthMinPixels: 2,
      visible: showConnectingLines ? true : false,
      pickable: false,
    }),
  ];
}

const TransitionDottedLines = ({
  showConnectingLines,
  crewMemberDayWise,
  filteredGeoJson,
  lookUpData,
  selectedServiceIds,
}) => {
  const [lineData, setLineData] = useState([]);
  const [isVisible, setIsVisible] = useState(true);
  const width = useViewportWidth();

  // this visible functionality is added due to webgl context lost issue
  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsVisible(!document.hidden);
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  // data calculation for dotted lines

  useEffect(() => {
    if (crewMemberDayWise) {
      const transformedArray = [];

      Object.entries(crewMemberDayWise).forEach(([crewMemberId, crewData]) => {
        crewData.day.forEach((dayData) => {
          const filteredPolygonIds = dayData.polygon.filter((polygonId) => {
            const polygon = lookUpData.polygons[polygonId];
            return (
              polygon &&
              selectedServiceIds.includes(polygon.serviceId.toString())
            );
          });
          const routePlan = routeGeneration(
            filteredPolygonIds,
            dayData?.parkingId,
            lookUpData
          );
          routePlan.forEach((route) => {
            const entryPoint = route?.entryPoint;
            const exitPoint = route?.exitPoint;

            const transformedObject = {
              source: [entryPoint?.lon, entryPoint?.lat],
              target: [exitPoint?.lon, exitPoint?.lat],
              color: hexToRgb(crewData.crewMemberColor), // Converts HEX to RGB
            };

            transformedArray.push(transformedObject);
          });
        });
      });

      setLineData(transformedArray);
    }
  }, [crewMemberDayWise, filteredGeoJson]);
  return (
    <DeckGlOverlay
      layers={getDeckGlLayers(lineData, showConnectingLines)}
      typedArrayManagerProps={
        width < 800 ? { overAlloc: 1, poolSize: 0 } : null
      }
      isVisible={isVisible}
    />
  );
};

export default TransitionDottedLines;
