import React, { useEffect, useState } from "react";
import {
  ControlPosition,
  Map,
  useMap,
  useMapsLibrary,
} from "@vis.gl/react-google-maps";
import { useDispatch, useSelector } from "react-redux";
import { calculateBounds, calculateZoom } from "../../utils/mapZoomCalculation";
import { setIsStreetView } from "../../slices/globalSlice";
import BaseServiceLayers from "./Layers/BaseServiceLayers";
import CoveredServiceLayers from "./Layers/CoveredServiceLayers";
import CoveredServiceLayersDayWise from "./Layers/CoveredServiceLayersDayWise";
import PathOfMotionServiceLayers from "./Layers/PathOfMotionServiceLayers";
import PathOfMotionNumberPoints from "./Layers/PathOfMotionNumberPoints";

const GISDashboardGoogleMaps = () => {
  const [baseFilteredGeoJson, setBaseFilteredGeoJson] = useState(null);
  const [baseFilteredDetailsGeoJson, setBaseFilteredDetailsGeoJson] =
    useState(null);
  const [coveredFilteredGeoJson, setCoveredFilteredGeoJson] = useState(null);
  const [coveredFilteredDateWiseGeoJson, setCoveredFilteredDateWiseGeoJson] =
    useState(null);
  const isSummary = useSelector((state) => state.gisDashboard.isSummary);
  const dispatch = useDispatch();
  const selectedServiceForDetails = useSelector(
    (state) => state.gisDashboard.selectedServiceForDetails
  );
  const pathOfMotionData = useSelector(
    (state) => state.gisDashboard.pathOfMotionData
  );
  const baseLayersGeojson = useSelector(
    (state) => state.gisDashboard.baseLayersGeojson
  );
  const coveredLayersGeojson = useSelector(
    (state) => state.gisDashboard.coveredLayersGeojson
  );
  const coveredLayersDateWiseGeojson = useSelector(
    (state) => state.gisDashboard.coveredLayersDateWiseGeojson
  );
  const selectedGISService = useSelector(
    (state) => state.gisDashboard.selectedGISService
  );
  const selectedGISServiceDate = useSelector(
    (state) => state.gisDashboard.selectedGISServiceDate
  );
  const selectedGISServicePathOfMotion = useSelector(
    (state) => state.gisDashboard.selectedGISServicePathOfMotion
  );
  const selectedDevices = useSelector(
    (state) => state.gisDashboard.selectedDevices
  );
  const selectedDay = useSelector((state) => state.gisDashboard.selectedDay);

  const map = useMap();
  const mapsLibrary = useMapsLibrary("maps");
  const [viewport, setViewport] = useState({
    center: { lat: 40.7, lng: -74 },
    zoom: 12,
    width: 800,
    height: 400,
    bearing: 0,
    streetViewControl: true,
    fullscreenControl: false,
    fullscreenControlOptions: true,
  });

  // Update the viewport when mapData changes
  useEffect(() => {
    if (
      !baseLayersGeojson ||
      !baseLayersGeojson.features ||
      baseLayersGeojson.features.length === 0
    ) {
      return;
    }

    const bounds = calculateBounds(baseLayersGeojson.features);
    const zoom = calculateZoom(bounds, viewport?.map?.width, viewport?.map?.height);
    setViewport((prevViewport) => ({
      ...prevViewport,
      center: {
        lat: (bounds[1] + bounds[3]) / 2,
        lng: (bounds[0] + bounds[2]) / 2,
      },

      zoom: zoom || 18,
    }));
  }, [baseLayersGeojson]);
  useEffect(() => {
    if (
      !coveredLayersDateWiseGeojson ||
      !coveredLayersDateWiseGeojson.features ||
      coveredLayersDateWiseGeojson.features.length === 0
    ) {
      return;
    }
    const bounds = calculateBounds(coveredLayersDateWiseGeojson.features);
    const zoom = calculateZoom(bounds, viewport?.map?.width, viewport?.map?.height);

    setViewport((prevViewport) => ({
      ...prevViewport,
      center: {
        lat: (bounds[1] + bounds[3]) / 2,
        lng: (bounds[0] + bounds[2]) / 2,
      },

      zoom: zoom || 18,
    }));
  }, [coveredLayersDateWiseGeojson]);

  useEffect(() => {
    if (mapsLibrary && map) {
      setViewport((prev) => ({
        ...prev,
        mapTypeControlOptions: {
          style: mapsLibrary?.MapTypeControlStyle?.HORIZONTAL_BAR,
          position: 0,
        },
      }));
      const panorama = map?.getStreetView();
      panorama.addListener("visible_changed", () => {
        dispatch(setIsStreetView(panorama.getVisible()));
      });
    }
  }, [mapsLibrary, map]);

  useEffect(() => {
    if (selectedGISService && baseLayersGeojson) {
      const filteredFeatures = baseLayersGeojson.features.filter(
        (feature) => selectedGISService[feature?.properties?.location_key]
      );
      setBaseFilteredGeoJson({
        type: "FeatureCollection",
        features: filteredFeatures,
      });
    }
  }, [selectedGISService, baseLayersGeojson]);
  useEffect(() => {
    if (selectedGISService && baseLayersGeojson && selectedServiceForDetails) {
      const filteredFeatures = baseLayersGeojson.features.filter(
        (feature) =>
          selectedServiceForDetails?.location_key ===
          feature?.properties?.location_key
      );

      setBaseFilteredDetailsGeoJson({
        type: "FeatureCollection",
        features: filteredFeatures,
      });
    }
  }, [selectedGISService, baseLayersGeojson, selectedServiceForDetails]);

  useEffect(() => {
    if (selectedGISService && coveredLayersGeojson) {
      const filteredFeatures = coveredLayersGeojson?.features?.filter(
        (feature) => selectedGISService[feature?.properties?.location_key]
      );
      setCoveredFilteredGeoJson({
        type: "FeatureCollection",
        features: filteredFeatures,
      });
    }
  }, [selectedGISService, coveredLayersGeojson]);

  useEffect(() => {
    if (selectedGISServiceDate && coveredLayersDateWiseGeojson) {
      const filteredFeatures = coveredLayersDateWiseGeojson?.features?.filter(
        (feature) => selectedGISServiceDate[feature?.properties?.date]
      );
      setCoveredFilteredDateWiseGeoJson({
        type: "FeatureCollection",
        features: filteredFeatures,
      });
    }
  }, [selectedGISServiceDate, coveredLayersDateWiseGeojson]);

  // console.log("map id ==>> ",process.env.REACT_APP_GOOGLE_MAPS_ID)
  return (
    <div style={{ height: "92vh", width: "100%" }}>
      <Map
        {...viewport}
        zoom={viewport?.zoom}
        defaultZoom={viewport?.zoom}
        center={viewport?.center}
        defaultCenter={viewport?.center}
        onCameraChanged={(v) => setViewport(v)}
        gestureHandling={"greedy"}
        draggableCursor="default" // Change this to any cursor style you want when the map is idle
        draggingCursor="move"
        tiltInteractionEnabled={true}
        defaultTilt={0}
        // disableDefaultUI= {true}
        mapId={"84a4fccd359c6c70"}
        // mapId={"e29610dc72ad5f45"}
        // mapId={"24ece105f1731113"}
        defaultBounds={{
          south: 40.5,
          west: -74.2,
          north: 40.9,
          east: -73.8,
        }}
        mapTypeControlOptions={{
          style: mapsLibrary?.MapTypeControlStyle?.HORIZONTAL_BAR,
          position: ControlPosition.TOP_CENTER,
        }}
        stylers={true}
      >
        <BaseServiceLayers
          layerData={
            isSummary ? baseFilteredGeoJson : baseFilteredDetailsGeoJson
          }
          dimLayers={coveredFilteredGeoJson?.features?.length > 0 ? true : false}
        />
        <CoveredServiceLayers
          layerData={coveredFilteredGeoJson}
          isSummary={isSummary}
        />
        <CoveredServiceLayersDayWise
          layerData={coveredFilteredDateWiseGeoJson}
          isSummary={isSummary}
        />
        <PathOfMotionServiceLayers
          layerData={pathOfMotionData}
          isSummary={isSummary}
        />
          <PathOfMotionNumberPoints isSummary={isSummary} />
      </Map>
    </div>
  );
};

export default GISDashboardGoogleMaps;
