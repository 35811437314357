import React from "react";
import MapTimeline from "../../components/Visjs/MapTimeline";
import CrewMemberWiseTimeline from "../../components/Visjs/CrewMemberWiseTimeline";
import ServiceWiseTimeline from "../../components/Visjs/ServiceWiseTimeline";
import useViewportWidth from "../../Hooks/useViewportWidth";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";

const TimelineDrawer = ({
  isPlannerDrawerOpen,
  isTimelineDrawerOpen,
  setHoveredPolygon,
  drawerRef,
  tabValue,
}) => {
  const width = useViewportWidth();
  return (
    <Drawer
      open={isTimelineDrawerOpen}
      direction="bottom"
      className="insidedrawer"
      lockBackgroundScroll={false}
      enableOverlay={false}
      style={{
        height: "auto",
        left: isPlannerDrawerOpen && width > 768 ? "27vw" : 0,
        width: isPlannerDrawerOpen && width > 768 ? "73vw" : "100vw"
      }}
    >
      <div ref={drawerRef}>
        <div className="graphContainer">
          <div>
            {tabValue === 0 && (
              <MapTimeline
                onBarHover={(polygon) => setHoveredPolygon(polygon)}
                onBarLeave={() => setHoveredPolygon(null)}
              />
            )}
            {tabValue === 1 && <CrewMemberWiseTimeline />}
            {tabValue === 2 && <ServiceWiseTimeline />}
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default TimelineDrawer;
