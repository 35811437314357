import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React, { useEffect, useRef, useState } from "react";
import PropertyGanttChartDeviceWise from "./PropertyGanttChartDeviceWise";
import { useSelector } from "react-redux";
import PropertyGanttChartEquipmentWise from "./PropertyGanttChartEquipmentWise";
import {
  useLazyGetOrganizationDevicesRoutesTransitStartForGanttQuery,
  useLazyGetOrganizationDevicesRoutesTransitEndForGanttQuery,
  useLazyGetOrganizationDevicesRoutesMissingDataForGanttQuery,
  useLazyGetOrganizationDevicesRoutesIdleOverlapQuery
} from "../../../services/Properties";
import {
  formatTotalTime,
  getDateForApi,
  getDateFromCalender,
  parseTimeString,
} from "../../../utils/common";
import { getCustomColor } from "../../../utils/theme";
import EntryExitTable from "./EntryExitTable";
import ProductionTimeTable from "./ProductionTimeTable";
import { MdCloudDownload } from "react-icons/md";
import { Mixpanel } from "../../../utils/mixPanel";

const PropertySummary = () => {
  const blackShade1 = getCustomColor("blackShade1");
  const captureRef = useRef(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [transitGanttData, setTransitGanttData] = useState(null);
  const [idleGanttData, setIdleGanttData] = useState(null);
  const [missingGanttData, setMissingGanttData] = useState(null);

  const devicesEquipmentData = useSelector(
    (state) => state.gisDashboard.devicesEquipmentData
  );
  const boundingBoxForDevices = useSelector(
    (state) => state.gisDashboard.boundingBoxForDevices
  );
  const boundsForDevices = useSelector(
    (state) => state.gisDashboard.boundsForDevices
  );
  const devicesSummaryRef = useSelector(
    (state) => state.gisDashboard.devicesSummaryRef
  );
  const devicesMapRef = useSelector(
    (state) => state.gisDashboard.devicesMapRef
  );
  const user = JSON.parse(localStorage.getItem("user"));
  const selectedDevicesGeoJson = useSelector(
    (state) => state.gisDashboard.selectedDevicesGeoJson
  );
  const selectedDateForDevices = useSelector(
    (state) => state.gisDashboard.selectedDateForDevices
  );
  const devicesDashboardLocationValue = useSelector(
    (state) => state.gisDashboard.devicesDashboardLocationValue
  );
  const selectedDevicesDashboard = useSelector(
    (state) => state.gisDashboard.selectedDevicesDashboard
  );
  const [getOrganizationDevicesRoutesTransitEndForGantt] =
    useLazyGetOrganizationDevicesRoutesTransitEndForGanttQuery();
  const [getOrganizationDevicesRoutesTransitStartForGantt] =
    useLazyGetOrganizationDevicesRoutesTransitStartForGanttQuery();
  const [getOrganizationDevicesRoutesMissingDataForGantt] =
    useLazyGetOrganizationDevicesRoutesMissingDataForGanttQuery();
  const [getOrganizationDevicesRoutesIdleOverlap] =
  useLazyGetOrganizationDevicesRoutesIdleOverlapQuery();

  const handleOpenDialog = () => {
    Mixpanel.identify(user.email);
    Mixpanel.track(
      `Property summary - ${
        devicesDashboardLocationValue?.name
      } (${getDateFromCalender(selectedDateForDevices)})`
    );
    setIsDialogOpen(true);
  };
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    Mixpanel.identify(user.email);
    Mixpanel.track(
      `Closed Property summary - ${
        devicesDashboardLocationValue?.name
      } (${getDateFromCalender(selectedDateForDevices)})`
    );
  };

  const handleCaptureImage = () => {
    Mixpanel.identify(user.email);
    Mixpanel.track(
      `Saving Property summary - ${
        devicesDashboardLocationValue?.name
      } (${getDateFromCalender(selectedDateForDevices)})`
    );

    const element1 = captureRef?.current;
    // const element3 = devicesMapRef.current;
    const deckCanvas = document.querySelector("canvas");
    const element2 = document.querySelector(".summaryContainer");
    if (!element1 || !element2) {
      return;
    }

    // Capture the first element
    html2canvas(element1, {
      scale: 2, // Increase scale for higher quality
      scrollX: -window.scrollX,
      scrollY: -window.scrollY,
      windowHeight: document.documentElement.offsetHeight,
    }).then((canvas1) => {
      const imgData1 = canvas1.toDataURL("image/png");
      const imgWidth = 200; // A4 page wcaptureRefidth in mm
      const pdf = new jsPDF("p", "mm", "a4");
      const imgHeight1 = (canvas1.height * imgWidth) / canvas1.width + 10; // Keep the image aspect ratio
      const logoImg = new Image();
      logoImg.src = "/images/logo.png";

      const borderPadding = 3;
      const borderWidth = 204; // Adjust the width based on padding

      pdf.rect(borderPadding, 5, borderWidth, 280);
      logoImg.onload = () => {
        pdf.addImage(logoImg, "PNG", 8, 10, 40, 8); // x, y, width, height
  
        pdf.setFontSize(14);
        pdf.text("Property Summary", 8, 30); // Add some margin or space from top in this text
  
        pdf.addImage(imgData1, "PNG", 5, 35, imgWidth, imgHeight1);
  
        // Capture all child elements inside the summaryContainer
        const childElements = element2.children;
  
        // Create a promise array to capture all child elements
        const capturePromises = Array.from(childElements).map((childElement) =>
          html2canvas(childElement, {
            scale: 2,
            scrollX: -window.scrollX,
            scrollY: -window.scrollY,
            windowHeight: document.documentElement.offsetHeight,
          }).then((canvas) => {
            return canvas.toDataURL("image/png");
          })
        );
  
        // Wait for all captures to finish
        Promise.all(capturePromises).then((imgDataArray) => {
          const imagesPerRow = 2;
          const margin = 3; // Margin between images
          const startY = 40 + imgHeight1; // Start after the first image
          let currentY = startY;
          let currentX = 8;
  
          imgDataArray.forEach((imgData, index) => {
            // Calculate height keeping aspect ratio
            const imgHeight = 73; // Adjust height based on requirement
            const imgCanvasWidth = imgWidth / 2 - margin -2;
  
            pdf.addImage(imgData, "PNG", currentX, currentY, imgCanvasWidth, imgHeight);
  
            // Update position for next image
            if ((index + 1) % imagesPerRow === 0) {
              currentY += imgHeight + margin; // Move to the next row
              currentX = 8;
            } else {
              currentX += imgCanvasWidth + margin;
            }
          });
  
          // Save the PDF after all images have been added
          pdf.save("logo-content.pdf");
        });
      };
    });
  };

  const fetchTransitForEnd = async () => {
    try {
      const response = await getOrganizationDevicesRoutesTransitEndForGantt({
        orgName: user?.organization,
        location: `${devicesDashboardLocationValue?.key}`,
        date: getDateForApi(selectedDateForDevices),
        // deviceId: deviceId,
      });
      if (response?.data) {
        setTransitGanttData((prevData) => ({
          type: "FeatureCollection",
          features: prevData?.features
            ? [...prevData?.features, ...response?.data?.results?.features]
            : response?.data?.results?.features,
        }));
      }
    } catch (err) {
      console.log(err);
    }
  };
  const fetchTransitForStart = async () => {
    try {
      const response = await getOrganizationDevicesRoutesTransitStartForGantt({
        orgName: user?.organization,
        location: `${devicesDashboardLocationValue?.key}`,
        date: getDateForApi(selectedDateForDevices),
        // deviceId: deviceId,
      });
      if (response?.data) {
        setTransitGanttData((prevData) => ({
          type: "FeatureCollection",
          features: prevData?.features
            ? [...prevData?.features, ...response?.data?.results?.features]
            : response?.data?.results?.features,
        }));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchMissingData = async (url = null, accumulatedData = []) => {
    try {
      const response = await getOrganizationDevicesRoutesMissingDataForGantt({
        orgName: user?.organization,
        date: getDateForApi(selectedDateForDevices),
        bbox: boundingBoxForDevices
          ? `${boundingBoxForDevices[0]},${boundingBoxForDevices[1]},${boundingBoxForDevices[2]},${boundingBoxForDevices[3]}`
          : `${boundsForDevices[0]},${boundsForDevices[1]},${boundsForDevices[2]},${boundsForDevices[3]}`,
        url, // Use URL for pagination
      });

      if (response?.data) {
        const geoJsonData = response?.data?.results;
        const nextUrl = response?.data?.next;

        // Combine the new data with accumulated data
        const combinedData = accumulatedData.concat(
          geoJsonData?.features || []
        );

        if (nextUrl) {
          // Call the function recursively until no more pages are left
          return fetchMissingData(nextUrl, combinedData);
        } else {
          // All data is fetched, now perform the calculations
          setMissingGanttData({
            ...geoJsonData,
            features: combinedData,
          });
        }
      }
    } catch (error) {
      console.error("Error fetching route data", error);
    }
  };
  const fetchIdleData = async (url = null, accumulatedData = []) => {
    try {
      const response = await getOrganizationDevicesRoutesIdleOverlap({
        orgName: user?.organization,
        date: getDateForApi(selectedDateForDevices),
        bbox: boundingBoxForDevices
          ? `${boundingBoxForDevices[0]},${boundingBoxForDevices[1]},${boundingBoxForDevices[2]},${boundingBoxForDevices[3]}`
          : `${boundsForDevices[0]},${boundsForDevices[1]},${boundsForDevices[2]},${boundsForDevices[3]}`,
        url, // Use URL for pagination
      });

      if (response?.data) {
        const geoJsonData = response?.data?.results;
        const nextUrl = response?.data?.next;

        // Combine the new data with accumulated data
        const combinedData = accumulatedData.concat(
          geoJsonData?.features || []
        );

        if (nextUrl) {
          // Call the function recursively until no more pages are left
          return fetchMissingData(nextUrl, combinedData);
        } else {
          // All data is fetched, now perform the calculations
          setIdleGanttData({
            ...geoJsonData,
            features: combinedData,
          });
        }
      }
    } catch (error) {
      console.error("Error fetching route data", error);
    }
  };

  useEffect(() => {
    if (selectedDevicesDashboard) {
      fetchTransitForEnd();
      fetchTransitForStart();
      fetchMissingData();
      fetchIdleData();
    }
    return () => {
      setTransitGanttData(null);
      setIdleGanttData(null);
    };
  }, [selectedDevicesDashboard]);

  // Helper function to parse a time string like "1h 17m 3s" to seconds

  // Function to summarize time spent by each beacon type
  function summarizeTimeByBeaconType(data) {
    const timeSummary = {};
    // Loop through all devices in the response
    Object.keys(data).forEach((deviceId) => {
      const beacons = data[deviceId];

      beacons.forEach((beacon) => {
        const beaconType = beacon.beacon_type;
        const totalTime = beacon.total_time;

        // Convert time string to seconds
        const timeInSeconds = parseTimeString(totalTime);

        // Get the user-friendly beacon type
        const friendlyBeaconType = beaconTypeMapping[beaconType] || beaconType;

        // If the friendly beacon type is not yet in the summary, initialize it
        if (!timeSummary[friendlyBeaconType]) {
          timeSummary[friendlyBeaconType] = 0;
        }

        // Accumulate the total time for this beacon type
        timeSummary[friendlyBeaconType] += timeInSeconds;
      });
    });

    // Convert the total time for each beacon type back to a readable format
    const formattedTimeSummary = {};
    Object.keys(timeSummary).forEach((beaconType) => {
      formattedTimeSummary[beaconType] = formatTotalTime(
        timeSummary[beaconType]
      );
    });

    return formattedTimeSummary;
  }

  // Map beacon types to user-friendly names
  const beaconTypeMapping = {
    ride_on_mower: "Mowing",
    string_trimmer: "String Trimming",
    blower: "Blowing",
    edge: "Edging",
  };

  // Get the summary
  const timeSummary = summarizeTimeByBeaconType(devicesEquipmentData);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "10px",
        padding: "0 12px",
      }}
      className="propertySummary"
    >
      <Box>
        <Typography variant="body2" fontWeight="600">
          {devicesDashboardLocationValue?.name}{" "}
          {`( ${getDateFromCalender(selectedDateForDevices)})`}
        </Typography>
      </Box>
      <Button variant="contained" size="small" onClick={handleOpenDialog}>
        Property summary
      </Button>
      <Dialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6" fontWeight="500">
              Property Summary
            </Typography>
            <Button
              variant="contained"
              size="small"
              onClick={handleCaptureImage}
              sx={{ color: "white" }}
            >
              <MdCloudDownload fontSize="20" color="white" />
              <Typography variant="body1" marginLeft="5px" fontWeight="600">
                Save
              </Typography>
            </Button>
          </Box>
        </DialogTitle>

        <DialogContent sx={{ padding: "0" }}>
          <Box ref={captureRef} sx={{ padding: "0 20px 20px" }}>
            <Typography
              variant="body1"
              fontWeight="600"
              sx={{ color: blackShade1.main }}
            >
              {devicesDashboardLocationValue?.name}{" "}
              {`- ${getDateFromCalender(selectedDateForDevices)}`}
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                marginBottom: "15px",
                marginTop: "5px",
                justifyContent: "space-between",
              }}
            >
              <Box className="propertiesCardLeft">
                <EntryExitTable />
              </Box>
              <Box className="propertiesCardRight">
                <ProductionTimeTable timeSummary={timeSummary} />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                marginBottom: "10px",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="body1"
                fontWeight="600"
                sx={{ color: blackShade1.main }}
              >
                Gantt Chart
              </Typography>
              <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
                <Box
                  sx={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  <Box
                    sx={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "20px",
                      background: "#4B0082",
                    }}
                  ></Box>
                  <Typography variant="body2">
                    Idle/Less confidence production
                  </Typography>
                </Box>
                <Box
                  sx={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  <Box
                    sx={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "20px",
                      background: "#FF8C00",
                    }}
                  ></Box>
                  <Typography variant="body2">Transit</Typography>
                </Box>
                <Box
                  sx={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  <Box
                    sx={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "20px",
                      background: "#6cb55d",
                    }}
                  ></Box>
                  <Typography variant="body2">Production</Typography>
                </Box>
                <Box
                  sx={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  <Box
                    sx={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "20px",
                      background: "#800000",
                    }}
                  ></Box>
                  <Typography variant="body2">Missing</Typography>
                </Box>
                
              </Box>
            </Box>
            <PropertyGanttChartDeviceWise
              propertySummary={selectedDevicesGeoJson}
              transitGanttData={transitGanttData}
              missingGanttData={missingGanttData}
              idleGanttData={idleGanttData}
            />

            {/* <PropertyGanttChartEquipmentWise
              propertySummary={selectedDevicesGeoJson}
            /> */}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PropertySummary;
