import React from "react";
import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { getCustomColor } from "../../../utils/theme";

const FolderList = ({ onFolderClick }) => {
  const blackShade1 = getCustomColor("blackShade1");
  const locationGroupData = useSelector(
    (state) => state.workPlan.locationGroupData
  );

  return (
    <Box className="folderListContainer" marginTop={2}>
      <Grid container spacing={2}>
        {locationGroupData &&
          locationGroupData.map((folder) => (
            <Grid key={folder.name} item xs={6} sm={4} md={2}>
              <Box
                button
                onClick={() => onFolderClick(folder.key)}
                className="folderContainer"
              >
                <Box>
                  <IconButton>
                    {/* <FaFolder /> */}
                    <img src="/images/folder.png" width="25px" height="25px" />
                  </IconButton>
                  <Tooltip title={folder?.name} arrow>
                    <Typography
                      fontWeight={"500"}
                      noWrap
                      variant="body2"
                      marginLeft="5px"
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        color: blackShade1.light,
                      }}
                    >
                      {folder?.name}
                    </Typography>
                  </Tooltip>
                </Box>
              </Box>
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};

export default FolderList;
