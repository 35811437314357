import {
  Box,
  Button,
  Grid,
  IconButton,
  Popover,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { MdDelete, MdEdit } from "react-icons/md";
import { getCustomColor } from "../../utils/theme";
import { useForm } from "react-hook-form";
import { useDeletePlanMutation, useUpdatePlannerNameMutation } from "../../services/OpenApi";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const PlansCard = ({ folder, parcelId }) => {
  const blackShade1 = getCustomColor("blackShade1");
  const user = localStorage.getItem("user");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [isEdit, setIsEdit] = useState(false);
  const [handleEditFolder, setHandleEditFolder] = useState(null);
  const [updatePlannerName, { isLoading }] = useUpdatePlannerNameMutation();
  const [deletePlan, { isLoading: isDeleteLoading }] = useDeletePlanMutation();
  const {
    register,
    handleSubmit: handleFormSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const handlePlanClick = (folder) => {
    const baseUrl = window.location.origin;
    const newUrl = `${baseUrl}/map?orderId=${parcelId}&runPlannerId=${folder?.runPlannerId}`;
    window.open(newUrl, "_blank");
  };

  const onSubmit = async (data) => {
    try {
      const response = await updatePlannerName({
        orgName: user?.organization,
        runPlannerId: folder?.runPlannerId,
        runPlannerName: data?.planName,
      });
      if (response?.data) {
        toast.success("Plan name changed successfully", {
          autoClose: 1000,
          hideProgressBar: true,
        });
        setIsEdit(false);
        setHandleEditFolder(null);
      } else if (response?.error) {
        toast.error(response?.error?.error || response?.error?.data?.message, {
          autoClose: 2000,
          hideProgressBar: true,
        });
      }
    } catch (err) {
      console.log("error => ", err);
    }
  };

  const handleDelete = async () => {
    try {
      const response = await deletePlan({
        orgName: user?.organization,
        runPlannerId: folder?.runPlannerId,
      });
      if (response?.error) {
        toast.error(response?.error?.error || response?.error?.data?.message, {
          autoClose: 2000,
          hideProgressBar: true,
        });
      }
      toast.success("Plan deleted successfully", {
        autoClose: 1000,
        hideProgressBar: true,
      });
      handleClose();
    } catch (err) {
      console.log("error => ", err);
    }
  };

  return (
    <Grid key={folder?.runPlannerId} item xs={12} sm={12} md={12}>
      <Box className="folderContainerPlans">
        <Box
          className="folderDataContainer"
          component="form"
          noValidate
          onSubmit={handleFormSubmit(onSubmit)}
        >
          <div className="folderDataContainerLeft">
            <IconButton>
              <img src="/images/google-docs.png" width="25px" height="25px" />
            </IconButton>
            {isEdit ? (
              <TextField
                size="small"
                fullWidth
                required
                id="planName"
                name="planName"
                margin="normal"
                InputLabelProps={{ shrink: true }}
                inputProps={{ maxLength: 52 }}
                {...register("planName", {
                  required: "Plan name is required",
                  maxLength: {
                    value: 52,
                    message: "Plan name should not exceed 52 characters",
                  },
                })}
                error={!!errors.planName}
                helperText={errors.planName ? errors.planName.message : ""}
              />
            ) : (
              <Tooltip title={folder?.runPlannerName} arrow>
                <Typography
                  fontWeight={"500"}
                  noWrap
                  onClick={() => handlePlanClick(folder)}
                  variant="body2"
                  fontSize={16}
                  marginLeft="10px"
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    color: blackShade1.light,
                  }}
                >
                  {folder?.runPlannerName}
                </Typography>
              </Tooltip>
            )}
          </div>
          <div className="tableActions">
            {isEdit ? (
              <>
                <Button
                  size="small"
                  color="error"
                  onClick={() => setIsEdit(false)}
                  variant="outlined"
                >
                  Cancel
                </Button>
                <Button
                  disabled={isLoading}
                  type="submit"
                  size="small"
                  variant="contained"
                >
                  Save
                </Button>
              </>
            ) : (
              <>
                <Tooltip title="Edit">
                  <IconButton
                    onClick={() => {
                      setHandleEditFolder(folder);
                      setValue("planName", folder?.runPlannerName);
                      setIsEdit(true);
                    }}
                  >
                    <MdEdit
                      color={blackShade1.light}
                      cursor={"pointer"}
                      fontSize={18}
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton onClick={handleClick}>
                    <MdDelete
                      cursor={"pointer"}
                      height={20}
                      color={blackShade1.light}
                      fontSize={18}
                    />
                  </IconButton>
                </Tooltip>
                <Popover
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <Box p={2}>
                    <Typography variant="body2">
                      Are you sure you want to delete{" "}
                      <strong>{folder?.runPlannerName}</strong>?
                    </Typography>
                    <Box mt={2} display="flex" justifyContent="flex-end">
                      <Button
                        onClick={handleClose}
                        variant="outlined"
                        size="small"
                        sx={{ mr: 1 }}
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={handleDelete}
                        disabled={isDeleteLoading}
                        color="error"
                        variant="contained"
                        size="small"
                      >
                        Delete
                      </Button>
                    </Box>
                  </Box>
                </Popover>
              </>
            )}
          </div>
        </Box>
      </Box>
    </Grid>
  );
};

export default PlansCard;
