import { Box, Typography } from "@mui/material";
import { GoDotFill } from "react-icons/go";
import React from "react";

const AuthDesignWrapper = ({ body }) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        className="authContainer"
      >
        {body}
      </Box>
      <div className="turingLogo">
        <img src="/images/Turing_Logo_Gradient.jpg" />
        <Box sx={{ display: "flex", gap: "20px",alignItems: "center" }}>
          <Typography
            fontWeight={"600"}
            fontSize={14}
            sx={{ color: "primary.dark" }}
            component="span"
            variant="body1"
          >
            Privacy Policy
          </Typography>
          <GoDotFill fontSize={10} color="#4EA403" />
          <Typography
            fontWeight={"600"}
            fontSize={14}
            sx={{ color: "primary.dark" }}
            component="span"
            variant="body1"
          >
            Terms of Service
          </Typography>
          <GoDotFill fontSize={10} color="#4EA403" />
          <Typography
            fontWeight={"600"}
            fontSize={14}
            sx={{ color: "primary.dark" }}
            component="span"
            variant="body1"
          >
            Resources
          </Typography>
        </Box>
      </div>
      <div className="patternLeft">
        <img src="/images/patternsLeft.png" />
      </div>
      <div className="patternRight">
        <img src="/images/patternsRight.png" />
      </div>
    </>
  );
};

export default AuthDesignWrapper;
