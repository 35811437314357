import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  useAddBeaconMutation,
  useEditBeaconMutation,
} from "../../services/Properties";
import { toast } from "react-toastify";
import QrReader from "../../components/QrReader/QrReader";

const BeaconTypes = [
  {
    name: "Ride on mower",
    value: "ride_on_mower",
  },
  {
    name: "String Trimmer",
    value: "string_trimmer",
  },
  {
    name: "Edger",
    value: "edger",
  },
  {
    name: "Blower",
    value: "blower",
  }
]

const AddBeaconModal = ({ isOpen, setIsOpen, editData, setEditBeaconData }) => {
  const [beaconId, setBeaconId] = useState(null);
  const [cameraOn, setCameraOn] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm();
  const [addBeacon, { isLoading: beaconLoading }] = useAddBeaconMutation();
  const [editBeacon, { isLoading: editBeaconLoading }] =
    useEditBeaconMutation();

  useEffect(() => {
    if (editData) {
      setBeaconId(editData?.core_attributes?.beacon_id);
      const formData = {
        type: editData?.core_attributes?.type,
        deckSize: editData?.core_attributes?.deck_size,
        brand: editData?.core_attributes?.brand,
        modal: editData?.core_attributes?.modal,
        displayName: editData?.core_attributes?.display_name,
        uniqueId: editData?.user_attributes?.unique_id,
      };
      reset(formData);
    }
  }, [editData]);

  const onSubmit = async (data) => {
    const createdName = data?.type.replace(" ", "_") + "_" + data?.uniqueId;
    const body = {
      organisation: user?.organization,
      content: {
        name: createdName,
        key: createdName,
        asset_model_id: null,
        asset_type: "landscaping_equipment",
        is_active: true,
        meta_data: {},
        core_attributes: {
          type: data?.type,
          deck_size: data?.deckSize,
          brand: data?.brand,
          modal: data?.modal,
          beacon_id: beaconId,
          display_name: data?.displayName,
        },
        user_attributes: { unique_id: data?.uniqueId },
        start_date: new Date().toISOString(),
      },
    };
    if (editData) {
      body.assetKey = createdName;
      const response = await editBeacon(body);
      if (response?.data) {
        toast.success("Beacon Updated Successfully", {
          autoClose: 2000,
          hideProgressBar: true,
        });
        handleCancel();
        setBeaconId(null);
        setEditBeaconData(null);
      } else if (response?.error) {
        toast.error(
          response?.error?.error ||
            response?.error?.data?.message ||
            "Something went wrong",
          {
            autoClose: 2000,
            hideProgressBar: true,
          }
        );
      }
    } else {
      const response = await addBeacon(body);
      if (response?.data) {
        toast.success("Beacon Added Successfully", {
          autoClose: 2000,
          hideProgressBar: true,
        });
        handleCancel();
        setBeaconId(null);
      } else if (response?.error) {
        toast.error(
          response?.error?.error ||
            response?.error?.data?.message ||
            "Something went wrong",
          {
            autoClose: 2000,
            hideProgressBar: true,
          }
        );
      }
    }
  };
  const handleCancel = () => {
    setIsOpen(false);
    setEditBeaconData(null);
    setBeaconId(null);
    setCameraOn(false);
    reset({
      type: "",
      deckSize: "",
      brand: "",
      modal: "",
      displayName: "",
      uniqueId: "",
    });
  };

  const handleQrSucess = (data) => {
    if (data) {
      setCameraOn(false);
      setBeaconId(data);
    }
  };
  return (
    <Dialog open={isOpen} fullWidth maxWidth="xs">
      <DialogTitle fontWeight={700} fontSize="20px" align="left">
        {editData ? "Edit Beacon" : "Add Beacon"}
      </DialogTitle>
      <Divider />
      <div className="dialogContainer">
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <TextField
            fullWidth
            name="brand"
            id="brand"
            placeholder="Brand name"
            label="Brand name"
            size="small"
            error={!!errors.brand}
            helperText={errors.brand && "Brand name is required"}
            {...register("brand", { required: true })}
          />
          <TextField
            fullWidth
            name="modal"
            id="modal"
            placeholder="Modal"
            label="Modal"
            size="small"
            error={!!errors.modal}
            helperText={errors.modal && "Modal is required"}
            {...register("modal", { required: true })}
          />
          <FormControl disabled={editData ? true : false} fullWidth error={!!errors.type}>
            <InputLabel size="small" name="type" id="type">
              Select an Option
            </InputLabel>
            <Controller
              name="type"
              control={control}
              defaultValue="" // Provide a default value
              rules={{ required: "Please select an option" }}
              render={({ field }) => (
                <Select
                  {...field}
                  labelId="type-label"
                  label="Select an Option"
                  size="small"
                >
                  {BeaconTypes?.map((beacon) => {
                    return <MenuItem key={beacon.value} value={beacon?.value}>{beacon.name}</MenuItem>;
                  })}
                </Select>
              )}
            />
            {errors.type && (
              <FormHelperText>{errors.type.message}</FormHelperText>
            )}
          </FormControl>
          <TextField
            fullWidth
            name="deckSize"
            id="deckSize"
            placeholder="Deck Size"
            label="Deck Size"
            size="small"
            error={!!errors.deckSize}
            helperText={errors.deckSize && "deckSize is required"}
            {...register("deckSize", { required: true })}
          />
          <TextField
            fullWidth
            name="displayName"
            id="displayName"
            placeholder="Display Name"
            label="Display Name"
            size="small"
            error={!!errors.displayName}
            helperText={errors.displayName && "Display Name is required"}
            {...register("displayName", { required: true })}
          />
          <TextField
            fullWidth
            name="uniqueId"
            id="uniqueId"
            disabled={editData ? true : false}
            placeholder="Unique Id"
            label="Unique Id"
            size="small"
            error={!!errors.uniqueId}
            helperText={errors.uniqueId && "Unique Id is required"}
            {...register("uniqueId", { required: true })}
          />
          {/* {cameraOn && <QrReader handleQrSucess={handleQrSucess} />} */}
          {cameraOn && <QrReader handleQrSucess={handleQrSucess} />}
          {beaconId ? (
            <>
              <Typography>Beacon Id - {beaconId}</Typography>
              <Button variant="outlined" onClick={() => setCameraOn(true)}>
                Capture Again
              </Button>
            </>
          ) : (
            <Button variant="outlined" onClick={() => setCameraOn(true)}>
              Scan QrCode
            </Button>
          )}
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              justifyContent: "flex-end",
              mt: 2,
            }}
          >
            <Button variant="outlined" onClick={handleCancel}>
              Cancel
            </Button>
            <Button disabled={beaconLoading} variant="contained" type="submit">
              {beaconLoading || editBeaconLoading ? (
                <CircularProgress size={24} />
              ) : editData ? (
                "Edit"
              ) : (
                "Add"
              )}
            </Button>
          </Box>
        </Box>
      </div>
    </Dialog>
  );
};

export default AddBeaconModal;
