import {
  Box,
  Divider,
  Drawer,
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Skeleton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCustomColor } from "../../../utils/theme";
import { toast } from "react-toastify";
import { IoClose } from "react-icons/io5";
import useViewportWidth from "../../../Hooks/useViewportWidth";
import { FaCalendar } from "react-icons/fa";
import {
  useGetIntelLocationGroupsMutation,
  useLazyGetDevicesBaseLayersQuery,
  useLazyGetOrganizationDevicesRoutesProductionQuery,
} from "../../../services/Properties";
import { setLocationGroupData } from "../../../slices/workPlanSlice";
import {
  setBeaconTypeColors,
  setBoundingBoxForDevices,
  setDeviceBaseLayersGeojson,
  setDevicesBeconCheckboxes,
  setDevicesDashboardLocationValue,
  setDevicesEquipmentData,
  setDevicesSummaryRef,
  setDevicesUniqueColors,
  setIsBeaconBasedColor,
  setIsDeviceLayersLoading,
  setSelectedDateForDevices,
  setSelectedDevicesDashboard,
  setSelectedDevicesGeoJson,
} from "../../../slices/gisDashboardSlice";
import {
  beaconColors,
  devicesColors,
  getDateForApi,
  getDateFromCalender,
  getServiceColorFromLocationKey,
} from "../../../utils/common";
import DayCalender from "../../../components/DayCalender/DayCalender";
import DevicesSummaryCard from "./DevicesSummaryCard";
import PropertySummary from "./PropertySummary";
import { Mixpanel } from "../../../utils/mixPanel";

const DevicesDashboardSidebar = ({
  isPlannerDrawerOpen,
  togglePlannerDrawer,
}) => {
  const greenShade1 = getCustomColor("greenShade1");
  const blackShade1 = getCustomColor("blackShade1");
  const devicesSummaryRef = useSelector(
    (state) => state.gisDashboard.devicesSummaryRef
  );
  const captureRef = useRef(devicesSummaryRef);

  const isBeaconBasedColor = useSelector(
    (state) => state.gisDashboard.isBeaconBasedColor
  );
  const isDeviceLayersLoading = useSelector(
    (state) => state.gisDashboard.isDeviceLayersLoading
  );
  const devicesDashboardLocationValue = useSelector(
    (state) => state.gisDashboard.devicesDashboardLocationValue
  );
  const selectedDevicesDashboard = useSelector(
    (state) => state.gisDashboard.selectedDevicesDashboard
  );
  // const [isBaseLayersLoading, setIsBaseLayersLoading] = useState(false);
  const width = useViewportWidth();
  const user = JSON.parse(localStorage.getItem("user"));
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const selectedDateForDevices = useSelector(
    (state) => state.gisDashboard.selectedDateForDevices
  );
  const boundingBoxForDevices = useSelector(
    (state) => state.gisDashboard.boundingBoxForDevices
  );
  const boundsForDevices = useSelector(
    (state) => state.gisDashboard.boundsForDevices
  );
  const locationGroupData = useSelector(
    (state) => state.workPlan.locationGroupData
  );
  const [getIntelLocationGroups, { isLoading }] =
    useGetIntelLocationGroupsMutation();
  const [getDevicesBaseLayers] = useLazyGetDevicesBaseLayersQuery();
  const [getOrganizationDevicesRoutesProduction] =
    useLazyGetOrganizationDevicesRoutesProductionQuery();

  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      try {
        const locationData = await getIntelLocationGroups(
          user?.organization
        ).unwrap();
        dispatch(setLocationGroupData(locationData?.results));
        localStorage.setItem(
          "locationGroups",
          JSON.stringify(locationData?.results)
        );
      } catch (error) {
        toast.error(error?.data?.detail, {
          autoClose: 2000,
          hideProgressBar: true,
        });
      }
    })();
  }, []);

  const fetchBaseLayers = async (selectedLocationValue) => {
    dispatch(setIsDeviceLayersLoading(true));
    try {
      const response = await getDevicesBaseLayers({
        orgName: user?.organization,
        location: `${selectedLocationValue?.key?.toUpperCase()}`,
      });
      if (response?.data) {
        let baseGeojson = {
          type: "FeatureCollection",
          features: [],
        };
        response?.data?.results?.features?.map((feature) => {
          if (feature?.properties?.key?.includes("landscape-parcel")) {
            dispatch(setBoundingBoxForDevices(feature?.bbox));
          } else {
            let featureObj = {
              type: "Feature",
              properties: {
                serviceName: getServiceColorFromLocationKey(
                  feature?.properties.key
                ),
              },
              geometry: feature?.geometry,
            };
            return baseGeojson.features.push(featureObj);
          }
        });
        dispatch(setDeviceBaseLayersGeojson(baseGeojson));
        dispatch(setIsDeviceLayersLoading(false));
      }
    } catch (error) {
      dispatch(setIsDeviceLayersLoading(false));
    }
  };
  const fetchRouteData = async (url = null, accumulatedData = []) => {
    dispatch(setIsDeviceLayersLoading(true));
    try {
      const response = await getOrganizationDevicesRoutesProduction({
        orgName: user?.organization,
        date: getDateForApi(selectedDateForDevices),
        bbox: boundingBoxForDevices
          ? `${boundingBoxForDevices[0]},${boundingBoxForDevices[1]},${boundingBoxForDevices[2]},${boundingBoxForDevices[3]}`
          : `${boundsForDevices[0]},${boundsForDevices[1]},${boundsForDevices[2]},${boundsForDevices[3]}`,
        url, // Use URL for pagination
      });

      if (response?.data) {
        const geoJsonData = response?.data?.results;
        const nextUrl = response?.data?.next;

        // Combine the new data with accumulated data
        const combinedData = accumulatedData.concat(
          geoJsonData?.features || []
        );

        if (nextUrl) {
          // Call the function recursively until no more pages are left
          return fetchRouteData(nextUrl, combinedData);
        } else {
          // All data is fetched, now perform the calculations
          Mixpanel.identify(user.email);
          Mixpanel.track(
            `Viewing property - ${
              devicesDashboardLocationValue?.name
            } (${getDateFromCalender(selectedDateForDevices)})`
          );
          dispatch(
            setSelectedDevicesGeoJson({
              ...geoJsonData,
              features: combinedData,
            })
          );

          const uniqueDevices = {};
          const uniqueDevicesColors = {};
          const beaconTypes = {};
          const beaconTypeColors = {};
          let deviceColorIndex = 0;
          let beaconColorIndex = 0;

          combinedData.forEach((feature) => {
            const deviceId = feature?.properties?.device_id;
            const beaconType = feature?.properties?.beacon_type;

            if (!beaconTypes[beaconType]) {
              beaconTypes[beaconType] = true;
              beaconTypeColors[beaconType] = beaconColors[beaconColorIndex];
              beaconColorIndex = (beaconColorIndex + 1) % beaconColors.length;
            }
            if (!uniqueDevices[deviceId]) {
              uniqueDevices[deviceId] = true;
              uniqueDevicesColors[deviceId] = devicesColors[deviceColorIndex];
              deviceColorIndex = (deviceColorIndex + 1) % devicesColors.length;
            }
          });

          // Dispatch actions after data aggregation
          dispatch(setDevicesUniqueColors(uniqueDevicesColors));
          dispatch(setSelectedDevicesDashboard(uniqueDevices));
          dispatch(setBeaconTypeColors(beaconTypeColors));

          const result = combinedData.reduce((acc, item) => {
            const deviceId = item.properties.device_id;
            const beaconId = item.properties.beacon_id;

            // Check if the deviceId exists in the accumulator
            if (!acc[deviceId]) {
              acc[deviceId] = {};
            }

            // Add beaconId to the device object if it doesn't already exist
            if (!acc[deviceId][beaconId]) {
              acc[deviceId][beaconId] = true;
            }

            return acc;
          }, {});

          dispatch(setDevicesBeconCheckboxes(result));

          function calculateTimeInSeconds(startTime, endTime) {
            const start = new Date(startTime);
            const end = new Date(endTime);
            const difference = end.getTime() - start.getTime();
            const differenceInSeconds = Math.round(difference / 1000);
            return differenceInSeconds;
          }

          // Function to convert the date to a readable format
          function formatDate(dateString) {
            const date = new Date(dateString);
            return date.toLocaleString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour12: false,
            });
          }

          const groupedByDeviceAndBeacon = combinedData.reduce((acc, item) => {
            const {
              device_id,
              beacon_id,
              beacon_name,
              beacon_type,
              start_time,
              end_time,
            } = item.properties;
            const timeInSeconds = calculateTimeInSeconds(start_time, end_time);

            if (!acc[device_id]) {
              acc[device_id] = {};
            }

            if (!acc[device_id][beacon_id]) {
              acc[device_id][beacon_id] = {
                beacon_name,
                beacon_type,
                total_time_in_seconds: 0,
                start_time: formatDate(start_time),
                end_time: formatDate(end_time),
              };
            }

            acc[device_id][beacon_id].total_time_in_seconds += timeInSeconds;
            acc[device_id][beacon_id].end_time = formatDate(end_time);

            return acc;
          }, {});

          function formatTotalTime(totalSeconds) {
            const hours = Math.floor(totalSeconds / 3600);
            const minutes = Math.floor((totalSeconds % 3600) / 60);
            const seconds = Math.floor(totalSeconds % 60);
            let timeString = "";
            if (hours > 0) {
              timeString += `${hours}h `;
            }
            if (minutes > 0 || hours > 0) {
              timeString += `${minutes}m `;
            }
            timeString += `${seconds}s`;
            return timeString;
          }
          // Format the final object
          const finalResult = Object.keys(groupedByDeviceAndBeacon).reduce(
            (acc, deviceId) => {
              acc[deviceId] = Object.keys(
                groupedByDeviceAndBeacon[deviceId]
              ).map((beaconId) => {
                const beacon = groupedByDeviceAndBeacon[deviceId][beaconId];
                return {
                  beacon_id: beaconId,
                  beacon_name: beacon.beacon_name,
                  beacon_type: beacon.beacon_type,
                  total_time: formatTotalTime(beacon.total_time_in_seconds),
                  start_time: beacon.start_time,
                  end_time: beacon.end_time,
                };
              });
              return acc;
            },
            {}
          );

          // Dispatch final grouped data
          dispatch(setDevicesEquipmentData(finalResult));
          dispatch(setIsDeviceLayersLoading(false));
        }
      }
    } catch (error) {
      console.error("Error fetching route data", error);
      dispatch(setIsDeviceLayersLoading(false));
    }
  };

  useEffect(() => {
    if (selectedDateForDevices) {
      fetchRouteData();
    }
  }, [boundingBoxForDevices, selectedDateForDevices]);

  const handleLocationSelectChange = (newValue) => {
    dispatch(setDevicesDashboardLocationValue(newValue));
    fetchBaseLayers(newValue);
  };

  const handleCalendarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCalendarClose = () => {
    setAnchorEl(null);
  };

  const handleSelectedDate = (date) => {
    dispatch(setSelectedDateForDevices(date));
  };

  useEffect(() => {
    if(captureRef){
      dispatch(setDevicesSummaryRef(captureRef));
    }
  }, [captureRef]);

  return (
    <Drawer
      anchor="left"
      open={isPlannerDrawerOpen}
      hideBackdrop
      variant="persistent"
      sx={{
        "& .MuiDrawer-paper": {
          width: width < 768 ? "100vw" : "35vw",
          zIndex: 10,
        },
      }}
    >
      <Box className="sidebarDrawer">
        {width < 768 && (
          <Box className="sidebarCloseButton" sx={{ margin: "10px 10px 0 0" }}>
            <IoClose size={24} onClick={() => togglePlannerDrawer()} />
          </Box>
        )}
        <Box className="sidebarSelectContainer">
          <FormControl fullWidth={width < 500 ? true : false}>
            <InputLabel
              sx={{ fontSize: "12px" }}
              size="small"
              fullWidth={width < 500 ? true : false}
              name="type"
              id="type"
            >
              Select Location
            </InputLabel>
            <Select
              value={devicesDashboardLocationValue || null}
              onChange={(e) => handleLocationSelectChange(e?.target?.value)}
              size="small"
              label="Select Location"
              labelId="type-label"
              fullWidth={width < 500 ? true : false}
              sx={{
                background: greenShade1.main,
                color: (theme) => theme.palette.primary.dark,
                fontWeight: 600,
                fontSize: "12px",
                height: "35px",
                width: width < 500 ? "auto" : "150px",
                border: "none",
                outline: "none",
                borderWidth: 0,
              }}
            >
              {locationGroupData &&
                locationGroupData.map((location) => (
                  <MenuItem key={location} value={location}>
                    {location.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <Box
            className="calenderButton"
            onClick={handleCalendarClick}
            sx={{ background: greenShade1.main }}
          >
            <Typography
              component="div"
              fontWeight="600"
              fontSize="12px"
              sx={{ color: "primary.dark" }}
              variant="subtitle2"
            >
              {selectedDateForDevices
                ? getDateFromCalender(selectedDateForDevices)
                : "Select Date"}
            </Typography>
            {<FaCalendar color="#326703" fontSize={16} />}
          </Box>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleCalendarClose}
            disableScrollLock={true}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&::before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <DayCalender
              dayCalenderValue={selectedDateForDevices}
              handleDayCalender={handleSelectedDate}
            />
          </Menu>
        </Box>
        <Divider />
      </Box>
      {selectedDevicesDashboard &&
        Object.keys(selectedDevicesDashboard).length > 0 && <PropertySummary />}
      <Box className="summaryWrapper">
        <Box ref={captureRef} className="summaryContainer">
          {isDeviceLayersLoading ? (
            [...Array(3)].map((_, index) => (
              <Skeleton
                key={index}
                variant="rectangular"
                width="100%"
                animation="wave"
                height={400}
                sx={{ borderRadius: "4px" }}
              />
            ))
          ) : selectedDevicesDashboard &&
            Object.keys(selectedDevicesDashboard).length > 0 ? (
            Object.keys(selectedDevicesDashboard).map((key) => {
              return <DevicesSummaryCard deviceName={key} key={key} />;
            })
          ) : (
            <div className="noDataForCrewIntel">
              <Typography variant="body1">No data available</Typography>
            </div>
          )}
        </Box>
      </Box>
    </Drawer>
  );
};

export default DevicesDashboardSidebar;
