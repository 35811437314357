import { MenuItem, Select } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedDay } from '../../slices/globalSlice';

const CrewMemberDaySelect = ({ data }) => {
  const dispatch = useDispatch();
  const selectedDay = useSelector((state) => state.global.selectedDay);
  const handleChange = (event) => {
    const selectedDay = event.target.value;
    dispatch(setSelectedDay(selectedDay));
  };

  // Get maximum number of days from the data
  const maxDays = Math.max(...Object.values(data).map(member => member.day.length));

  // Generate options for select dropdown
  const dayOptions = Array.from({ length: maxDays }, (_, index) => index + 1);
  dayOptions.push("All")

  return (
    <Select
      value={selectedDay}
      onChange={handleChange}
      className='daySelect'
      fullWidth
    >
      {dayOptions.map(day => (
        <MenuItem key={day} value={day}>{`Day ${day}`}</MenuItem>
      ))}
    </Select>
  );
};

export default CrewMemberDaySelect;
