import React, { useEffect, useState } from "react";
import {
  useAddStepServicesDataMutation,
  useLazyGetDefaultservicesQuery,
  useLazyGetServicesDataQuery,
  useLazyGetStepServicesDataQuery,
} from "../../../services/OpenApi";
import { Button, CircularProgress, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { MdEdit } from "react-icons/md";
import AddServicesAndEquipmentModal from "../../../Modals/AddServicesAndEquipmentModal/AddServicesAndEquipmentModal";
import { useParams } from "react-router-dom";
import { handleNextStep } from "../../../slices/stepperFormSlice";
import { toast } from "react-toastify";
import {
  convertFromFeetPerMinute,
  getMeasuringUnit,
} from "../../../utils/common";

const AddServicesAndEquipmentForm = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const params = useParams();
  const dispatch = useDispatch();
  const [addStepServicesData] = useAddStepServicesDataMutation();
  const [getStepServicesData] = useLazyGetStepServicesDataQuery();
  const user = useSelector((state) => state.global.user);
  const [getServicesData, { isLoading }] = useLazyGetServicesDataQuery();
  const [getDefaultservices] = useLazyGetDefaultservicesQuery();
  const [skillAndEquipmentDropdown, setSkillsAndEquipentDropdown] = useState(
    []
  );
  const [servicesDefaultData, setServicesDefaultData] = useState([]);

  useEffect(() => {
    (async () => {
      const response = await getServicesData();
      

      if (response?.data?.data?.length > 0) {
        const convertedData = response?.data?.data?.map((service) => ({
          ...service,
          equipment: service?.equipment?.map((equipment) => ({
            ...equipment,
            productionRate: parseFloat(equipment?.productionRate?.toFixed(2)),
            transitionPace: parseFloat(equipment?.transitionPace?.toFixed(2)),
            transitionPaceUnit: "MILES_PER_HOUR",
          })),
        }));

        setSkillsAndEquipentDropdown(convertedData);
      }
      
    })();
  }, []);
  const editServiceClick = (service) => {
    setIsOpen(true);
    setEditData(service);
  };

  const onSubmit = async () => {
    const data = {
      orderId: params?.id,
      orgName: user?.organization,
      content: servicesDefaultData.map((service) => {
        return {
          serviceId: service.serviceId,
          skillId: service.serviceSkill.skillId,
          serviceEquipment: service.serviceEquipment.map((equipment) => {
            return {
              equipmentId: equipment.equipmentId,
              productionRate: equipment.productionRate,
              transitionPace: equipment.transitionPace,
              productionRateUnit: equipment.productionRateUnit,
              transitionPaceUnit: equipment.transitionPaceUnit,
              measuringUnit: equipment?.measuringUnit
                ? equipment?.measuringUnit
                : 0, // You may replace 0 with the appropriate measuring unit value
            };
          }),
        };
      }),
    };
    try {
      const response = await addStepServicesData(data);
      if (response?.data) {
        toast.success("Services and Equipments added successfully", {
          autoClose: 1000,
          hideProgressBar: true,
        });
        dispatch(handleNextStep());
      } else if (response?.error) {
        toast.error(
          response?.error?.error ||
            response?.error ||
            response?.error?.data?.message,
          {
            autoClose: 2000,
            hideProgressBar: true,
          }
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const UpdateServiceData = (data) => {
    const updatedData = servicesDefaultData.map((service) => {
      if (service?.serviceId === data?.serviceId) {
        return data;
      }
      return service;
    });
    setServicesDefaultData(updatedData);
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await getStepServicesData({
          orderId: params?.id,
          orgName: user?.organization,
        }).unwrap();
        if (response) {
          if (response.data.length > 0) {
            const convertedData = response?.data?.map((service) => ({
              ...service,
              serviceEquipment: service.serviceEquipment.map((equipment) => ({
                ...equipment,
              })),
            }));
            setServicesDefaultData(convertedData);
          } else {
            const defaultServices = await getDefaultservices();
            if (defaultServices?.data?.data?.length > 0) {
              const convertedData = defaultServices?.data?.data?.map((service) => ({
                ...service,
                serviceEquipment: service.serviceEquipment.map((equipment) => ({
                  ...equipment,
                  productionRate: parseFloat(equipment?.productionRate?.toFixed(2)),
                  transitionPace: parseFloat(equipment?.transitionPace?.toFixed(2)),
                  transitionPaceUnit: "MILES_PER_HOUR",
                })),
              }));
      
              setServicesDefaultData(convertedData);
            }
          }
        }
      } catch (err) {
        console.log("error => ", err);
      }
    })();
  }, [getStepServicesData]);
  return (
    <div className="StepsFormContainer">
      <h3 className="StepsFormContainerHeading">Services</h3>
      {servicesDefaultData &&
        servicesDefaultData?.map((service) => {
          return (
            <div className="serviceContainer" key={service?.id}>
              <div className="serviceName">
                {service?.name}{" "}
                <MdEdit
                  cursor="pointer"
                  onClick={() => editServiceClick(service)}
                  fontSize="16"
                />
              </div>
              <div className="serviceSubTitle">Skill</div>
              <Typography component="h3" marginBottom="10px" variant="body2">
                {service?.serviceSkill?.skillName}
              </Typography>
              {service?.serviceEquipment?.map((equipment) => {
                return (
                  <div className="equipmentDataContainer">
                    <div className="equipmentDataLeft">
                      <div className="serviceSubTitle">Equipment Name</div>
                      <Typography component="div" fontSize={11} variant="body2">
                        {equipment?.equipmentName}
                      </Typography>
                    </div>
                    <div className="equipmentDataRight">
                      <div className="serviceSubTitle">Production Rate</div>
                      <Typography component="div" fontSize={11} variant="body2">
                        {equipment?.productionRate?.toFixed(2)}{" "}
                        {getMeasuringUnit(equipment?.productionRateUnit)}
                      </Typography>
                    </div>
                    <div className="equipmentDataRight">
                      <div className="serviceSubTitle">Top Speed</div>
                      <Typography component="div" fontSize={11} variant="body2">
                        {/* convert speed from feet/hr to mph */}
                        {equipment?.transitionPace?.toFixed(2)}{" "}
                        {getMeasuringUnit(equipment?.transitionPaceUnit)}
                      </Typography>
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      <AddServicesAndEquipmentModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        editData={editData}
        UpdateServiceData={UpdateServiceData}
        skillAndEquipmentDropdown={skillAndEquipmentDropdown}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        disabled={isLoading}
        onClick={onSubmit}
        sx={{ mt: 3, mb: 2 }}
      >
        {isLoading ? <CircularProgress size={24} /> : "Confirm"}
      </Button>
    </div>
  );
};

export default AddServicesAndEquipmentForm;
