import React, { useEffect, useRef, useState } from "react";
import Timeline from "react-visjs-timeline";
import "vis/dist/vis.min.css";
import { useSelector } from "react-redux";
import {
  convertStartAndEndTimes,
  formatTimestampForCrewIntel,
  getTimelineColorBasedOnLocationKey,
  locationKeyNameConversion,
} from "../../../utils/common";

const ServiceBasedTimeline = () => {
  const timelineRef = useRef();
  const lookUp = useSelector((state) => state.global.lookUpData);
  const selectedDay = useSelector((state) => state.global.selectedDay);
  const summaryData = useSelector((state) => state.gisDashboard.summaryData);
  const [groupData, setGroupData] = useState([]);
  const [itemsData, setItemsData] = useState([]);
  const [itemsSummaryData, setItemsSummaryData] = useState([]);
  const [summary, setSummary] = useState(false);
  const [options, setOptions] = useState({
    stack: false,
    height: "32vh",
    zoomMin: 1000, // Adjust as necessary
    zoomMax: 100000000000,
    orientation: { axis: "top", item: "top" },
    groupOrder: "content",
    showTooltips: true,
    preferZoom: true,
    verticalScroll: true,
    groupHeightMode: "fitItems",
    groupOrder: function (a, b) {
      return b.value - a.value;
    },
  });

  const transformDataForTimeline = (summaryData) => {
    const timelineData = [];

    summaryData?.forEach((summary) => {
      const { location_key, devices_list } = summary || {};
      const locationKey = locationKeyNameConversion(location_key);

      if (devices_list) {
        Object.entries(devices_list).forEach(([deviceId, deviceData]) => {
          const { sessions } = deviceData || {};

          if (sessions) {
            Object.entries(sessions).forEach(([date, timeStamps]) => {
              if (Array.isArray(timeStamps)) {
                timeStamps.forEach((timeStamp, index) => {
                  if (Array.isArray(timeStamp) && timeStamp.length === 2) {
                    const [start, end] = timeStamp;
                    timelineData.push({
                      id: `${locationKey}-${deviceId}-${date}-${index}`,
                      start: formatTimestampForCrewIntel(Math.ceil(start)),
                      end: formatTimestampForCrewIntel(Math.ceil(end)),
                      group: `${locationKey}-${deviceId}`,
                      className: `${getTimelineColorBasedOnLocationKey(
                        location_key
                      )}`, // Custom CSS class
                    });
                  }
                });
              }
            });
          }
        });
      }
    });

    return timelineData;
  };

  // const transformDataForTimelineSummary = (lookup) => {
  //   const crewMemberDayWise = lookup.crewMemberDayWise;
  //   const timelineData = [];

  //   for (const crewId in crewMemberDayWise) {
  //     const crewData = crewMemberDayWise[crewId];
  //     let DayPolygons;
  //     if (crewId == 0) {
  //       DayPolygons = crewData.day.filter((dayData) => dayData.day == 0);
  //     } else if (selectedDay == "All") {
  //       DayPolygons = crewData.day;
  //     } else {
  //       DayPolygons = crewData.day.filter(
  //         (dayData) => dayData.day == selectedDay
  //       );
  //     }
  //     DayPolygons.forEach((dayData) => {
  //       const polygons = dayData.polygon;
  //       let currentServiceName = null;
  //       let currentServiceId = null;
  //       let currentStartTime = null;
  //       let currentEndTime = null;
  //       let currentCrewMemberName = null;
  //       let currentCrewMemberId = null;
  //       let currentPolygonId = null;

  //       polygons.forEach((polygonId, index) => {
  //         const polygon = lookup.polygons[polygonId];

  //         if (currentServiceName === null) {
  //           // This is the first polygon

  //           currentServiceName = getServiceName(polygon.serviceId);
  //           currentServiceId = polygon.serviceId;
  //           currentStartTime = polygon.arrivalTime;
  //           currentCrewMemberId = polygon.crewMemberId;
  //           currentCrewMemberName = getCrewMemberName(polygon.crewMemberId);
  //           currentPolygonId = polygonId;
  //         } else if (currentServiceName === getServiceName(polygon.serviceId)) {
  //           // Same service, update the end time
  //           currentEndTime = polygon.departureTime;
  //         } else {
  //           // Service name changed, push the previous task and start a new one
  //           pushTask(
  //             currentPolygonId,
  //             currentServiceName,
  //             currentServiceId,
  //             currentStartTime,
  //             currentEndTime,
  //             currentCrewMemberName,
  //             currentCrewMemberId
  //           );

  //           // Check for time gap and add a task for the gap
  //           if (index >= 1) {
  //             const previousPolygonId = polygons[index - 1];
  //             const previousPolygon = lookup.polygons[previousPolygonId];
  //             const serviceName = getServiceName(previousPolygon.serviceId);
  //             const gapStartTime = previousPolygon.departureTime;
  //             const gapEndTime = polygon.arrivalTime;

  //             pushTask(
  //               previousPolygonId,
  //               serviceName,
  //               previousPolygon.serviceId,
  //               gapStartTime,
  //               gapEndTime,
  //               currentCrewMemberName,
  //               currentCrewMemberId,
  //               "Gap"
  //             );
  //           }

  //           currentServiceName = getServiceName(polygon.serviceId);
  //           currentStartTime = polygon.arrivalTime;
  //           currentServiceId = polygon.serviceId;
  //           currentCrewMemberId = polygon.crewMemberId;
  //           currentCrewMemberName = getCrewMemberName(polygon.crewMemberId);
  //           currentPolygonId = polygonId;
  //         }

  //         // Always update the end time to the current polygon's departure time
  //         currentEndTime = polygon.departureTime;

  //         // If it's the last polygon, push the task
  //         if (index === polygons.length - 1) {
  //           pushTask(
  //             currentPolygonId,
  //             currentServiceName,
  //             currentServiceId,
  //             currentStartTime,
  //             currentEndTime,
  //             currentCrewMemberName,
  //             currentCrewMemberId
  //           );
  //         }
  //       });
  //     });

  //     function pushTask(
  //       polygonId,
  //       serviceName,
  //       serviceId,
  //       startTime,
  //       endTime,
  //       crewMemberName,
  //       crewMemberId,
  //       gap
  //     ) {
  //       const task = {
  //         id:
  //           gap === "Gap"
  //             ? `Back To Truck ${polygonId} ${crewMemberName}`
  //             : `${polygonId} ${crewMemberName}`,
  //         content:
  //           gap === "Gap" ? "Back To Truck" : serviceName.replace("_", " "), // Change content for Gap
  //         start: convertTimestampToFormattedDate(startTime),
  //         end: convertTimestampToFormattedDate(endTime),
  //         group: `${crewMemberId}`,
  //         title: `<div>
  //         <div class="individualContainer">
  //         <div>Crew Member : ${crewMemberName}</div>
  //         <div>Service Name : ${
  //           gap === "Gap" ? "Back To Truck" : serviceName.replace("_", " ")
  //         }</div>
  //         <div>Start Time : ${convertTimestampToFormattedDate(startTime)}</div>
  //         <div>End Time : ${convertTimestampToFormattedDate(endTime)}</div>
  //         </div>
  //         </div>`,
  //         className:
  //           gap === "Gap" ? "Back_To_Truck" : serviceName.replace(" ", "_"), // Custom CSS class
  //       };

  //       timelineData.push(task);
  //     }
  //   }

  //   return timelineData;
  // };

  const transformGroupDataForTimeline = (summaryData) => {
    const groupData = [];

    if (summaryData) {
      summaryData?.forEach((summary) => {
        const locationKey = locationKeyNameConversion(summary?.location_key);

        const data = Object.keys(summary?.devices_list).map((deviceId) => {
          return `${locationKey}-${deviceId}`;
        });

        const group = {
          id: locationKey,
          content: `${locationKey}`,
          showNested: false,
          nestedGroups: data,
        };

        groupData.push(group);
        Object.keys(summary?.devices_list).map((deviceId) => {
          const group = {
            id: `${locationKey}-${deviceId}`,
            content: `${deviceId}`,
          };

          groupData.push(group);
        });
      });
    }

    return groupData;
  };

  const findMinMaxTimeFromLookup = (summaryData) => {
    let minStartTime = null;
    let maxEndTime = null;

    summaryData?.forEach((summary) => {
      const { location_key, devices_list } = summary || {};

      if (devices_list) {
        Object.entries(devices_list).forEach(([deviceId, deviceData]) => {
          const { sessions } = deviceData || {};

          if (sessions) {
            Object.entries(sessions).forEach(([date, timeStamps]) => {
              if (Array.isArray(timeStamps)) {
                timeStamps.forEach((timeStamp, index) => {
                  if (Array.isArray(timeStamp) && timeStamp.length === 2) {
                    const [start, end] = timeStamp;

                    const startDate = formatTimestampForCrewIntel(start);
                    const endDate = formatTimestampForCrewIntel(end);
                    if (!minStartTime || startDate < minStartTime) {
                      minStartTime = startDate;
                    }
                    if (!maxEndTime || endDate > maxEndTime) {
                      maxEndTime = endDate;
                    }
                  }
                });
              }
            });
          }
        });
      }
    });

    return { minStartTime, maxEndTime };
  };

  useEffect(() => {
    if (summaryData) {
      let lookupData = { ...lookUp };
      const timelineData = transformDataForTimeline(summaryData);
      setItemsData(timelineData);
      //   const timelineDataSummary = transformDataForTimelineSummary(lookupData);
      //   setItemsSummaryData(timelineDataSummary);
      //   setItemsData((prevData) => [...prevData, ...timelineDataSummary]);
      const groupData = transformGroupDataForTimeline(summaryData);
      setGroupData(groupData);
      const bounds = findMinMaxTimeFromLookup(summaryData);
      const chartStart = new Date(bounds.minStartTime);
      const chartEnd = new Date(bounds.maxEndTime);
      const adjustedTimes = convertStartAndEndTimes(chartStart, chartEnd);
      const clusterOpts = {
        start: adjustedTimes.start,
        end: adjustedTimes.end,
        min: adjustedTimes.start + 1,
        max: adjustedTimes.end + 1,
      };
      setOptions((prevOptions) => ({
        ...prevOptions,
        ...clusterOpts,
      }));
    }
  }, [summaryData, selectedDay]);

  return (
    <div>
      {groupData && (
        <Timeline
          ref={timelineRef}
          options={options}
          groups={groupData}
          items={summary ? itemsSummaryData : itemsData}
        />
      )}
    </div>
  );
};

export default ServiceBasedTimeline;
