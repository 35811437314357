import { Button } from '@mui/material';
import React, { useRef } from 'react';
import { toast } from "react-toastify";

const LookUpUploadButton = ({ onFileUpload }) => {
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileUpload = (event) => {
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      try {
        const jsonData = JSON.parse(e.target.result);
        onFileUpload(jsonData)
      } catch (error) {
        console.error('Error parsing JSON:', error);
        toast.error("Not able to parse the uploaded file", {
          autoClose: 2000,
          hideProgressBar: true,
        });
      }
    };

    if (event.target.files.length > 0) {
      fileReader.readAsText(event.target.files[0]);
      event.target.value = null;
    }
  };

  return (
    <div className="uploadButtonContainer">
      <input
        type="file"
        accept=".json"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileUpload}
      />
      <Button fullWidth size='small' variant='contained' onClick={handleButtonClick}>Upload LookUp</Button>
    </div>
  );
};

export default LookUpUploadButton;
