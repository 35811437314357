import { createSlice } from "@reduxjs/toolkit";
import { geoJSONToShape, shapeToGeoJSON } from "../utils/common";
import {
  isMarker,
  isPolygon,
  isPolyline,
} from "../screens/GoogleMapsDraw/types";

const initialState = {
  parcelData: [],
};

const drawingSlice = createSlice({
  name: "drawing",
  initialState,
  reducers: {
    resetDrawingSlice: () => initialState,
    setParcelData: (state, action) => {
      state.parcelData = action.payload;
    },
    setOverlay: (state, action) => {
      const { overlay } = action.payload;

      const snapshot = {};
      if (isMarker(overlay)) {
        snapshot.position = overlay.getPosition()?.toJSON();
      } else if (isPolygon(overlay) || isPolyline(overlay)) {
        const last = overlay.getPath().getAt(0);
        snapshot.path = overlay.getPath()?.getArray().push(last);
      }
      state.parcelData = [
        ...state.parcelData,
        {
          type: action.payload.type,
          geometry: action.payload.overlay,
          snapshot,
          geojson: shapeToGeoJSON(overlay),
        },
      ];
    },
    updateOverlays: (state) => {
      const overlays = state.parcelData.map((overlay) => {
        const snapshot = {};
        const { geometry } = overlay;

        if (isMarker(geometry)) {
          snapshot.position = geometry.getPosition()?.toJSON();
        } else if (isPolygon(geometry) || isPolyline(geometry)) {
          const last = geometry.getPath().getAt(0);
          snapshot.path = geometry.getPath()?.getArray().push(last);
        }

        return {
          ...overlay,
          snapshot,
          geojson: shapeToGeoJSON(geometry), // Convert shape to GeoJSON
        };
      });
      state.parcelData = overlays;
    },
    initializeFromGeoJSON: (state, action) => {
      const { parcelGeoJSON, drawingManager, addUpdateListener, readOnly } =
        action.payload;
      const overlays = parcelGeoJSON?.features?.map((feature) =>
        geoJSONToShape(
          feature,
          drawingManager.getMap(),
          (eventName, overlay) => addUpdateListener(eventName, { overlay }),
          readOnly
        )
      );

      state.parcelData = overlays?.map((overlay) => {
        const snapshot = {};
        const { geometry } = overlay;

        if (isMarker(geometry)) {
          snapshot.position = geometry.getPosition()?.toJSON();
        } else if (isPolygon(geometry) || isPolyline(geometry)) {
          // Get the last point of the polygon because it should be closed
          const last = geometry.getPath().getAt(0);
          snapshot.path = geometry.getPath()?.getArray().push(last);
        }

        return {
          ...overlay,
          snapshot,
          geojson: shapeToGeoJSON(geometry),
        };
      });
    },
  },
});

export const {
  resetDrawingSlice,
  setParcelData,
  setOverlay,
  updateOverlays,
  initializeFromGeoJSON,
} = drawingSlice.actions;

export default drawingSlice.reducer;
