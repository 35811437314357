import {
  Box,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Skeleton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { StyledTableCell } from "../../MapScreen/PlannerDetailsDrawer";
import {
  convertTimestampToFormattedDate,
  convertTimestampToFormattedDateForEntryExit,
  entryExitTimeDifference,
  getDateForApi,
} from "../../../utils/common";
import { getCustomColor } from "../../../utils/theme";
import { useLazyGetOrganizationPropertyStatsQuery } from "../../../services/Properties";
import { useSelector } from "react-redux";

const EntryExitTable = () => {
  const blackShade1 = getCustomColor("blackShade1");
  const [propertyStats, setPropertyStats] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const selectedDevicesGeoJson = useSelector(
    (state) => state.gisDashboard.selectedDevicesGeoJson
  );
  const devicesDashboardLocationValue = useSelector(
    (state) => state.gisDashboard.devicesDashboardLocationValue
  );
  const selectedDateForDevices = useSelector(
    (state) => state.gisDashboard.selectedDateForDevices
  );
  const [getOrganizationPropertyStats] =
    useLazyGetOrganizationPropertyStatsQuery();

  const fetchPropertyStats = async (deviceId) => {
    try {
      setIsLoading(true);
      const response = await getOrganizationPropertyStats({
        orgName: user?.organization,
        location: `${devicesDashboardLocationValue?.key}`,
        date: getDateForApi(selectedDateForDevices),
        deviceId: deviceId,
      });
      if (response?.data) {
        setIsLoading(false);
        // setPropertyStats(response?.data?.results);  will enable this when fixed from backend
        const stats = [];
        let start = null;
        let end = null;

        response?.data?.results?.features.forEach((feature, index) => {
          const { start_time, end_time } = feature?.properties;
          const startTime = new Date(start_time);
          const endTime = new Date(end_time);

          if (index > 0) {
            const previousEndTime = new Date(
              response?.data?.results?.features[index - 1].properties.end_time
            );
            const timeDiffInMinutes =
              (startTime - previousEndTime) / (1000 * 60); // Convert milliseconds to minutes

            if (timeDiffInMinutes > 10) {
              stats.push({ start_time: start, end_time: end });
              start = startTime;
              end = endTime;
            } else {
              end = endTime;
            }
          } else {
            start = startTime;
            end = endTime;
          }
        });

        // To push the final range after the loop
        if (start && end) {
          stats.push({ start_time: start, end_time: end });
        }

        setPropertyStats(stats);
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    if (selectedDevicesGeoJson) {
      const deviceId =
        selectedDevicesGeoJson?.features[0]?.properties?.device_id;
      fetchPropertyStats(deviceId);
    }
  }, [selectedDevicesGeoJson]);

  return (
    <>
      <Typography
        fontWeight="600"
        variant="body1"
        sx={{ color: blackShade1.main, marginBottom: "5px" }}
      >
        Property Entries/Exits
      </Typography>

      {isLoading ? (
        <>
        <Skeleton
          variant="rectangular"
          width="100%"
          animation="wave"
          height={170}
          sx={{ borderRadius: "4px" }}
        />
        <Skeleton
          variant="rectangular"
          width="30%"
          animation="wave"
          height={10}
          sx={{ borderRadius: "4px", marginTop: "10px" }}
        />
        </>
      ) : (
        <>
          <TableContainer
            className="propertySummary"
            sx={{ height: 160 }}
            component={Paper}
          >
            <Table size="small" stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">Entry Time</StyledTableCell>
                  <StyledTableCell align="center">Exit Time</StyledTableCell>
                  <StyledTableCell align="center">Time Spent</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {propertyStats &&
                  propertyStats?.map((stat, index) => {
                    const { start_time, end_time } = stat;
                    return (
                      <TableRow key={index}>
                        <StyledTableCell
                          style={{ fontSize: "13px"}}
                          align="left"
                        >
                          {convertTimestampToFormattedDateForEntryExit(start_time)}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{ fontSize: "13px" }}
                          align="center"
                        >
                          {convertTimestampToFormattedDateForEntryExit(end_time)}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{ fontSize: "13px" }}
                          align="center"
                        >
                          {entryExitTimeDifference(start_time,end_time)}
                        </StyledTableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <Box marginTop="10px">
            <Typography
              sx={{ color: blackShade1.main }}
              variant="body1"
              fontWeight="600"
              component="span"
            >
              Total no of visits :{" "}
            </Typography>
            <Typography variant="body1" component="span">
              {propertyStats && propertyStats?.length}
            </Typography>
          </Box>
        </>
      )}
    </>
  );
};

export default EntryExitTable;
