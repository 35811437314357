import React from "react";
import { Card, CardContent, Typography, Grid } from "@mui/material";

const ParcelWorker = ({ parcelWorker }) => {
  return (
    <Card>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Parcel Workers
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2" color="text.secondary">
              Working Hours: {(parcelWorker?.workingHour / 60).toFixed(2)} hrs
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Expected days for planning: {parcelWorker.noOfDays}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          justifyContent="center"
          alignItems="center"
        >
          {parcelWorker.crews.map((crew, index) => (
            <Grid item xs={4} key={index}>
              <Card key={index} variant="outlined" sx={{ margin: "0 auto" }}>
                <CardContent>
                  <Typography variant="h6">Crew {index + 1}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    Member Count: {crew.crewMemberCount}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Available Equipment:
                  </Typography>
                  <Typography
                    variant="body1"
                    component="span"
                    color="text.secondary"
                  >
                    <ul>
                      {crew.availableEquipment.map((equipment, idx) => (
                        <li key={idx}>
                          {equipment.description} - {" "}
                          {equipment.quantity}
                        </li>
                      ))}
                    </ul>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ParcelWorker;
