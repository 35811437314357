import { Scanner } from "@yudiel/react-qr-scanner";

const QrReader = ({ handleQrSucess }) => {

  return (
    <Scanner
      onScan={(result) => (handleQrSucess(result[0]?.rawValue))}
    />
  );
};

export default QrReader;
