import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import { IoMdClose } from "react-icons/io";
import { IoAlertCircleOutline } from "react-icons/io5";
import { getCustomColor } from "../../utils/theme";
import SecondaryButton from "../../components/Buttons/SecondaryButton";

const ConfirmationModal = ({
  isOpen,
  setIsOpen,
  title,
  body,
  handleOk,
  secondaryButtonTitle,
  PrimaryButtonTitle,
  secondaryButtonVariant,
  secondaryButtonColor,
  secondaryButtonClick,
  primaryButtonVariant,
}) => {
  const blackShade1 = getCustomColor("blackShade1");
  const handleCancel = () => {
    setIsOpen(false);
  };
  return (
    <Dialog open={isOpen} fullWidth maxWidth="xs">
      {title && (
        <DialogTitle fontWeight={700} fontSize="22px" align="left">
          {title}
        </DialogTitle>
      )}
      <IconButton
        aria-label="close"
        onClick={handleCancel}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <IoMdClose />
      </IconButton>
      <div className="confirmationModalBody">
        <div className="confirmationModalBodyContent">
          <IoAlertCircleOutline fontSize={100} color="orange" />
          <Typography
            component="h4"
            fontWeight="600"
            sx={{ color: blackShade1.main }}
            variant="h4"
          >
            Are you sure?
          </Typography>
          <Typography component="p" variant="body1">
            {body}
          </Typography>
        </div>
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            justifyContent: "flex-end",
            mt: 2,
          }}
        >
          <Button
            variant={
              secondaryButtonVariant ? secondaryButtonVariant : "outlined"
            }
            color={secondaryButtonColor ? secondaryButtonColor : "error"}
            onClick={secondaryButtonClick ? secondaryButtonClick : handleCancel}
          >
            {secondaryButtonTitle ? secondaryButtonTitle : "Cancel"}
          </Button>
          <Button
            variant={primaryButtonVariant ? primaryButtonVariant : "contained"}
            onClick={handleOk}
          >
            {PrimaryButtonTitle ? PrimaryButtonTitle : "Yes"}
          </Button>
        </Box>
      </div>
    </Dialog>
  );
};

export default ConfirmationModal;
