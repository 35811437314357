import React, { useEffect, useState } from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  CircularProgress,
  Stack,
  Skeleton,
  Box,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { setWorkerPlanFolderData } from "../../../slices/workPlanSlice";
import { useGetWorkPlansMutation } from "../../../services/Properties";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getCustomColor } from "../../../utils/theme";

const NestedFolderList = ({ folderPath }) => {
  const blackShade1 = getCustomColor("blackShade1");
  const user = useSelector((state) => state.global.user);
  const workerPlanFolderData = useSelector(
    (state) => state.workPlan.workerPlanFolderData
  );
  const [getWorkPlans, { isLoading }] = useGetWorkPlansMutation();
  const dispatch = useDispatch();

  const fetchWorkPlans = async () => {
    const data = {
      organization: user.organization,
      locationGroup: folderPath,
    };
    try {
      const workDataFolder = await getWorkPlans(data).unwrap();
      dispatch(setWorkerPlanFolderData(workDataFolder.results));
    } catch (error) {
      toast.error(
        error?.originalStatus === 500 ? "Something Went Wrong" : error
      );
    }
  };

  useEffect(() => {
    if (folderPath) {
      fetchWorkPlans();
    }
  }, [folderPath]);

  const handlePlanClick = (key) => {
    const baseUrl = window.location.origin;
    const newUrl = `${baseUrl}/map?location=${folderPath}&workFolder=${key}`;
    window.open(newUrl, "_blank");
  };

   if (isLoading) {
    return (
      <>
        <Grid container marginTop="1px" spacing={2}>
          {[...Array(12)].map((_, index) => (
            <Grid item xs={6} md={4} lg={2} key={index}>
              <Skeleton
                variant="rectangular"
                width="100%"
                height={74}
                sx={{ borderRadius: "4px" }}
              />
            </Grid>
          ))}
        </Grid>
      </>
    );
  }

  return (
    <Box className="folderListContainer" marginTop={2}>
    <Grid container spacing={2}>
      {workerPlanFolderData &&
        workerPlanFolderData.map((folder) => (
          <Grid key={folder.name} item xs={6} sm={4} md={2}>
            <Box
              button
              onClick={() => handlePlanClick(folder.key)}
              className="folderContainer"
            >
              <Box>
                <IconButton>
                  <img src="/images/google-docs.png" width="25px" height="25px"/>
                </IconButton>
                <Tooltip title={folder?.name} arrow>
                  <Typography
                    fontWeight={"500"}
                    noWrap
                    variant="body2"
                    marginLeft="10px"
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      color: blackShade1.light,
                    }}
                  >
                    {folder?.name}
                  </Typography>
                </Tooltip>
              </Box>
            </Box>
          </Grid>
        ))}
    </Grid>
  </Box>
  );
};

export default NestedFolderList;
