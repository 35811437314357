import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { getCustomColor } from "../../utils/theme";
import { useGetPlansQuery } from "../../services/OpenApi";
import { toast } from "react-toastify";
import { MdDelete, MdEdit } from "react-icons/md";
import PlansCard from "./PlansCard";

const PlansModal = ({ isOpen, setIsOpen, parcelId, setParcelId }) => {
  const blackShade1 = getCustomColor("blackShade1");
  const user = JSON.parse(localStorage.getItem("user"));
  const { data: plansData } = useGetPlansQuery({
    orderId: parcelId,
    orgName: user?.organization,
  });

  const handleCancel = () => {
    setIsOpen(false);
    setParcelId(null);
  };

  return (
    <Dialog open={isOpen} fullWidth maxWidth="sm">
      <DialogTitle fontWeight={700} fontSize="22px" align="left">
        Plans
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleCancel}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <IoMdClose />
      </IconButton>
      <div className="confirmationModalBody">
        <Box className="folderListContainer" marginTop={2}>
          <Grid container spacing={2}>
            {plansData?.data?.length > 0 &&
              plansData?.data?.map((folder) => (
                <PlansCard
                  folder={folder}
                  parcelId={parcelId}
                  key={folder?.runPlannerId}
                />
              ))}
          </Grid>
        </Box>
      </div>
    </Dialog>
  );
};

export default PlansModal;
